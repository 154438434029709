/* repris de ace / bootstrap, mais adapté pour avoir un bouton aussi */
.action-buttons {
    display: flex;
    align-items: center;
    .btn-link-svg {
        display: flex;
        align-items: center;
        svg {
            width: 20px;
            height: 20px;
            &:hover, &:focus {
                .btn-link-svg-filling {
                    fill: #2a6496;
                }
            }
        }
    }
    button, a {
        margin: 0 4px;
        display: inline-block;
        opacity: 0.85;
        -webkit-transition: all 0.1s;
        -o-transition: all 0.1s;
        transition: all 0.1s;
        color: #428bca;
        text-decoration: none;
        &:hover, &:focus {
            text-decoration: none;
            opacity: 1;
            -moz-transform: scale(1.2);
            -webkit-transform: scale(1.2);
            -o-transform: scale(1.2);
            -ms-transform: scale(1.2);
            transform: scale(1.2);
            color: #2a6496;
        }
    }
}

a {
    &.action-buttons-text {
        > i {
            transform: scale(1.0);
            margin-right: 0.3em;
            margin-left: 12px;
        }
        &:hover {
            > i {
                transform: scale(1.2);
            }
        }
    }
    &.extrant-suppression, &.extrant-edition {
        color: #75b936 !important;
        &:hover, &:focus {
            transform: scale(1.2);
        }
    }
    &.extrant-suppression {
        &:hover, &:focus {
            color: #ee1e2d !important;
        }
    }
    &.extrant-edition {
        &:hover, &:focus {
            color: #428bca !important;
        }
    }
    &.btn-telechargement {
        color: #428bca !important;
        &:hover {
            color: #1b6aaa !important;
        }
        &.disabled {
            color: #c0c0c0 !important;
            pointer-events: none;
        }
    }
}

.flash-message, [data-type="travaux-batiment-nature-type"] {
    a.action-buttons-text {
        > i {
            transform: scale(1.0);
            margin-right: 0.3em;
        }
    }
}

.flash-message {
    a.action-buttons-text {
        > i {
            margin-left: 24px;
        }
    }
}

[data-type="travaux-batiment-nature-type"] {
    a.action-buttons-text {
        > i {
            margin-left: -12px;
        }
    }
}

button {
    &.disabled {
        cursor: not-allowed;
        background-color: #D1D3D4 !important;
    }
    &.filtre-close {
        background-color: #5c8d65;
        &:focus {
            box-shadow: 0 0 3px #444;
        }
    }
    &.filtre {
        &.btn {
            margin: 5px 0;
        }
    }
    &.disabled-filtre {
        &.btn {
            margin: 5px 0;
            background-color: #5c8d65;
        }
    }
    &.btn-evimbec[name="btn-tj-exporter-excel"] {
        height: 28px !important;
    }
}

.btn-svg {
    width: 22px;
}

.btn-default.disabled,
.btn-default.disabled.active,
.btn-default.disabled:active,
.btn-default.disabled:focus,
.btn-default.disabled:hover,
.btn-default[disabled],
.btn-default.active[disabled],
.btn-default[disabled]:active,
.btn-default[disabled]:focus,
.btn-default[disabled]:hover,
.btn.disabled,
.btn.disabled.active,
.btn.disabled:active,
.btn.disabled:focus,
.btn.disabled:hover,
.btn[disabled],
.btn.active[disabled],
.btn[disabled]:active,
.btn[disabled]:focus,
.btn[disabled]:hover,
fieldset[disabled] .btn,
fieldset[disabled] .btn-default,
fieldset[disabled] .btn-default.active,
fieldset[disabled] .btn-default:active,
fieldset[disabled] .btn-default:focus,
fieldset[disabled] .btn-default:hover,
fieldset[disabled] .btn.active,
fieldset[disabled] .btn:active,
fieldset[disabled] .btn:focus,
fieldset[disabled] .btn:hover {
    background-color: #D1D3D4 !important;
    border-color: #D1D3D4 !important;
}

.btn-rapport {
    padding-bottom: 50px;
}

.open {
    .btn-info.dropdown-toggle {
        background-color: #4f99c6 !important;
        border-color: $color-blue-action-principal;
    }
}

.btn-success {
    &.active {
        &.toggle {
            background-color: #629B58 !important;
            -webkit-box-shadow: inset 4px 6px 4px -5px rgba(0, 0, 0, 0.3) !important;
            -moz-box-shadow: inset 4px 6px 4px -5px rgba(0, 0, 0, 0.3) !important;
            box-shadow: inset 4px 6px 4px -5px rgba(0, 0, 0, 0.3) !important;
            /* AceBug : Je ne sais pas pourquoi Ace vient foutre une bordure jaune sur des boutons bleus ! */
            &:after {
                display: inline-block;
                content: "";
                position: absolute;
                border-bottom: 1px solid #87B87F;
                left: -4px;
                right: -4px;
                bottom: -4px
            }
        }
        &:after {
            display: inline-block;
            content: "";
            position: absolute;
            border-bottom: 1px solid #87B87F;
            left: -4px;
            right: -4px;
            bottom: -4px
        }
    }
}

.btn-info {
    @extend .btn-success;
    background-color: $color-blue-action-principal !important;
    border-color: $color-blue-action-principal;
    &.active {
        &.toggle {
            background-color: #5487A8 !important;
            &:after {
                border-bottom: 1px solid #4396CB;
            }
        }
        &:after {
            border-bottom: 1px solid #4396CB;
        }
    }
    &:active, &:hover {
        background-color: #4f99c6 !important;
        border-color: $color-blue-action-principal;
    }
    &:focus {
        background-color: $color-blue-action-principal !important;
        border-color: $color-blue-action-principal;
    }
}

button.modal-choisir[disabled],
button.disabled,
.control-group .radio input[disabled],
.control-group .radio span[disabled],
input.ace[type="radio"][disabled] + .lbl {
    cursor: not-allowed;
}

.link-disabled {
    opacity: .7;
    pointer-events: none;
}

/* nouveaux éléments style SIG */

.btn-sig-primary {
    border-color: $color-primary;
    background-color: $color-primary !important;
    &:hover, &:focus, &:active, &:active:focus {
        border-color: $color-primary !important;
        background-color: $color-primary-dark !important;
    }
}

.open .btn.dropdown-toggle.btn-sig-primary {
    border-color: $color-primary !important;
    background-color: $color-primary-dark !important;
}

.btn-sig-secondary {
    border-color: $color-secondary;
    background-color: $color-secondary !important;
    &:hover, &:focus, &:active, &:active:focus {
        border-color: $color-secondary !important;
        background-color: $color-secondary-dark !important;
    }
}

.open .btn.dropdown-toggle.btn-sig-secondary {
    border-color: $color-secondary !important;
    background-color: $color-secondary-dark !important;
}

/* Bouton par défaut applicable à toute action sur un élément d'une table */
.btn-evimbec {
    border-width: 1px !important;
    border-color: $color-blue-action-principal !important;
    background-color: $color-white !important;
    color: $color-blue-action-principal !important;
    text-shadow: none !important;
    &:hover, &:focus {
        background-color: #ebebeb !important;
        border-color: #4f99c6 !important;
    }

    //noinspection CssUnknownTarget
    &[disabled], &.disabled {
        color: #b7b7b7 !important;
        content: url("");
        background-color: $color-white !important;
        border-color: #ABBAC3 !important;
        cursor: not-allowed;
        &:hover, &:focus {
            color: #b7b7b7 !important;
            content: url("");
            background-color: $color-white !important;
            border-color: #ABBAC3 !important;
        }
    }
}

.btn-evimbec-inactive {
    border-width: 1px;
    border-color: #999999 !important;
    background-color: $color-white !important;
    color: #999999 !important;
    text-shadow: none;
}

.btn-evimbec-action-grille-couleur {
    border-width: 1px;
    border-color: $color-blue-action-principal !important;
    background-color: $color-white !important;
    text-shadow: none;
    &:hover, &:focus {
        background-color: #ebebeb !important;
        border-color: #4f99c6 !important;
    }
    > span {
        color: $color-black !important;
        text-shadow: none !important;
    }
    > i {
        color: $color-blue-action-principal !important;
        text-shadow: none !important;
    }
}

.btn-evimbec.evb-xls {
    padding-top: 0;
    padding-bottom: 0;
}

.action-buttons-utiliser {
    &.actif {
        color: #5CB85C;
        font-weight: bold;
    }
}

.lien-ouvert {
    cursor: pointer;
}

a.cboxElement {
    &:hover {
        opacity: 0.7;
    }
}

.ace-file-name > .ace-icon.fa:not(.fa-upload) {
    background-color: transparent;
}

.evb-xls:before {
    //noinspection CssUnknownTarget
    content: url(/static/images/xls_mini.png);
    vertical-align: -4px;
    line-height: 0.3em;
    padding-top: 1px;
    padding-bottom: 1px;
    margin: 0;
}

.btn-tj-exporter-excel:disabled .evb-xls:before {
    filter: brightness(1.5) grayscale(1);
}

.evb-gpx:before {
    //noinspection CssUnknownTarget
    content: url('/static/images/gpx.png');
    vertical-align: -4px;
    line-height: 0.3em;
    padding-top: 1px;
    padding-bottom: 1px;
    margin: 0;
}

.btn-tj-exporter-gpx:disabled .evb-gpx:before {
    filter: brightness(1.5) grayscale(1);
}

.evb-google-map:before {
    //noinspection CssUnknownTarget
    content: url('/static/images/google_map.png');
    vertical-align: -4px;
    line-height: 0.3em;
    padding-top: 1px;
    padding-bottom: 1px;
    margin: 0;
}

.btn-tj-exporter-google-map:disabled .evb-google-map:before {
    filter: brightness(1.5) grayscale(1);
}

.btn-responsive {
    max-width: 100%;
    white-space: normal;
}

.btn-primary, .btn-primary:focus {
    background-color: $color-blue-action-principal !important;
    border-color: $color-blue-action-principal;
}

.btn-with-options {
    display: flex;
    flex-direction: column;
    transition: height $time-md ease;
    overflow: hidden;
    .btn-options-icon {
        margin-left: 5px;
        transition: transform $time-md ease;
    }
    .btn-options-list {
        display: flex;
        flex-direction: column;
    }
    .btn-options-text, .btn-options-option {
        margin-bottom: 0 !important;
    }
    &.open {
        .btn-options-icon {
            transform: rotate(-180deg);
        }
    }
}

.btn-options-option {
    position: relative;
    &:before {
        content: '';
        position: absolute;
        top: -5px;
        left: $spacing-sm;
        right: $spacing-sm;
        height: 1px;
        background-color: $color-white;
        opacity: .7;
    }
}

.btn-group:first-child {
    margin-left: 5px; /* ace overwrite le code de bootstrap, ce qui cause un décallage, correction */
}

.reset-button-style {
    @include reset-button-style;
}

.reset-select-style-container {
    select {
        @include reset-select-style;
    }
}

.reset-select-style {
    @include reset-select-style;
}

.reset-input-file-style {
    @include reset-input-file-style;
}

.not-active {
    pointer-events: none;
    cursor: default;
    text-decoration: none;
}

li.methode-calcul-lien-disabled {
    pointer-events: none;
    cursor: default;
    text-decoration: none;
    color: #989898;
    > a {
        color: #989898;
    }
}

.pointeur {
    cursor: pointer !important;
}

.big-button-list {
    width: 600px;
    max-width: 100%;
    margin: -5px (-$spacing-sm);
    .big-button-list-item {
        margin: 5px $spacing-sm;
    }
}


.action-buttons-lg {
    a {
        margin: 0 3px;
        display: inline-block;
        opacity: 0.85;
        -webkit-transition: all 0.1s;
        -o-transition: all 0.1s;
        transition: all 0.1s;
        &:hover {
            text-decoration: none;
            opacity: 1;
            -moz-transform: scale(1.2);
            -webkit-transform: scale(1.2);
            -o-transform: scale(1.2);
            -ms-transform: scale(1.2);
            transform: scale(1.2);
        }
    }
}

.action-buttons-signature {
    text-align: left;
    width: 150px;
    display: block;
}

.evenement.action-buttons {
    text-align: right;
    width: auto;
}

.a {
    color: $color-blue-action-principal !important;
    text-decoration: none;
}

.btn-link {
  @include reset-button-style;
  @include lien-style-bootstrap;
}

.btn-link-svg {
    @include reset-button-style;
    text-decoration: none;
    .btn-link-svg-filling {
        fill: #337ab7;
    }
    &:hover, &:focus {
        fill: #0050b2;
    }
}

a, .a, .btn-link {
    &:hover, &:focus {
        color: #0050b2;
        text-decoration: underline;
    }
}

a:active {
    color: #0050b2;
    text-decoration: None;
}

.row-hyperlien {
    cursor: pointer;
}

.btn-input-container {
    border: 1px solid #aaa;
    .btn-input-btn {
        position: relative;
        color: $color-black !important;
        background-color: #fafafa !important;
        border: none !important;
        transition: background-color $time-md ease;
        &:after {
            content: "";
            position: absolute;
            right: 0;
            top: 4px;
            bottom: 4px;
            width: 1px;
            background-color: #aaa;
        }
        &:hover, &:focus {
            background-color: #ededed !important;
        }
        &:active, &:active:focus {
            background-color: #d9d9d9 !important;
        }
    }
    .btn-input-input.select2-container {
        .select2-choice {
            border: none !important;
        }
    }
}

/* boutons présents dans datatable */
.dt-buttons {
    padding-top: 6px;
}

div {
    &.dt-button-background {
        background: rgba(0, 0, 0, .5);
    }
    &.dt-button-collection {
        border-radius: 0;
        box-shadow: none;
    }
}

/* liste des boutons colvis pour montrer / cacher les colonnes */
.dt-button-collection .dt-button,
.dt-button.buttons-collection {
    color: $color-white !important;
    font-size: 13px;
    text-align: center;
    line-height: 1.39;
    text-shadow: 0 -1px 0 rgba(0,0,0,.25)!important;
    box-shadow: none !important;
    outline: none;
    background: #abbac3 !important;
    padding: 4px 9px;
    border-color: #abbac3;
    border-width: 4px !important;
    border-radius: 0 !important;
    margin: 0 0 1px 0 !important;
    transition: all ease .15s;
}

/* si c'est le seul enfant et qu'il est vide c'est la colonne des cases à cocher, ajout d'un texte */
.dt-button span:only-child:empty:before {
    content: 'Cases à cocher';
    display: inline-block;
}

.dt-button-collection {
    .dt-button {
        &:last-child {
            margin-bottom: 0 !important;
        }
        &:hover, &:focus {
            background: #8b9aa3!important;
            border-color: #abbac3;
        }
        &.active, &:active, &:active:focus {
            background: $color-primary !important;
            border-color: $color-primary;
        }
        &.active {
            &:hover, &:focus {
                background: $color-primary-dark !important;
                border-color: $color-primary;
            }
            &:active, &:active:focus {
                background: #abbac3 !important;
                border-color: #abbac3;
            }
        }
    }
}

/* bouton colvis et autres filtres */
.dt-button.buttons-collection {
    background: #e7e7e7 !important;
    border-color: #e7e7e7;
    color: $color-grey-ace !important;
    text-shadow: 0 -1px 0 rgba(250,250,250,.25) !important;
    &:hover, &:focus, &:active, &:active:focus {
        background: #d9d9d9 !important;
        border-color: #e7e7e7 !important;
    }
}

.export-button {
    float: right;
}

.button-group {
    margin: -2px;
    .btn {
        margin: 2px;
    }
}

.panel-btn-group {
    margin: -2.5px;
    .panel-btn-group-btn {
        margin: 2.5px;
    }
}

.btn-signature {
    display: flex;
    align-items: center;
    color: $color-white;
    width: 100%;
    min-height: 34px;
    margin-bottom: 2px;
    .badge-container {
        flex-grow: 1;
        flex-shrink: 1;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    @include breakpoint-max-custom(1900px) {
        flex-direction: column;
        .badge {
            margin-top: 3px;
        }
    }
}

.btn-xs-datatable {
    line-height: 1;
}
