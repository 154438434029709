@charset "UTF-8";
/* scss modulaire -> faites attention à l'ordre des imports pour le bon fonctionnement des variables! */
/* vars -> variables et mixins -> importer dans un autre fichier afin que ce soit accessible par les autres .scss */
/* scss modulaire -> faites attention à l'ordre des imports pour le bon fonctionnement des variables! */
/* lors de la création d'une nouvelle variable de couleur, n'oubliez pas de l'ajouter à $couleurs-site */
/* menu ace */
/* boutons principaux (sauvegarder...) */
/* couleurs des fiches (résidentiel, agricole, non résidentiel, multirésidentiel) */
/* color error de bootstrap */
/* color warning de bootstrap */
/* color warning dans les boutons de ace */
/* couleur background explorateur ue évènement ouvert */
/* lie les couleurs à leur nom */
/* breakpoints tirés de la doc de bootstrap */
/* fonctions utiles pour compiler le scss */
/* ****
    Ajoute les styles normalement et ajoute le media query pour l'impression.
    Mets les styles à important dans le mode impression pour écraser les styles print de bootstrap.
    Prends une liste (au lieu du content classique) car pas encore implémenté par sass (loop dans content)
    ---
    Recommandé pour les background-colors et les colors si on les veut lors de l'impression
**** */
/* mixins utiles */
/* style copié / collé de font-awesome */
/* animation présente dans l'accueil, ajouter la classe loaded en js pour déclencher l'animation d'apparition */
/* libs -> styles de base */
/* créé une variable css pour chaque couleur (ex: --color-primary), permets aux fichiers css de s'en servir */
:root {
  --color-black: #393939;
  --color-white: #fff;
  --color-primary: #040073;
  --color-primary-dark: #030052;
  --color-secondary: #6ab12f;
  --color-secondary-dark: #5b9729;
  --color-grey-darker: #616161;
  --color-grey-dark: #7c7c7c;
  --color-grey: #ccc;
  --color-grey-light: #ddd;
  --color-grey-lighter: #f9f9f9;
  --color-grey-light-ace: #e5e5e5;
  --color-grey-lighter-ace: #eee;
  --color-grey-ace: #888;
  --color-blue-light: #669fc7;
  --color-blue: #5293c4;
  --color-green: #87ba21;
  --color-green-lighter: #daff8a;
  --color-red: #d55b52;
  --color-red-light: #eb0d0f;
  --color-red-lighter: #f3d0cd;
  --color-r: #d15b47;
  --color-r-light: #ffcc99;
  --color-a: #808000;
  --color-a-light: #eaffad;
  --color-n: #98031f;
  --color-n-light: #f2dede;
  --color-m: #4c8fbd;
  --color-m-light: #eef4f9;
  --color-error: #a94442;
  --color-warning-light: #fcf8e3;
  --color-warning-dark: #ffb752;
  --color-yellow-light: #fdffc0;
  --color-blue-action-principal: #80b9fe;
}

body {
  background-color: #fff;
}

.bg-none {
  background: none !important;
}

.border-none {
  border: none !important;
}

.bg-black {
  background-color: #393939 !important;
}
@media print {
  .bg-black {
    background-color: #393939 !important;
  }
}

.text-black {
  color: #393939 !important;
}
@media print {
  .text-black {
    color: #393939 !important;
  }
}
.text-black.d1, .text-black.d2, .text-black.d3, .text-black.d4, .text-black.d5, .text-black.d6 {
  color: #393939 !important;
}
@media print {
  .text-black.d1, .text-black.d2, .text-black.d3, .text-black.d4, .text-black.d5, .text-black.d6 {
    color: #393939 !important;
  }
}

.border-black {
  border: 1px solid #393939 !important;
}

.border-top-black {
  border-top: 1px solid #393939 !important;
}

.border-left-black {
  border-left: 1px solid #393939 !important;
}

.border-right-black {
  border-right: 1px solid #393939 !important;
}

.border-bottom-black {
  border-bottom: 1px solid #393939 !important;
}

.bg-white {
  background-color: #fff !important;
}
@media print {
  .bg-white {
    background-color: #fff !important;
  }
}

.text-white {
  color: #fff !important;
}
@media print {
  .text-white {
    color: #fff !important;
  }
}
.text-white.d1, .text-white.d2, .text-white.d3, .text-white.d4, .text-white.d5, .text-white.d6 {
  color: #fff !important;
}
@media print {
  .text-white.d1, .text-white.d2, .text-white.d3, .text-white.d4, .text-white.d5, .text-white.d6 {
    color: #fff !important;
  }
}

.border-white {
  border: 1px solid #fff !important;
}

.border-top-white {
  border-top: 1px solid #fff !important;
}

.border-left-white {
  border-left: 1px solid #fff !important;
}

.border-right-white {
  border-right: 1px solid #fff !important;
}

.border-bottom-white {
  border-bottom: 1px solid #fff !important;
}

.bg-primary {
  background-color: #040073 !important;
}
@media print {
  .bg-primary {
    background-color: #040073 !important;
  }
}

.text-primary {
  color: #040073 !important;
}
@media print {
  .text-primary {
    color: #040073 !important;
  }
}
.text-primary.d1, .text-primary.d2, .text-primary.d3, .text-primary.d4, .text-primary.d5, .text-primary.d6 {
  color: #040073 !important;
}
@media print {
  .text-primary.d1, .text-primary.d2, .text-primary.d3, .text-primary.d4, .text-primary.d5, .text-primary.d6 {
    color: #040073 !important;
  }
}

.border-primary {
  border: 1px solid #040073 !important;
}

.border-top-primary {
  border-top: 1px solid #040073 !important;
}

.border-left-primary {
  border-left: 1px solid #040073 !important;
}

.border-right-primary {
  border-right: 1px solid #040073 !important;
}

.border-bottom-primary {
  border-bottom: 1px solid #040073 !important;
}

.bg-primary-dark {
  background-color: #030052 !important;
}
@media print {
  .bg-primary-dark {
    background-color: #030052 !important;
  }
}

.text-primary-dark {
  color: #030052 !important;
}
@media print {
  .text-primary-dark {
    color: #030052 !important;
  }
}
.text-primary-dark.d1, .text-primary-dark.d2, .text-primary-dark.d3, .text-primary-dark.d4, .text-primary-dark.d5, .text-primary-dark.d6 {
  color: #030052 !important;
}
@media print {
  .text-primary-dark.d1, .text-primary-dark.d2, .text-primary-dark.d3, .text-primary-dark.d4, .text-primary-dark.d5, .text-primary-dark.d6 {
    color: #030052 !important;
  }
}

.border-primary-dark {
  border: 1px solid #030052 !important;
}

.border-top-primary-dark {
  border-top: 1px solid #030052 !important;
}

.border-left-primary-dark {
  border-left: 1px solid #030052 !important;
}

.border-right-primary-dark {
  border-right: 1px solid #030052 !important;
}

.border-bottom-primary-dark {
  border-bottom: 1px solid #030052 !important;
}

.bg-secondary {
  background-color: #6ab12f !important;
}
@media print {
  .bg-secondary {
    background-color: #6ab12f !important;
  }
}

.text-secondary {
  color: #6ab12f !important;
}
@media print {
  .text-secondary {
    color: #6ab12f !important;
  }
}
.text-secondary.d1, .text-secondary.d2, .text-secondary.d3, .text-secondary.d4, .text-secondary.d5, .text-secondary.d6 {
  color: #6ab12f !important;
}
@media print {
  .text-secondary.d1, .text-secondary.d2, .text-secondary.d3, .text-secondary.d4, .text-secondary.d5, .text-secondary.d6 {
    color: #6ab12f !important;
  }
}

.border-secondary {
  border: 1px solid #6ab12f !important;
}

.border-top-secondary {
  border-top: 1px solid #6ab12f !important;
}

.border-left-secondary {
  border-left: 1px solid #6ab12f !important;
}

.border-right-secondary {
  border-right: 1px solid #6ab12f !important;
}

.border-bottom-secondary {
  border-bottom: 1px solid #6ab12f !important;
}

.bg-secondary-dark {
  background-color: #5b9729 !important;
}
@media print {
  .bg-secondary-dark {
    background-color: #5b9729 !important;
  }
}

.text-secondary-dark {
  color: #5b9729 !important;
}
@media print {
  .text-secondary-dark {
    color: #5b9729 !important;
  }
}
.text-secondary-dark.d1, .text-secondary-dark.d2, .text-secondary-dark.d3, .text-secondary-dark.d4, .text-secondary-dark.d5, .text-secondary-dark.d6 {
  color: #5b9729 !important;
}
@media print {
  .text-secondary-dark.d1, .text-secondary-dark.d2, .text-secondary-dark.d3, .text-secondary-dark.d4, .text-secondary-dark.d5, .text-secondary-dark.d6 {
    color: #5b9729 !important;
  }
}

.border-secondary-dark {
  border: 1px solid #5b9729 !important;
}

.border-top-secondary-dark {
  border-top: 1px solid #5b9729 !important;
}

.border-left-secondary-dark {
  border-left: 1px solid #5b9729 !important;
}

.border-right-secondary-dark {
  border-right: 1px solid #5b9729 !important;
}

.border-bottom-secondary-dark {
  border-bottom: 1px solid #5b9729 !important;
}

.bg-grey-darker {
  background-color: #616161 !important;
}
@media print {
  .bg-grey-darker {
    background-color: #616161 !important;
  }
}

.text-grey-darker {
  color: #616161 !important;
}
@media print {
  .text-grey-darker {
    color: #616161 !important;
  }
}
.text-grey-darker.d1, .text-grey-darker.d2, .text-grey-darker.d3, .text-grey-darker.d4, .text-grey-darker.d5, .text-grey-darker.d6 {
  color: #616161 !important;
}
@media print {
  .text-grey-darker.d1, .text-grey-darker.d2, .text-grey-darker.d3, .text-grey-darker.d4, .text-grey-darker.d5, .text-grey-darker.d6 {
    color: #616161 !important;
  }
}

.border-grey-darker {
  border: 1px solid #616161 !important;
}

.border-top-grey-darker {
  border-top: 1px solid #616161 !important;
}

.border-left-grey-darker {
  border-left: 1px solid #616161 !important;
}

.border-right-grey-darker {
  border-right: 1px solid #616161 !important;
}

.border-bottom-grey-darker {
  border-bottom: 1px solid #616161 !important;
}

.bg-grey-dark {
  background-color: #7c7c7c !important;
}
@media print {
  .bg-grey-dark {
    background-color: #7c7c7c !important;
  }
}

.text-grey-dark {
  color: #7c7c7c !important;
}
@media print {
  .text-grey-dark {
    color: #7c7c7c !important;
  }
}
.text-grey-dark.d1, .text-grey-dark.d2, .text-grey-dark.d3, .text-grey-dark.d4, .text-grey-dark.d5, .text-grey-dark.d6 {
  color: #7c7c7c !important;
}
@media print {
  .text-grey-dark.d1, .text-grey-dark.d2, .text-grey-dark.d3, .text-grey-dark.d4, .text-grey-dark.d5, .text-grey-dark.d6 {
    color: #7c7c7c !important;
  }
}

.border-grey-dark {
  border: 1px solid #7c7c7c !important;
}

.border-top-grey-dark {
  border-top: 1px solid #7c7c7c !important;
}

.border-left-grey-dark {
  border-left: 1px solid #7c7c7c !important;
}

.border-right-grey-dark {
  border-right: 1px solid #7c7c7c !important;
}

.border-bottom-grey-dark {
  border-bottom: 1px solid #7c7c7c !important;
}

.bg-grey {
  background-color: #ccc !important;
}
@media print {
  .bg-grey {
    background-color: #ccc !important;
  }
}

.text-grey {
  color: #ccc !important;
}
@media print {
  .text-grey {
    color: #ccc !important;
  }
}
.text-grey.d1, .text-grey.d2, .text-grey.d3, .text-grey.d4, .text-grey.d5, .text-grey.d6 {
  color: #ccc !important;
}
@media print {
  .text-grey.d1, .text-grey.d2, .text-grey.d3, .text-grey.d4, .text-grey.d5, .text-grey.d6 {
    color: #ccc !important;
  }
}

.border-grey {
  border: 1px solid #ccc !important;
}

.border-top-grey {
  border-top: 1px solid #ccc !important;
}

.border-left-grey {
  border-left: 1px solid #ccc !important;
}

.border-right-grey {
  border-right: 1px solid #ccc !important;
}

.border-bottom-grey {
  border-bottom: 1px solid #ccc !important;
}

.bg-grey-light {
  background-color: #ddd !important;
}
@media print {
  .bg-grey-light {
    background-color: #ddd !important;
  }
}

.text-grey-light {
  color: #ddd !important;
}
@media print {
  .text-grey-light {
    color: #ddd !important;
  }
}
.text-grey-light.d1, .text-grey-light.d2, .text-grey-light.d3, .text-grey-light.d4, .text-grey-light.d5, .text-grey-light.d6 {
  color: #ddd !important;
}
@media print {
  .text-grey-light.d1, .text-grey-light.d2, .text-grey-light.d3, .text-grey-light.d4, .text-grey-light.d5, .text-grey-light.d6 {
    color: #ddd !important;
  }
}

.border-grey-light {
  border: 1px solid #ddd !important;
}

.border-top-grey-light {
  border-top: 1px solid #ddd !important;
}

.border-left-grey-light {
  border-left: 1px solid #ddd !important;
}

.border-right-grey-light {
  border-right: 1px solid #ddd !important;
}

.border-bottom-grey-light {
  border-bottom: 1px solid #ddd !important;
}

.bg-grey-lighter {
  background-color: #f9f9f9 !important;
}
@media print {
  .bg-grey-lighter {
    background-color: #f9f9f9 !important;
  }
}

.text-grey-lighter {
  color: #f9f9f9 !important;
}
@media print {
  .text-grey-lighter {
    color: #f9f9f9 !important;
  }
}
.text-grey-lighter.d1, .text-grey-lighter.d2, .text-grey-lighter.d3, .text-grey-lighter.d4, .text-grey-lighter.d5, .text-grey-lighter.d6 {
  color: #f9f9f9 !important;
}
@media print {
  .text-grey-lighter.d1, .text-grey-lighter.d2, .text-grey-lighter.d3, .text-grey-lighter.d4, .text-grey-lighter.d5, .text-grey-lighter.d6 {
    color: #f9f9f9 !important;
  }
}

.border-grey-lighter {
  border: 1px solid #f9f9f9 !important;
}

.border-top-grey-lighter {
  border-top: 1px solid #f9f9f9 !important;
}

.border-left-grey-lighter {
  border-left: 1px solid #f9f9f9 !important;
}

.border-right-grey-lighter {
  border-right: 1px solid #f9f9f9 !important;
}

.border-bottom-grey-lighter {
  border-bottom: 1px solid #f9f9f9 !important;
}

.bg-grey-light-ace {
  background-color: #e5e5e5 !important;
}
@media print {
  .bg-grey-light-ace {
    background-color: #e5e5e5 !important;
  }
}

.text-grey-light-ace {
  color: #e5e5e5 !important;
}
@media print {
  .text-grey-light-ace {
    color: #e5e5e5 !important;
  }
}
.text-grey-light-ace.d1, .text-grey-light-ace.d2, .text-grey-light-ace.d3, .text-grey-light-ace.d4, .text-grey-light-ace.d5, .text-grey-light-ace.d6 {
  color: #e5e5e5 !important;
}
@media print {
  .text-grey-light-ace.d1, .text-grey-light-ace.d2, .text-grey-light-ace.d3, .text-grey-light-ace.d4, .text-grey-light-ace.d5, .text-grey-light-ace.d6 {
    color: #e5e5e5 !important;
  }
}

.border-grey-light-ace {
  border: 1px solid #e5e5e5 !important;
}

.border-top-grey-light-ace {
  border-top: 1px solid #e5e5e5 !important;
}

.border-left-grey-light-ace {
  border-left: 1px solid #e5e5e5 !important;
}

.border-right-grey-light-ace {
  border-right: 1px solid #e5e5e5 !important;
}

.border-bottom-grey-light-ace {
  border-bottom: 1px solid #e5e5e5 !important;
}

.bg-grey-lighter-ace {
  background-color: #eee !important;
}
@media print {
  .bg-grey-lighter-ace {
    background-color: #eee !important;
  }
}

.text-grey-lighter-ace {
  color: #eee !important;
}
@media print {
  .text-grey-lighter-ace {
    color: #eee !important;
  }
}
.text-grey-lighter-ace.d1, .text-grey-lighter-ace.d2, .text-grey-lighter-ace.d3, .text-grey-lighter-ace.d4, .text-grey-lighter-ace.d5, .text-grey-lighter-ace.d6 {
  color: #eee !important;
}
@media print {
  .text-grey-lighter-ace.d1, .text-grey-lighter-ace.d2, .text-grey-lighter-ace.d3, .text-grey-lighter-ace.d4, .text-grey-lighter-ace.d5, .text-grey-lighter-ace.d6 {
    color: #eee !important;
  }
}

.border-grey-lighter-ace {
  border: 1px solid #eee !important;
}

.border-top-grey-lighter-ace {
  border-top: 1px solid #eee !important;
}

.border-left-grey-lighter-ace {
  border-left: 1px solid #eee !important;
}

.border-right-grey-lighter-ace {
  border-right: 1px solid #eee !important;
}

.border-bottom-grey-lighter-ace {
  border-bottom: 1px solid #eee !important;
}

.bg-grey-ace {
  background-color: #888 !important;
}
@media print {
  .bg-grey-ace {
    background-color: #888 !important;
  }
}

.text-grey-ace {
  color: #888 !important;
}
@media print {
  .text-grey-ace {
    color: #888 !important;
  }
}
.text-grey-ace.d1, .text-grey-ace.d2, .text-grey-ace.d3, .text-grey-ace.d4, .text-grey-ace.d5, .text-grey-ace.d6 {
  color: #888 !important;
}
@media print {
  .text-grey-ace.d1, .text-grey-ace.d2, .text-grey-ace.d3, .text-grey-ace.d4, .text-grey-ace.d5, .text-grey-ace.d6 {
    color: #888 !important;
  }
}

.border-grey-ace {
  border: 1px solid #888 !important;
}

.border-top-grey-ace {
  border-top: 1px solid #888 !important;
}

.border-left-grey-ace {
  border-left: 1px solid #888 !important;
}

.border-right-grey-ace {
  border-right: 1px solid #888 !important;
}

.border-bottom-grey-ace {
  border-bottom: 1px solid #888 !important;
}

.bg-blue-light {
  background-color: #669fc7 !important;
}
@media print {
  .bg-blue-light {
    background-color: #669fc7 !important;
  }
}

.text-blue-light {
  color: #669fc7 !important;
}
@media print {
  .text-blue-light {
    color: #669fc7 !important;
  }
}
.text-blue-light.d1, .text-blue-light.d2, .text-blue-light.d3, .text-blue-light.d4, .text-blue-light.d5, .text-blue-light.d6 {
  color: #669fc7 !important;
}
@media print {
  .text-blue-light.d1, .text-blue-light.d2, .text-blue-light.d3, .text-blue-light.d4, .text-blue-light.d5, .text-blue-light.d6 {
    color: #669fc7 !important;
  }
}

.border-blue-light {
  border: 1px solid #669fc7 !important;
}

.border-top-blue-light {
  border-top: 1px solid #669fc7 !important;
}

.border-left-blue-light {
  border-left: 1px solid #669fc7 !important;
}

.border-right-blue-light {
  border-right: 1px solid #669fc7 !important;
}

.border-bottom-blue-light {
  border-bottom: 1px solid #669fc7 !important;
}

.bg-blue {
  background-color: #5293c4 !important;
}
@media print {
  .bg-blue {
    background-color: #5293c4 !important;
  }
}

.text-blue {
  color: #5293c4 !important;
}
@media print {
  .text-blue {
    color: #5293c4 !important;
  }
}
.text-blue.d1, .text-blue.d2, .text-blue.d3, .text-blue.d4, .text-blue.d5, .text-blue.d6 {
  color: #5293c4 !important;
}
@media print {
  .text-blue.d1, .text-blue.d2, .text-blue.d3, .text-blue.d4, .text-blue.d5, .text-blue.d6 {
    color: #5293c4 !important;
  }
}

.border-blue {
  border: 1px solid #5293c4 !important;
}

.border-top-blue {
  border-top: 1px solid #5293c4 !important;
}

.border-left-blue {
  border-left: 1px solid #5293c4 !important;
}

.border-right-blue {
  border-right: 1px solid #5293c4 !important;
}

.border-bottom-blue {
  border-bottom: 1px solid #5293c4 !important;
}

.bg-green {
  background-color: #87ba21 !important;
}
@media print {
  .bg-green {
    background-color: #87ba21 !important;
  }
}

.text-green {
  color: #87ba21 !important;
}
@media print {
  .text-green {
    color: #87ba21 !important;
  }
}
.text-green.d1, .text-green.d2, .text-green.d3, .text-green.d4, .text-green.d5, .text-green.d6 {
  color: #87ba21 !important;
}
@media print {
  .text-green.d1, .text-green.d2, .text-green.d3, .text-green.d4, .text-green.d5, .text-green.d6 {
    color: #87ba21 !important;
  }
}

.border-green {
  border: 1px solid #87ba21 !important;
}

.border-top-green {
  border-top: 1px solid #87ba21 !important;
}

.border-left-green {
  border-left: 1px solid #87ba21 !important;
}

.border-right-green {
  border-right: 1px solid #87ba21 !important;
}

.border-bottom-green {
  border-bottom: 1px solid #87ba21 !important;
}

.bg-green-lighter {
  background-color: #daff8a !important;
}
@media print {
  .bg-green-lighter {
    background-color: #daff8a !important;
  }
}

.text-green-lighter {
  color: #daff8a !important;
}
@media print {
  .text-green-lighter {
    color: #daff8a !important;
  }
}
.text-green-lighter.d1, .text-green-lighter.d2, .text-green-lighter.d3, .text-green-lighter.d4, .text-green-lighter.d5, .text-green-lighter.d6 {
  color: #daff8a !important;
}
@media print {
  .text-green-lighter.d1, .text-green-lighter.d2, .text-green-lighter.d3, .text-green-lighter.d4, .text-green-lighter.d5, .text-green-lighter.d6 {
    color: #daff8a !important;
  }
}

.border-green-lighter {
  border: 1px solid #daff8a !important;
}

.border-top-green-lighter {
  border-top: 1px solid #daff8a !important;
}

.border-left-green-lighter {
  border-left: 1px solid #daff8a !important;
}

.border-right-green-lighter {
  border-right: 1px solid #daff8a !important;
}

.border-bottom-green-lighter {
  border-bottom: 1px solid #daff8a !important;
}

.bg-red {
  background-color: #d55b52 !important;
}
@media print {
  .bg-red {
    background-color: #d55b52 !important;
  }
}

.text-red {
  color: #d55b52 !important;
}
@media print {
  .text-red {
    color: #d55b52 !important;
  }
}
.text-red.d1, .text-red.d2, .text-red.d3, .text-red.d4, .text-red.d5, .text-red.d6 {
  color: #d55b52 !important;
}
@media print {
  .text-red.d1, .text-red.d2, .text-red.d3, .text-red.d4, .text-red.d5, .text-red.d6 {
    color: #d55b52 !important;
  }
}

.border-red {
  border: 1px solid #d55b52 !important;
}

.border-top-red {
  border-top: 1px solid #d55b52 !important;
}

.border-left-red {
  border-left: 1px solid #d55b52 !important;
}

.border-right-red {
  border-right: 1px solid #d55b52 !important;
}

.border-bottom-red {
  border-bottom: 1px solid #d55b52 !important;
}

.bg-red-light {
  background-color: #eb0d0f !important;
}
@media print {
  .bg-red-light {
    background-color: #eb0d0f !important;
  }
}

.text-red-light {
  color: #eb0d0f !important;
}
@media print {
  .text-red-light {
    color: #eb0d0f !important;
  }
}
.text-red-light.d1, .text-red-light.d2, .text-red-light.d3, .text-red-light.d4, .text-red-light.d5, .text-red-light.d6 {
  color: #eb0d0f !important;
}
@media print {
  .text-red-light.d1, .text-red-light.d2, .text-red-light.d3, .text-red-light.d4, .text-red-light.d5, .text-red-light.d6 {
    color: #eb0d0f !important;
  }
}

.border-red-light {
  border: 1px solid #eb0d0f !important;
}

.border-top-red-light {
  border-top: 1px solid #eb0d0f !important;
}

.border-left-red-light {
  border-left: 1px solid #eb0d0f !important;
}

.border-right-red-light {
  border-right: 1px solid #eb0d0f !important;
}

.border-bottom-red-light {
  border-bottom: 1px solid #eb0d0f !important;
}

.bg-red-lighter {
  background-color: #f3d0cd !important;
}
@media print {
  .bg-red-lighter {
    background-color: #f3d0cd !important;
  }
}

.text-red-lighter {
  color: #f3d0cd !important;
}
@media print {
  .text-red-lighter {
    color: #f3d0cd !important;
  }
}
.text-red-lighter.d1, .text-red-lighter.d2, .text-red-lighter.d3, .text-red-lighter.d4, .text-red-lighter.d5, .text-red-lighter.d6 {
  color: #f3d0cd !important;
}
@media print {
  .text-red-lighter.d1, .text-red-lighter.d2, .text-red-lighter.d3, .text-red-lighter.d4, .text-red-lighter.d5, .text-red-lighter.d6 {
    color: #f3d0cd !important;
  }
}

.border-red-lighter {
  border: 1px solid #f3d0cd !important;
}

.border-top-red-lighter {
  border-top: 1px solid #f3d0cd !important;
}

.border-left-red-lighter {
  border-left: 1px solid #f3d0cd !important;
}

.border-right-red-lighter {
  border-right: 1px solid #f3d0cd !important;
}

.border-bottom-red-lighter {
  border-bottom: 1px solid #f3d0cd !important;
}

.bg-r {
  background-color: #d15b47 !important;
}
@media print {
  .bg-r {
    background-color: #d15b47 !important;
  }
}

.text-r {
  color: #d15b47 !important;
}
@media print {
  .text-r {
    color: #d15b47 !important;
  }
}
.text-r.d1, .text-r.d2, .text-r.d3, .text-r.d4, .text-r.d5, .text-r.d6 {
  color: #d15b47 !important;
}
@media print {
  .text-r.d1, .text-r.d2, .text-r.d3, .text-r.d4, .text-r.d5, .text-r.d6 {
    color: #d15b47 !important;
  }
}

.border-r {
  border: 1px solid #d15b47 !important;
}

.border-top-r {
  border-top: 1px solid #d15b47 !important;
}

.border-left-r {
  border-left: 1px solid #d15b47 !important;
}

.border-right-r {
  border-right: 1px solid #d15b47 !important;
}

.border-bottom-r {
  border-bottom: 1px solid #d15b47 !important;
}

.bg-r-light {
  background-color: #ffcc99 !important;
}
@media print {
  .bg-r-light {
    background-color: #ffcc99 !important;
  }
}

.text-r-light {
  color: #ffcc99 !important;
}
@media print {
  .text-r-light {
    color: #ffcc99 !important;
  }
}
.text-r-light.d1, .text-r-light.d2, .text-r-light.d3, .text-r-light.d4, .text-r-light.d5, .text-r-light.d6 {
  color: #ffcc99 !important;
}
@media print {
  .text-r-light.d1, .text-r-light.d2, .text-r-light.d3, .text-r-light.d4, .text-r-light.d5, .text-r-light.d6 {
    color: #ffcc99 !important;
  }
}

.border-r-light {
  border: 1px solid #ffcc99 !important;
}

.border-top-r-light {
  border-top: 1px solid #ffcc99 !important;
}

.border-left-r-light {
  border-left: 1px solid #ffcc99 !important;
}

.border-right-r-light {
  border-right: 1px solid #ffcc99 !important;
}

.border-bottom-r-light {
  border-bottom: 1px solid #ffcc99 !important;
}

.bg-a {
  background-color: #808000 !important;
}
@media print {
  .bg-a {
    background-color: #808000 !important;
  }
}

.text-a {
  color: #808000 !important;
}
@media print {
  .text-a {
    color: #808000 !important;
  }
}
.text-a.d1, .text-a.d2, .text-a.d3, .text-a.d4, .text-a.d5, .text-a.d6 {
  color: #808000 !important;
}
@media print {
  .text-a.d1, .text-a.d2, .text-a.d3, .text-a.d4, .text-a.d5, .text-a.d6 {
    color: #808000 !important;
  }
}

.border-a {
  border: 1px solid #808000 !important;
}

.border-top-a {
  border-top: 1px solid #808000 !important;
}

.border-left-a {
  border-left: 1px solid #808000 !important;
}

.border-right-a {
  border-right: 1px solid #808000 !important;
}

.border-bottom-a {
  border-bottom: 1px solid #808000 !important;
}

.bg-a-light {
  background-color: #eaffad !important;
}
@media print {
  .bg-a-light {
    background-color: #eaffad !important;
  }
}

.text-a-light {
  color: #eaffad !important;
}
@media print {
  .text-a-light {
    color: #eaffad !important;
  }
}
.text-a-light.d1, .text-a-light.d2, .text-a-light.d3, .text-a-light.d4, .text-a-light.d5, .text-a-light.d6 {
  color: #eaffad !important;
}
@media print {
  .text-a-light.d1, .text-a-light.d2, .text-a-light.d3, .text-a-light.d4, .text-a-light.d5, .text-a-light.d6 {
    color: #eaffad !important;
  }
}

.border-a-light {
  border: 1px solid #eaffad !important;
}

.border-top-a-light {
  border-top: 1px solid #eaffad !important;
}

.border-left-a-light {
  border-left: 1px solid #eaffad !important;
}

.border-right-a-light {
  border-right: 1px solid #eaffad !important;
}

.border-bottom-a-light {
  border-bottom: 1px solid #eaffad !important;
}

.bg-n {
  background-color: #98031f !important;
}
@media print {
  .bg-n {
    background-color: #98031f !important;
  }
}

.text-n {
  color: #98031f !important;
}
@media print {
  .text-n {
    color: #98031f !important;
  }
}
.text-n.d1, .text-n.d2, .text-n.d3, .text-n.d4, .text-n.d5, .text-n.d6 {
  color: #98031f !important;
}
@media print {
  .text-n.d1, .text-n.d2, .text-n.d3, .text-n.d4, .text-n.d5, .text-n.d6 {
    color: #98031f !important;
  }
}

.border-n {
  border: 1px solid #98031f !important;
}

.border-top-n {
  border-top: 1px solid #98031f !important;
}

.border-left-n {
  border-left: 1px solid #98031f !important;
}

.border-right-n {
  border-right: 1px solid #98031f !important;
}

.border-bottom-n {
  border-bottom: 1px solid #98031f !important;
}

.bg-n-light {
  background-color: #f2dede !important;
}
@media print {
  .bg-n-light {
    background-color: #f2dede !important;
  }
}

.text-n-light {
  color: #f2dede !important;
}
@media print {
  .text-n-light {
    color: #f2dede !important;
  }
}
.text-n-light.d1, .text-n-light.d2, .text-n-light.d3, .text-n-light.d4, .text-n-light.d5, .text-n-light.d6 {
  color: #f2dede !important;
}
@media print {
  .text-n-light.d1, .text-n-light.d2, .text-n-light.d3, .text-n-light.d4, .text-n-light.d5, .text-n-light.d6 {
    color: #f2dede !important;
  }
}

.border-n-light {
  border: 1px solid #f2dede !important;
}

.border-top-n-light {
  border-top: 1px solid #f2dede !important;
}

.border-left-n-light {
  border-left: 1px solid #f2dede !important;
}

.border-right-n-light {
  border-right: 1px solid #f2dede !important;
}

.border-bottom-n-light {
  border-bottom: 1px solid #f2dede !important;
}

.bg-m {
  background-color: #4c8fbd !important;
}
@media print {
  .bg-m {
    background-color: #4c8fbd !important;
  }
}

.text-m {
  color: #4c8fbd !important;
}
@media print {
  .text-m {
    color: #4c8fbd !important;
  }
}
.text-m.d1, .text-m.d2, .text-m.d3, .text-m.d4, .text-m.d5, .text-m.d6 {
  color: #4c8fbd !important;
}
@media print {
  .text-m.d1, .text-m.d2, .text-m.d3, .text-m.d4, .text-m.d5, .text-m.d6 {
    color: #4c8fbd !important;
  }
}

.border-m {
  border: 1px solid #4c8fbd !important;
}

.border-top-m {
  border-top: 1px solid #4c8fbd !important;
}

.border-left-m {
  border-left: 1px solid #4c8fbd !important;
}

.border-right-m {
  border-right: 1px solid #4c8fbd !important;
}

.border-bottom-m {
  border-bottom: 1px solid #4c8fbd !important;
}

.bg-m-light {
  background-color: #eef4f9 !important;
}
@media print {
  .bg-m-light {
    background-color: #eef4f9 !important;
  }
}

.text-m-light {
  color: #eef4f9 !important;
}
@media print {
  .text-m-light {
    color: #eef4f9 !important;
  }
}
.text-m-light.d1, .text-m-light.d2, .text-m-light.d3, .text-m-light.d4, .text-m-light.d5, .text-m-light.d6 {
  color: #eef4f9 !important;
}
@media print {
  .text-m-light.d1, .text-m-light.d2, .text-m-light.d3, .text-m-light.d4, .text-m-light.d5, .text-m-light.d6 {
    color: #eef4f9 !important;
  }
}

.border-m-light {
  border: 1px solid #eef4f9 !important;
}

.border-top-m-light {
  border-top: 1px solid #eef4f9 !important;
}

.border-left-m-light {
  border-left: 1px solid #eef4f9 !important;
}

.border-right-m-light {
  border-right: 1px solid #eef4f9 !important;
}

.border-bottom-m-light {
  border-bottom: 1px solid #eef4f9 !important;
}

.bg-error {
  background-color: #a94442 !important;
}
@media print {
  .bg-error {
    background-color: #a94442 !important;
  }
}

.text-error {
  color: #a94442 !important;
}
@media print {
  .text-error {
    color: #a94442 !important;
  }
}
.text-error.d1, .text-error.d2, .text-error.d3, .text-error.d4, .text-error.d5, .text-error.d6 {
  color: #a94442 !important;
}
@media print {
  .text-error.d1, .text-error.d2, .text-error.d3, .text-error.d4, .text-error.d5, .text-error.d6 {
    color: #a94442 !important;
  }
}

.border-error {
  border: 1px solid #a94442 !important;
}

.border-top-error {
  border-top: 1px solid #a94442 !important;
}

.border-left-error {
  border-left: 1px solid #a94442 !important;
}

.border-right-error {
  border-right: 1px solid #a94442 !important;
}

.border-bottom-error {
  border-bottom: 1px solid #a94442 !important;
}

.bg-warning-light {
  background-color: #fcf8e3 !important;
}
@media print {
  .bg-warning-light {
    background-color: #fcf8e3 !important;
  }
}

.text-warning-light {
  color: #fcf8e3 !important;
}
@media print {
  .text-warning-light {
    color: #fcf8e3 !important;
  }
}
.text-warning-light.d1, .text-warning-light.d2, .text-warning-light.d3, .text-warning-light.d4, .text-warning-light.d5, .text-warning-light.d6 {
  color: #fcf8e3 !important;
}
@media print {
  .text-warning-light.d1, .text-warning-light.d2, .text-warning-light.d3, .text-warning-light.d4, .text-warning-light.d5, .text-warning-light.d6 {
    color: #fcf8e3 !important;
  }
}

.border-warning-light {
  border: 1px solid #fcf8e3 !important;
}

.border-top-warning-light {
  border-top: 1px solid #fcf8e3 !important;
}

.border-left-warning-light {
  border-left: 1px solid #fcf8e3 !important;
}

.border-right-warning-light {
  border-right: 1px solid #fcf8e3 !important;
}

.border-bottom-warning-light {
  border-bottom: 1px solid #fcf8e3 !important;
}

.bg-warning-dark {
  background-color: #ffb752 !important;
}
@media print {
  .bg-warning-dark {
    background-color: #ffb752 !important;
  }
}

.text-warning-dark {
  color: #ffb752 !important;
}
@media print {
  .text-warning-dark {
    color: #ffb752 !important;
  }
}
.text-warning-dark.d1, .text-warning-dark.d2, .text-warning-dark.d3, .text-warning-dark.d4, .text-warning-dark.d5, .text-warning-dark.d6 {
  color: #ffb752 !important;
}
@media print {
  .text-warning-dark.d1, .text-warning-dark.d2, .text-warning-dark.d3, .text-warning-dark.d4, .text-warning-dark.d5, .text-warning-dark.d6 {
    color: #ffb752 !important;
  }
}

.border-warning-dark {
  border: 1px solid #ffb752 !important;
}

.border-top-warning-dark {
  border-top: 1px solid #ffb752 !important;
}

.border-left-warning-dark {
  border-left: 1px solid #ffb752 !important;
}

.border-right-warning-dark {
  border-right: 1px solid #ffb752 !important;
}

.border-bottom-warning-dark {
  border-bottom: 1px solid #ffb752 !important;
}

.bg-yellow-light {
  background-color: #fdffc0 !important;
}
@media print {
  .bg-yellow-light {
    background-color: #fdffc0 !important;
  }
}

.text-yellow-light {
  color: #fdffc0 !important;
}
@media print {
  .text-yellow-light {
    color: #fdffc0 !important;
  }
}
.text-yellow-light.d1, .text-yellow-light.d2, .text-yellow-light.d3, .text-yellow-light.d4, .text-yellow-light.d5, .text-yellow-light.d6 {
  color: #fdffc0 !important;
}
@media print {
  .text-yellow-light.d1, .text-yellow-light.d2, .text-yellow-light.d3, .text-yellow-light.d4, .text-yellow-light.d5, .text-yellow-light.d6 {
    color: #fdffc0 !important;
  }
}

.border-yellow-light {
  border: 1px solid #fdffc0 !important;
}

.border-top-yellow-light {
  border-top: 1px solid #fdffc0 !important;
}

.border-left-yellow-light {
  border-left: 1px solid #fdffc0 !important;
}

.border-right-yellow-light {
  border-right: 1px solid #fdffc0 !important;
}

.border-bottom-yellow-light {
  border-bottom: 1px solid #fdffc0 !important;
}

.bg-blue-action-principal {
  background-color: #80b9fe !important;
}
@media print {
  .bg-blue-action-principal {
    background-color: #80b9fe !important;
  }
}

.text-blue-action-principal {
  color: #80b9fe !important;
}
@media print {
  .text-blue-action-principal {
    color: #80b9fe !important;
  }
}
.text-blue-action-principal.d1, .text-blue-action-principal.d2, .text-blue-action-principal.d3, .text-blue-action-principal.d4, .text-blue-action-principal.d5, .text-blue-action-principal.d6 {
  color: #80b9fe !important;
}
@media print {
  .text-blue-action-principal.d1, .text-blue-action-principal.d2, .text-blue-action-principal.d3, .text-blue-action-principal.d4, .text-blue-action-principal.d5, .text-blue-action-principal.d6 {
    color: #80b9fe !important;
  }
}

.border-blue-action-principal {
  border: 1px solid #80b9fe !important;
}

.border-top-blue-action-principal {
  border-top: 1px solid #80b9fe !important;
}

.border-left-blue-action-principal {
  border-left: 1px solid #80b9fe !important;
}

.border-right-blue-action-principal {
  border-right: 1px solid #80b9fe !important;
}

.border-bottom-blue-action-principal {
  border-bottom: 1px solid #80b9fe !important;
}

.border-top-none {
  border-top: none !important;
  border-top: none !important;
}
@media print {
  .border-top-none {
    border-top: none !important;
  }
}

.border-left-none {
  border-left: none !important;
  border-left: none !important;
}
@media print {
  .border-left-none {
    border-left: none !important;
  }
}

.border-right-none {
  border-right: none !important;
  border-right: none !important;
}
@media print {
  .border-right-none {
    border-right: none !important;
  }
}

.border-bottom-none {
  border-bottom: none !important;
  border-bottom: none !important;
}
@media print {
  .border-bottom-none {
    border-bottom: none !important;
  }
}

.text-gray {
  color: gray;
}
@media print {
  .text-gray {
    color: gray !important;
  }
}

.red-alert {
  color: #a94442;
}
@media print {
  .red-alert {
    color: #a94442 !important;
  }
}

.blue {
  color: #478fca !important;
}
@media print {
  .blue {
    color: #478fca !important;
  }
}

.classique {
  background-color: #fcf6f9 !important;
}
@media print {
  .classique {
    background-color: #fcf6f9 !important;
  }
}

span.cycle-ouvert {
  background-color: #fff17a;
}
@media print {
  span.cycle-ouvert {
    background-color: #fff17a !important;
  }
}

.geocentralis-blue {
  color: #0050b2;
}
@media print {
  .geocentralis-blue {
    color: #0050b2 !important;
  }
}

.yellow {
  color: #f3d743 !important;
}
@media print {
  .yellow {
    color: #f3d743 !important;
  }
}

.evenement-ouvert-jaune {
  background-color: #fdffc0 !important;
}
@media print {
  .evenement-ouvert-jaune {
    background-color: #fdffc0 !important;
  }
}
.evenement-ouvert-jaune td {
  background-color: #fdffc0 !important;
}
@media print {
  .evenement-ouvert-jaune td {
    background-color: #fdffc0 !important;
  }
}

.fill-white {
  fill: #fff;
}

.border-1 {
  padding-top: 12px;
  padding-right: 12px;
  padding-left: 12px;
  padding-bottom: 12px;
  border: 1px solid #ddd;
  margin-bottom: 12px;
}

.ombre {
  -moz-box-shadow: 1px 1px 3px #6b6b6b;
  -webkit-box-shadow: 1px 1px 3px #6b6b6b;
  box-shadow: 1px 1px 3px #6b6b6b;
  border: none 1px #393939;
  -moz-border-radius-topleft: 5px;
  -moz-border-radius-topright: 5px;
  -moz-border-radius-bottomleft: 5px;
  -moz-border-radius-bottomright: 5px;
  -webkit-border-top-left-radius: 5px;
  -webkit-border-top-right-radius: 5px;
  -webkit-border-bottom-left-radius: 5px;
  -webkit-border-bottom-right-radius: 5px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}
@media print {
  .ombre {
    -moz-box-shadow: 1px 1px 3px #6b6b6b !important;
    -webkit-box-shadow: 1px 1px 3px #6b6b6b !important;
    box-shadow: 1px 1px 3px #6b6b6b !important;
    border: none 1px #393939 !important;
  }
}

.box-shadow-light {
  -webkit-box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.06);
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.06);
}

.flex-caption-ombre {
  left: 2px;
  right: 3px;
  -moz-box-shadow: 1px 1px 3px #6b6b6b;
  -webkit-box-shadow: 1px 1px 3px #6b6b6b;
  box-shadow: 1px 1px 3px #6b6b6b;
  border: none 1px #393939;
  -moz-border-radius-topleft: 0;
  -moz-border-radius-topright: 0;
  -moz-border-radius-bottomleft: 5px;
  -moz-border-radius-bottomright: 5px;
  -webkit-border-top-left-radius: 0;
  -webkit-border-top-right-radius: 0;
  -webkit-border-bottom-left-radius: 5px;
  -webkit-border-bottom-right-radius: 5px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.bg-gradient-primary, body.login-layout {
  background: no-repeat, linear-gradient(141deg, #1e1d47 51%, #040073 75%) !important;
}

.border-black {
  border: 1px solid #393939;
}

.border-2-black {
  border: 2px solid #393939;
}

body {
  font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 14px;
}

h1, h2, h3, h4, h5, h6, .d1, .d2, .d3, .d4, .d5, .d6 {
  font-weight: 400;
  font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
}

h1, .d1 {
  font-size: 23px !important;
  font-weight: 400 !important;
  color: #040073 !important;
}

h4, .d4 {
  font-size: 18px !important;
}

h5, .d5 {
  font-size: 15px !important;
  font-weight: 400 !important;
}

.text-lg {
  font-size: 17px !important;
}

.text-bold {
  font-weight: bold !important;
}

.bold {
  font-weight: bold;
}

.not-bold {
  font-weight: normal;
}

.capitalize {
  text-transform: capitalize;
}

.lower_capitalize {
  text-transform: lowercase;
}

.lower_capitalize:first-letter {
  text-transform: uppercase;
}

.text-italic {
  font-style: italic;
}

.text-decoration-underline {
  text-decoration: underline !important;
}

.text-16 {
  font-size: 16px;
}

.text-small {
  font-size: 12px;
}

.line-height-base {
  line-height: 1.5 !important;
}

.text-decoration-none {
  text-decoration: none;
}

.word-break {
  word-break: break-word;
  hyphens: auto;
}

p a {
  color: #428BCA;
  text-shadow: 2px 0 0 #f2f6ed, 1px 0 0 #f2f6ed, -1px 0 0 #f2f6ed, -2px 0 0 #f2f6ed;
  background-image: linear-gradient(to bottom, currentColor 0%, currentColor 100%);
  background-repeat: repeat-x;
  background-position: 0 95%;
  -webkit-background-size: 100% 0.5px;
  background-size: 100% 1px;
}
p a:hover, p a:focus {
  color: #428BCA;
  background-image: linear-gradient(to bottom, currentColor 0%, currentColor 0%);
  background-size: 0 0;
  text-decoration: none;
}

.list-inline, .list-unstyled {
  margin-left: 0;
  color: #393939;
  font-weight: initial;
  padding-top: 15px;
  padding-left: 15px;
}

.reset-list {
  margin: 0;
  padding: 0;
  list-style: none;
}
.reset-list ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

ul.no-bullets {
  list-style-type: none;
}
ul.margin-left {
  margin-left: 50px;
}
ul.no-margin {
  margin-left: 0;
}

.text-warning {
  font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  padding-top: 10px;
}

.text-left {
  text-align: left !important;
}

.text-center {
  text-align: center !important;
}

.text-right {
  text-align: right !important;
}

.center {
  text-align: center;
}

.text-v-center {
  vertical-align: middle;
  display: inline-block;
}

.upper {
  text-transform: uppercase;
}

.note2 {
  word-wrap: break-word;
}

.evb-ficheData1 {
  color: #393939;
  font-family: Arial;
}

.text-overflow-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 100%;
}

.position-fixed {
  position: fixed;
}

@media (min-width: 992px) {
  .pos-abs-md-min {
    position: absolute;
  }
}

.d-block {
  display: block !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex;
}

.align-items-initial {
  align-items: initial !important;
}

.align-items-flex-start {
  align-items: flex-start;
}

.align-items-flex-end {
  align-items: flex-end;
}

.align-items-center, .align-items-center-md {
  align-items: center;
}

@media (max-width: 991px) {
  .align-items-center-md {
    align-items: initial;
  }
}

.flex-direction-column {
  flex-direction: column;
}

@media (max-width: 991px) {
  .flex-direction-column-reverse-mobile {
    flex-direction: column-reverse;
  }
}

@media (max-width: 991px) {
  .flex-direction-column-mobile {
    flex-direction: column;
  }
}

@media (min-width: 992px) {
  .flex-direction-row-reverse-desktop {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: reverse !important;
    -ms-flex-direction: row-reverse !important;
    flex-direction: row-reverse !important;
  }
}

.justify-content-flex-end {
  justify-content: flex-end;
}

.justify-content-center {
  justify-content: center;
}

.justify-content-space-between {
  justify-content: space-between !important;
}

.justify-content-space-evenly {
  justify-content: space-evenly !important;
}

.justify-content-space-around {
  justify-content: space-around !important;
}

@media (max-width: 767px) {
  .justify-content-flex-start-sm-max {
    justify-content: flex-start !important;
  }
}

.flex-grow-1 {
  flex-grow: 1;
}

.flex-grow-0 {
  flex-grow: 0;
}

.flex-shrink-1 {
  flex-shrink: 1;
}

.flex-shrink-0 {
  flex-shrink: 0;
}

.flex-1 {
  flex: 1;
}

.flex-wrap-wrap {
  flex-wrap: wrap;
}

.flex-full-center, .champs-multiple .div-header-suppression.flex-full-center {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

@media (min-width: 768px) {
  .rowflex-full-center-sm {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

@media (min-width: 992px) {
  .rowflex-space-between-md {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

.nowrap {
  white-space: nowrap !important;
}

.overflow-x-auto {
  overflow-x: auto;
}

.overflow-hidden {
  overflow: hidden;
}

.overflow-visible {
  overflow: visible;
}

.overflow-auto {
  overflow: auto;
}

.center,
.align-center {
  text-align: center !important;
}

/* custom rows for bloc forms -- do not use if your class override padding */
.row-padding-5 {
  margin-left: -2.5px;
  margin-right: -2.5px;
}
.row-padding-5 .form-horizontal .form-group {
  margin-right: -2.5px;
  margin-left: -2.5px;
}
.row-padding-5 .row {
  margin-left: -2.5px;
  margin-right: -2.5px;
}
.row-padding-5 [class^=col-], .row-padding-5 [class^=scol-] {
  padding-left: 2.5px;
  padding-right: 2.5px;
}

.form-horizontal .row-padding-5 .form-group {
  margin-right: -2.5px;
  margin-left: -2.5px;
}

.row-no-gutters {
  margin-left: 0;
  margin-right: 0;
}
.row-no-gutters .form-horizontal .form-group {
  margin-right: 0;
  margin-left: 0;
}
.row-no-gutters > [class^=col-] {
  padding-left: 0;
  padding-right: 0;
}

.form-horizontal .row-no-gutters .form-group {
  margin-right: 0;
  margin-left: 0;
}

.page-break-avoid {
  page-break-inside: avoid;
}

/* quand on s'en sert, la colonne doit avoir un classe col-xs- */
.row-flex {
  display: flex;
  flex-wrap: wrap;
  flex-flow: nowrap;
}

@media (min-width: 768px) {
  .row-flex-sm {
    display: flex;
    flex-wrap: wrap;
    flex-flow: nowrap;
  }
}
/* Dans le cas d'un bouton avec un dropdown, on ne veut pas d'espace entre les deux éléments */
ul .dropdown-menu {
  margin-top: -0.5em !important;
}

.icon-centered {
  margin-right: 0 !important;
}

.bypass-bootrap-row-margin {
  margin-left: 0;
  margin-right: 12px;
}

div.content {
  position: absolute;
}

.col-centered-65 {
  width: 65%;
  margin: 0 auto;
  text-align: left;
}

.col-fixed-150 {
  width: 150px;
}

.col-fixed-200 {
  width: 200px;
}

.col-fixed-250 {
  width: 250px;
}

.col-fixed-300 {
  width: 300px;
}

@media (max-width: 1199px) {
  .col-fixed-800 {
    width: 802px;
  }
}
@media (max-width: 991px) {
  .col-fixed-800 {
    width: 600px;
  }
}

/* créé des classes pour col xl puisqu'elles n'existent pas dans bootstrap */
.col-xl-0 {
  position: relative;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}

@media (min-width: 1500px) {
  /* colonnes */
  /* colonnes offset */
  .col-xl-offset-0 {
    margin-left: 0%;
  }
  /* colonnes pull */
  .col-xl-pull-0 {
    right: auto;
  }
  /* colonnes push */
  .col-xl-push-0 {
    left: auto;
  }
}
.col-xl-1 {
  position: relative;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}

@media (min-width: 1500px) {
  /* colonnes */
  .col-xl-1 {
    float: left;
    width: 8.3333333333%;
  }
  /* colonnes offset */
  .col-xl-offset-1 {
    margin-left: 8.3333333333%;
  }
  /* colonnes pull */
  .col-xl-pull-1 {
    right: 8.3333333333%;
  }
  /* colonnes push */
  .col-xl-push-1 {
    left: 8.3333333333%;
  }
}
.col-xl-2 {
  position: relative;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}

@media (min-width: 1500px) {
  /* colonnes */
  .col-xl-2 {
    float: left;
    width: 16.6666666667%;
  }
  /* colonnes offset */
  .col-xl-offset-2 {
    margin-left: 16.6666666667%;
  }
  /* colonnes pull */
  .col-xl-pull-2 {
    right: 16.6666666667%;
  }
  /* colonnes push */
  .col-xl-push-2 {
    left: 16.6666666667%;
  }
}
.col-xl-3 {
  position: relative;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}

@media (min-width: 1500px) {
  /* colonnes */
  .col-xl-3 {
    float: left;
    width: 25%;
  }
  /* colonnes offset */
  .col-xl-offset-3 {
    margin-left: 25%;
  }
  /* colonnes pull */
  .col-xl-pull-3 {
    right: 25%;
  }
  /* colonnes push */
  .col-xl-push-3 {
    left: 25%;
  }
}
.col-xl-4 {
  position: relative;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}

@media (min-width: 1500px) {
  /* colonnes */
  .col-xl-4 {
    float: left;
    width: 33.3333333333%;
  }
  /* colonnes offset */
  .col-xl-offset-4 {
    margin-left: 33.3333333333%;
  }
  /* colonnes pull */
  .col-xl-pull-4 {
    right: 33.3333333333%;
  }
  /* colonnes push */
  .col-xl-push-4 {
    left: 33.3333333333%;
  }
}
.col-xl-5 {
  position: relative;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}

@media (min-width: 1500px) {
  /* colonnes */
  .col-xl-5 {
    float: left;
    width: 41.6666666667%;
  }
  /* colonnes offset */
  .col-xl-offset-5 {
    margin-left: 41.6666666667%;
  }
  /* colonnes pull */
  .col-xl-pull-5 {
    right: 41.6666666667%;
  }
  /* colonnes push */
  .col-xl-push-5 {
    left: 41.6666666667%;
  }
}
.col-xl-6 {
  position: relative;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}

@media (min-width: 1500px) {
  /* colonnes */
  .col-xl-6 {
    float: left;
    width: 50%;
  }
  /* colonnes offset */
  .col-xl-offset-6 {
    margin-left: 50%;
  }
  /* colonnes pull */
  .col-xl-pull-6 {
    right: 50%;
  }
  /* colonnes push */
  .col-xl-push-6 {
    left: 50%;
  }
}
.col-xl-7 {
  position: relative;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}

@media (min-width: 1500px) {
  /* colonnes */
  .col-xl-7 {
    float: left;
    width: 58.3333333333%;
  }
  /* colonnes offset */
  .col-xl-offset-7 {
    margin-left: 58.3333333333%;
  }
  /* colonnes pull */
  .col-xl-pull-7 {
    right: 58.3333333333%;
  }
  /* colonnes push */
  .col-xl-push-7 {
    left: 58.3333333333%;
  }
}
.col-xl-8 {
  position: relative;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}

@media (min-width: 1500px) {
  /* colonnes */
  .col-xl-8 {
    float: left;
    width: 66.6666666667%;
  }
  /* colonnes offset */
  .col-xl-offset-8 {
    margin-left: 66.6666666667%;
  }
  /* colonnes pull */
  .col-xl-pull-8 {
    right: 66.6666666667%;
  }
  /* colonnes push */
  .col-xl-push-8 {
    left: 66.6666666667%;
  }
}
.col-xl-9 {
  position: relative;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}

@media (min-width: 1500px) {
  /* colonnes */
  .col-xl-9 {
    float: left;
    width: 75%;
  }
  /* colonnes offset */
  .col-xl-offset-9 {
    margin-left: 75%;
  }
  /* colonnes pull */
  .col-xl-pull-9 {
    right: 75%;
  }
  /* colonnes push */
  .col-xl-push-9 {
    left: 75%;
  }
}
.col-xl-10 {
  position: relative;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}

@media (min-width: 1500px) {
  /* colonnes */
  .col-xl-10 {
    float: left;
    width: 83.3333333333%;
  }
  /* colonnes offset */
  .col-xl-offset-10 {
    margin-left: 83.3333333333%;
  }
  /* colonnes pull */
  .col-xl-pull-10 {
    right: 83.3333333333%;
  }
  /* colonnes push */
  .col-xl-push-10 {
    left: 83.3333333333%;
  }
}
.col-xl-11 {
  position: relative;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}

@media (min-width: 1500px) {
  /* colonnes */
  .col-xl-11 {
    float: left;
    width: 91.6666666667%;
  }
  /* colonnes offset */
  .col-xl-offset-11 {
    margin-left: 91.6666666667%;
  }
  /* colonnes pull */
  .col-xl-pull-11 {
    right: 91.6666666667%;
  }
  /* colonnes push */
  .col-xl-push-11 {
    left: 91.6666666667%;
  }
}
.col-xl-12 {
  position: relative;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}

@media (min-width: 1500px) {
  /* colonnes */
  .col-xl-12 {
    float: left;
    width: 100%;
  }
  /* colonnes offset */
  .col-xl-offset-12 {
    margin-left: 100%;
  }
  /* colonnes pull */
  .col-xl-pull-12 {
    right: 100%;
  }
  /* colonnes push */
  .col-xl-push-12 {
    left: 100%;
  }
}
.scol-xl-0 {
  position: relative;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}

@media (min-width: 1500px) {
  /* colonnes */
  /* colonnes offset */
  .scol-xl-offset-0 {
    margin-left: 0%;
  }
  /* colonnes pull */
  .scol-xl-pull-0 {
    right: auto;
  }
  /* colonnes push */
  .scol-xl-push-0 {
    left: auto;
  }
}
.scol-xl-1 {
  position: relative;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}

@media (min-width: 1500px) {
  /* colonnes */
  .scol-xl-1 {
    float: left;
    width: 4.1666666667%;
  }
  /* colonnes offset */
  .scol-xl-offset-1 {
    margin-left: 4.1666666667%;
  }
  /* colonnes pull */
  .scol-xl-pull-1 {
    right: 4.1666666667%;
  }
  /* colonnes push */
  .scol-xl-push-1 {
    left: 4.1666666667%;
  }
}
.scol-xl-2 {
  position: relative;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}

@media (min-width: 1500px) {
  /* colonnes */
  .scol-xl-2 {
    float: left;
    width: 8.3333333333%;
  }
  /* colonnes offset */
  .scol-xl-offset-2 {
    margin-left: 8.3333333333%;
  }
  /* colonnes pull */
  .scol-xl-pull-2 {
    right: 8.3333333333%;
  }
  /* colonnes push */
  .scol-xl-push-2 {
    left: 8.3333333333%;
  }
}
.scol-xl-3 {
  position: relative;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}

@media (min-width: 1500px) {
  /* colonnes */
  .scol-xl-3 {
    float: left;
    width: 12.5%;
  }
  /* colonnes offset */
  .scol-xl-offset-3 {
    margin-left: 12.5%;
  }
  /* colonnes pull */
  .scol-xl-pull-3 {
    right: 12.5%;
  }
  /* colonnes push */
  .scol-xl-push-3 {
    left: 12.5%;
  }
}
.scol-xl-4 {
  position: relative;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}

@media (min-width: 1500px) {
  /* colonnes */
  .scol-xl-4 {
    float: left;
    width: 16.6666666667%;
  }
  /* colonnes offset */
  .scol-xl-offset-4 {
    margin-left: 16.6666666667%;
  }
  /* colonnes pull */
  .scol-xl-pull-4 {
    right: 16.6666666667%;
  }
  /* colonnes push */
  .scol-xl-push-4 {
    left: 16.6666666667%;
  }
}
.scol-xl-5 {
  position: relative;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}

@media (min-width: 1500px) {
  /* colonnes */
  .scol-xl-5 {
    float: left;
    width: 20.8333333333%;
  }
  /* colonnes offset */
  .scol-xl-offset-5 {
    margin-left: 20.8333333333%;
  }
  /* colonnes pull */
  .scol-xl-pull-5 {
    right: 20.8333333333%;
  }
  /* colonnes push */
  .scol-xl-push-5 {
    left: 20.8333333333%;
  }
}
.scol-xl-6 {
  position: relative;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}

@media (min-width: 1500px) {
  /* colonnes */
  .scol-xl-6 {
    float: left;
    width: 25%;
  }
  /* colonnes offset */
  .scol-xl-offset-6 {
    margin-left: 25%;
  }
  /* colonnes pull */
  .scol-xl-pull-6 {
    right: 25%;
  }
  /* colonnes push */
  .scol-xl-push-6 {
    left: 25%;
  }
}
.scol-xl-7 {
  position: relative;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}

@media (min-width: 1500px) {
  /* colonnes */
  .scol-xl-7 {
    float: left;
    width: 29.1666666667%;
  }
  /* colonnes offset */
  .scol-xl-offset-7 {
    margin-left: 29.1666666667%;
  }
  /* colonnes pull */
  .scol-xl-pull-7 {
    right: 29.1666666667%;
  }
  /* colonnes push */
  .scol-xl-push-7 {
    left: 29.1666666667%;
  }
}
.scol-xl-8 {
  position: relative;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}

@media (min-width: 1500px) {
  /* colonnes */
  .scol-xl-8 {
    float: left;
    width: 33.3333333333%;
  }
  /* colonnes offset */
  .scol-xl-offset-8 {
    margin-left: 33.3333333333%;
  }
  /* colonnes pull */
  .scol-xl-pull-8 {
    right: 33.3333333333%;
  }
  /* colonnes push */
  .scol-xl-push-8 {
    left: 33.3333333333%;
  }
}
.scol-xl-9 {
  position: relative;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}

@media (min-width: 1500px) {
  /* colonnes */
  .scol-xl-9 {
    float: left;
    width: 37.5%;
  }
  /* colonnes offset */
  .scol-xl-offset-9 {
    margin-left: 37.5%;
  }
  /* colonnes pull */
  .scol-xl-pull-9 {
    right: 37.5%;
  }
  /* colonnes push */
  .scol-xl-push-9 {
    left: 37.5%;
  }
}
.scol-xl-10 {
  position: relative;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}

@media (min-width: 1500px) {
  /* colonnes */
  .scol-xl-10 {
    float: left;
    width: 41.6666666667%;
  }
  /* colonnes offset */
  .scol-xl-offset-10 {
    margin-left: 41.6666666667%;
  }
  /* colonnes pull */
  .scol-xl-pull-10 {
    right: 41.6666666667%;
  }
  /* colonnes push */
  .scol-xl-push-10 {
    left: 41.6666666667%;
  }
}
.scol-xl-11 {
  position: relative;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}

@media (min-width: 1500px) {
  /* colonnes */
  .scol-xl-11 {
    float: left;
    width: 45.8333333333%;
  }
  /* colonnes offset */
  .scol-xl-offset-11 {
    margin-left: 45.8333333333%;
  }
  /* colonnes pull */
  .scol-xl-pull-11 {
    right: 45.8333333333%;
  }
  /* colonnes push */
  .scol-xl-push-11 {
    left: 45.8333333333%;
  }
}
.scol-xl-12 {
  position: relative;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}

@media (min-width: 1500px) {
  /* colonnes */
  .scol-xl-12 {
    float: left;
    width: 50%;
  }
  /* colonnes offset */
  .scol-xl-offset-12 {
    margin-left: 50%;
  }
  /* colonnes pull */
  .scol-xl-pull-12 {
    right: 50%;
  }
  /* colonnes push */
  .scol-xl-push-12 {
    left: 50%;
  }
}
.scol-xl-13 {
  position: relative;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}

@media (min-width: 1500px) {
  /* colonnes */
  .scol-xl-13 {
    float: left;
    width: 54.1666666667%;
  }
  /* colonnes offset */
  .scol-xl-offset-13 {
    margin-left: 54.1666666667%;
  }
  /* colonnes pull */
  .scol-xl-pull-13 {
    right: 54.1666666667%;
  }
  /* colonnes push */
  .scol-xl-push-13 {
    left: 54.1666666667%;
  }
}
.scol-xl-14 {
  position: relative;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}

@media (min-width: 1500px) {
  /* colonnes */
  .scol-xl-14 {
    float: left;
    width: 58.3333333333%;
  }
  /* colonnes offset */
  .scol-xl-offset-14 {
    margin-left: 58.3333333333%;
  }
  /* colonnes pull */
  .scol-xl-pull-14 {
    right: 58.3333333333%;
  }
  /* colonnes push */
  .scol-xl-push-14 {
    left: 58.3333333333%;
  }
}
.scol-xl-15 {
  position: relative;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}

@media (min-width: 1500px) {
  /* colonnes */
  .scol-xl-15 {
    float: left;
    width: 62.5%;
  }
  /* colonnes offset */
  .scol-xl-offset-15 {
    margin-left: 62.5%;
  }
  /* colonnes pull */
  .scol-xl-pull-15 {
    right: 62.5%;
  }
  /* colonnes push */
  .scol-xl-push-15 {
    left: 62.5%;
  }
}
.scol-xl-16 {
  position: relative;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}

@media (min-width: 1500px) {
  /* colonnes */
  .scol-xl-16 {
    float: left;
    width: 66.6666666667%;
  }
  /* colonnes offset */
  .scol-xl-offset-16 {
    margin-left: 66.6666666667%;
  }
  /* colonnes pull */
  .scol-xl-pull-16 {
    right: 66.6666666667%;
  }
  /* colonnes push */
  .scol-xl-push-16 {
    left: 66.6666666667%;
  }
}
.scol-xl-17 {
  position: relative;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}

@media (min-width: 1500px) {
  /* colonnes */
  .scol-xl-17 {
    float: left;
    width: 70.8333333333%;
  }
  /* colonnes offset */
  .scol-xl-offset-17 {
    margin-left: 70.8333333333%;
  }
  /* colonnes pull */
  .scol-xl-pull-17 {
    right: 70.8333333333%;
  }
  /* colonnes push */
  .scol-xl-push-17 {
    left: 70.8333333333%;
  }
}
.scol-xl-18 {
  position: relative;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}

@media (min-width: 1500px) {
  /* colonnes */
  .scol-xl-18 {
    float: left;
    width: 75%;
  }
  /* colonnes offset */
  .scol-xl-offset-18 {
    margin-left: 75%;
  }
  /* colonnes pull */
  .scol-xl-pull-18 {
    right: 75%;
  }
  /* colonnes push */
  .scol-xl-push-18 {
    left: 75%;
  }
}
.scol-xl-19 {
  position: relative;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}

@media (min-width: 1500px) {
  /* colonnes */
  .scol-xl-19 {
    float: left;
    width: 79.1666666667%;
  }
  /* colonnes offset */
  .scol-xl-offset-19 {
    margin-left: 79.1666666667%;
  }
  /* colonnes pull */
  .scol-xl-pull-19 {
    right: 79.1666666667%;
  }
  /* colonnes push */
  .scol-xl-push-19 {
    left: 79.1666666667%;
  }
}
.scol-xl-20 {
  position: relative;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}

@media (min-width: 1500px) {
  /* colonnes */
  .scol-xl-20 {
    float: left;
    width: 83.3333333333%;
  }
  /* colonnes offset */
  .scol-xl-offset-20 {
    margin-left: 83.3333333333%;
  }
  /* colonnes pull */
  .scol-xl-pull-20 {
    right: 83.3333333333%;
  }
  /* colonnes push */
  .scol-xl-push-20 {
    left: 83.3333333333%;
  }
}
.scol-xl-21 {
  position: relative;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}

@media (min-width: 1500px) {
  /* colonnes */
  .scol-xl-21 {
    float: left;
    width: 87.5%;
  }
  /* colonnes offset */
  .scol-xl-offset-21 {
    margin-left: 87.5%;
  }
  /* colonnes pull */
  .scol-xl-pull-21 {
    right: 87.5%;
  }
  /* colonnes push */
  .scol-xl-push-21 {
    left: 87.5%;
  }
}
.scol-xl-22 {
  position: relative;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}

@media (min-width: 1500px) {
  /* colonnes */
  .scol-xl-22 {
    float: left;
    width: 91.6666666667%;
  }
  /* colonnes offset */
  .scol-xl-offset-22 {
    margin-left: 91.6666666667%;
  }
  /* colonnes pull */
  .scol-xl-pull-22 {
    right: 91.6666666667%;
  }
  /* colonnes push */
  .scol-xl-push-22 {
    left: 91.6666666667%;
  }
}
.scol-xl-23 {
  position: relative;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}

@media (min-width: 1500px) {
  /* colonnes */
  .scol-xl-23 {
    float: left;
    width: 95.8333333333%;
  }
  /* colonnes offset */
  .scol-xl-offset-23 {
    margin-left: 95.8333333333%;
  }
  /* colonnes pull */
  .scol-xl-pull-23 {
    right: 95.8333333333%;
  }
  /* colonnes push */
  .scol-xl-push-23 {
    left: 95.8333333333%;
  }
}
.scol-xl-24 {
  position: relative;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}

@media (min-width: 1500px) {
  /* colonnes */
  .scol-xl-24 {
    float: left;
    width: 100%;
  }
  /* colonnes offset */
  .scol-xl-offset-24 {
    margin-left: 100%;
  }
  /* colonnes pull */
  .scol-xl-pull-24 {
    right: 100%;
  }
  /* colonnes push */
  .scol-xl-push-24 {
    left: 100%;
  }
}
/* présent dans les fiches, pour cacher du contenu */
.col-xs-0, .col-sm-0, .col-md-0, .col-lg-0, .col-xl-0 {
  display: none;
}

.cacher {
  display: none;
}

#contenu, .contenu {
  position: relative;
  margin-left: 5px;
  margin-right: 5px;
}

@media (max-width: 510px) {
  .hidden-xxs {
    display: none;
  }
}

.invisible {
  visibility: hidden;
}

.vraiment-invisible {
  display: none;
}

.margin-0, table.margin-0 {
  margin: 0 !important;
}

@media (min-width: 768px) {
  .margin-sm-0 {
    margin: 0 !important;
  }
}

.margin-bottom-0-last:last-child {
  margin-bottom: 0;
}

.margin-bottom-0-beforelast:nth-last-child(2), .margin-bottom-0-beforelast:last-child {
  margin-bottom: 0 !important;
}

.margin-bottom-0 {
  margin-bottom: 0 !important;
}

.margin-0 {
  margin: 0 !important;
}

.margin-bottom-03 {
  margin-bottom: 0.3em !important;
}

.margin-bottom-05 {
  margin-bottom: 0.5em;
}

.margin-bottom-07 {
  margin-bottom: 0.7em;
}

.margin-bottom-1 {
  margin-bottom: 1em;
}

.margin-bottom-1-5 {
  margin-bottom: 1.5em;
}

@media (max-width: 991px) {
  .margin-bottom-1-5-desktop {
    margin-bottom: 1.5em !important;
  }
}

.margin-bottom-2 {
  margin-bottom: 2em;
}

.margin-bottom-3 {
  margin-bottom: 3em;
}

@media (max-width: 991px) {
  .margin-bottom-1-mobile {
    margin-bottom: 1em;
  }
}

.margin-left-0 {
  margin-left: 0 !important;
}

.margin-left-5 {
  margin-left: 5px !important;
}

.margin-left-05 {
  margin-left: 0.5em;
}

.margin-left-07 {
  margin-left: 0.7em;
}

.margin-left-right-05 {
  margin-left: 5px;
  margin-right: 5px;
}

.margin-left-right-20 {
  margin-left: 20px;
  margin-right: 20px;
}

.margin-left-24 {
  margin-left: 24px;
}

.margin-right-0 {
  margin-right: 0 !important;
}

.margin-right-01 {
  margin-right: 0.1em;
}

.margin-right-03 {
  margin-right: 0.3em !important;
}

.margin-right-05 {
  margin-right: 0.5em;
}

.margin-right-1 {
  margin-right: 1em;
}

.margin-right-5px {
  margin-right: 5px !important;
}

.margin-right-15 {
  margin-right: 15px !important;
}

.margin-top-0 {
  margin-top: 0 !important;
}

.margin-top-03 {
  margin-top: 0.3em;
}

.margin-top-05 {
  margin-top: 0.5em;
}

.margin-top-1, .dataTables_wrapper .row.margin-top-1 {
  margin-top: 1em !important;
}

.margin-top-15 {
  margin-top: 1.5em;
}

.margin-top-2 {
  margin-top: 2em !important;
}

.margin-top-5px {
  margin-top: 5px;
}

.margin-bottom-15 {
  margin-bottom: 15px !important;
}

.margin-12 {
  margin: 12px;
}

li.margin-bottom-2 {
  margin-bottom: 2em;
}

li.margin-bottom-3 {
  margin-bottom: 3em;
}

.ml-0 {
  margin-left: 0 !important;
}

.no-padding {
  padding: 0;
}

.padding-0 {
  padding: 0 !important;
}

.padding-5 {
  padding: 5px;
}

.padding-7 {
  padding: 7px;
}

.padding-8 {
  padding: 8px;
}

.padding-15 {
  padding: 15px;
}

.padding-20 {
  padding: 20px;
}

.padding-side-10 {
  padding-left: 10px !important;
  padding-right: 10px !important;
}

.padding-top-0 {
  padding-top: 0 !important;
}

.padding-top-05 {
  padding-top: 0.5em;
}

.padding-top-1 {
  padding-top: 1em !important;
}

.padding-bottom-0 {
  padding-bottom: 0 !important;
}

.padding-bottom-2 {
  padding-bottom: 2em;
}

.padding-bottom-10 {
  padding-bottom: 10px !important;
}

.padding-10 {
  padding: 10px;
}

.padding-y-30-resp {
  padding-top: 30px;
  padding-bottom: 30px;
}
@media (max-width: 767px) {
  .padding-y-30-resp {
    padding-top: 20px;
    padding-bottom: 20px;
  }
}

.pl-0 {
  padding-left: 0 !important;
}

.pl-5px {
  padding-left: 5px !important;
}

.pl-15px {
  padding-left: 15px !important;
}

.pr-0 {
  padding-right: 0 !important;
}

.pr-1px {
  padding-right: 1px;
}

.pr-15px {
  padding-right: 15px !important;
}

.pr-30px {
  padding-right: 30px;
}

.height-auto {
  height: auto !important;
}

.height-100 {
  height: 100% !important;
}

.height-90px {
  height: 90px;
}

.min-width-100 {
  min-width: 100%;
}

.max-width-100 {
  max-width: 100%;
}

.vertical-align-middle {
  vertical-align: middle;
}

img.centered {
  display: block;
  margin-left: auto;
  margin-right: auto;
  padding: 1px;
  border: 1px solid #393939;
}

.pre-wrap {
  white-space: pre-wrap;
}

.obj-fit-cover {
  object-fit: cover;
}

.z-index-1 {
  z-index: 1 !important;
}

.z-index-2 {
  z-index: 2 !important;
}

.z-index-3 {
  z-index: 3 !important;
}

.z-index-4 {
  z-index: 4 !important;
}

.z-index-5 {
  z-index: 5 !important;
}

.z-index-6 {
  z-index: 6 !important;
}

.z-index-7 {
  z-index: 7 !important;
}

.z-index-8 {
  z-index: 8 !important;
}

.z-index-9 {
  z-index: 9 !important;
}

.z-index-10 {
  z-index: 10 !important;
}

.left1 {
  float: left;
  width: 50%;
  height: 100%;
  color: #393939;
  font-size: 1em;
  margin-bottom: 2px;
}

.right1 {
  float: right;
  width: 50%;
  height: 100%;
  color: #393939;
  font-weight: bold;
  font-size: 1em;
  margin-bottom: 2px;
}

.lineContainer1 {
  clear: both;
}

.space {
  height: 55px;
}

.page-100vh {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}
.page-100vh .page-100vh-header,
.page-100vh .page-100vh-footer {
  flex-grow: 0;
  flex-shrink: 0;
}
.page-100vh .page-100vh-content {
  flex-grow: 1;
  flex-shrink: 0;
}

@keyframes shake {
  0% {
    transform: translateX(0);
  }
  0% {
    transform: translateX(2px);
  }
  10% {
    transform: translateX(-2px);
  }
  15% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(0);
  }
}
@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.animate-red:link {
  -webkit-transition: color 250ms linear, background-color 250ms ease-in-out, border-color 250ms ease-in-out;
  -moz-transition: color 250ms linear, background-color 250ms ease-in-out, border-color 250ms ease-in-out;
  -o-transition: color 250ms linear, background-color 250ms ease-in-out, border-color 250ms ease-in-out;
  transition: color 250ms linear, background-color 250ms ease-in-out, border-color 250ms ease-in-out;
}

.animate-red:hover {
  color: red !important;
  border-color: red !important;
  -webkit-transition: color 250ms linear, background-color 250ms ease-in-out, border-color 250ms ease-in-out;
  -moz-transition: color 250ms linear, background-color 250ms ease-in-out, border-color 250ms ease-in-out;
  -o-transition: color 250ms linear, background-color 250ms ease-in-out, border-color 250ms ease-in-out;
  transition: color 250ms linear, background-color 250ms ease-in-out, border-color 250ms ease-in-out;
}

.slide-right {
  width: 16.666%;
  -webkit-transition: width 1s ease-in-out;
  -moz-transition: width 1s ease-in-out;
  -ms-transition: width 1s ease-in-out;
  -o-transition: width 1s ease-in-out;
  transition: width 1s ease-in-out;
}
.slide-right.hidden {
  width: 0;
  -webkit-transition: width 1s ease-in-out;
  -moz-transition: width 1s ease-in-out;
  -ms-transition: width 1s ease-in-out;
  -o-transition: width 1s ease-in-out;
  transition: width 1s ease-in-out;
}

.animate-shake {
  animation: shake 3s linear infinite;
}

/* elements -> style des éléments se répétant plusieurs fois dans le site */
/* repris de ace / bootstrap, mais adapté pour avoir un bouton aussi */
.action-buttons {
  display: flex;
  align-items: center;
}
.action-buttons .btn-link-svg {
  display: flex;
  align-items: center;
}
.action-buttons .btn-link-svg svg {
  width: 20px;
  height: 20px;
}
.action-buttons .btn-link-svg svg:hover .btn-link-svg-filling, .action-buttons .btn-link-svg svg:focus .btn-link-svg-filling {
  fill: #2a6496;
}
.action-buttons button, .action-buttons a {
  margin: 0 4px;
  display: inline-block;
  opacity: 0.85;
  -webkit-transition: all 0.1s;
  -o-transition: all 0.1s;
  transition: all 0.1s;
  color: #428bca;
  text-decoration: none;
}
.action-buttons button:hover, .action-buttons button:focus, .action-buttons a:hover, .action-buttons a:focus {
  text-decoration: none;
  opacity: 1;
  -moz-transform: scale(1.2);
  -webkit-transform: scale(1.2);
  -o-transform: scale(1.2);
  -ms-transform: scale(1.2);
  transform: scale(1.2);
  color: #2a6496;
}

a.action-buttons-text > i {
  transform: scale(1);
  margin-right: 0.3em;
  margin-left: 12px;
}
a.action-buttons-text:hover > i {
  transform: scale(1.2);
}
a.extrant-suppression, a.extrant-edition {
  color: #75b936 !important;
}
a.extrant-suppression:hover, a.extrant-suppression:focus, a.extrant-edition:hover, a.extrant-edition:focus {
  transform: scale(1.2);
}
a.extrant-suppression:hover, a.extrant-suppression:focus {
  color: #ee1e2d !important;
}
a.extrant-edition:hover, a.extrant-edition:focus {
  color: #428bca !important;
}
a.btn-telechargement {
  color: #428bca !important;
}
a.btn-telechargement:hover {
  color: #1b6aaa !important;
}
a.btn-telechargement.disabled {
  color: #c0c0c0 !important;
  pointer-events: none;
}

.flash-message a.action-buttons-text > i, [data-type=travaux-batiment-nature-type] a.action-buttons-text > i {
  transform: scale(1);
  margin-right: 0.3em;
}

.flash-message a.action-buttons-text > i {
  margin-left: 24px;
}

[data-type=travaux-batiment-nature-type] a.action-buttons-text > i {
  margin-left: -12px;
}

button.disabled {
  cursor: not-allowed;
  background-color: #D1D3D4 !important;
}
button.filtre-close {
  background-color: #5c8d65;
}
button.filtre-close:focus {
  box-shadow: 0 0 3px #444;
}
button.filtre.btn {
  margin: 5px 0;
}
button.disabled-filtre.btn {
  margin: 5px 0;
  background-color: #5c8d65;
}
button.btn-evimbec[name=btn-tj-exporter-excel] {
  height: 28px !important;
}

.btn-svg {
  width: 22px;
}

.btn-default.disabled,
.btn-default.disabled.active,
.btn-default.disabled:active,
.btn-default.disabled:focus,
.btn-default.disabled:hover,
.btn-default[disabled],
.btn-default.active[disabled],
.btn-default[disabled]:active,
.btn-default[disabled]:focus,
.btn-default[disabled]:hover,
.btn.disabled,
.btn.disabled.active,
.btn.disabled:active,
.btn.disabled:focus,
.btn.disabled:hover,
.btn[disabled],
.btn.active[disabled],
.btn[disabled]:active,
.btn[disabled]:focus,
.btn[disabled]:hover,
fieldset[disabled] .btn,
fieldset[disabled] .btn-default,
fieldset[disabled] .btn-default.active,
fieldset[disabled] .btn-default:active,
fieldset[disabled] .btn-default:focus,
fieldset[disabled] .btn-default:hover,
fieldset[disabled] .btn.active,
fieldset[disabled] .btn:active,
fieldset[disabled] .btn:focus,
fieldset[disabled] .btn:hover {
  background-color: #D1D3D4 !important;
  border-color: #D1D3D4 !important;
}

.btn-rapport {
  padding-bottom: 50px;
}

.open .btn-info.dropdown-toggle {
  background-color: #4f99c6 !important;
  border-color: #80b9fe;
}

.btn-success.active.toggle, .active.toggle.btn-info {
  background-color: #629B58 !important;
  -webkit-box-shadow: inset 4px 6px 4px -5px rgba(0, 0, 0, 0.3) !important;
  -moz-box-shadow: inset 4px 6px 4px -5px rgba(0, 0, 0, 0.3) !important;
  box-shadow: inset 4px 6px 4px -5px rgba(0, 0, 0, 0.3) !important;
  /* AceBug : Je ne sais pas pourquoi Ace vient foutre une bordure jaune sur des boutons bleus ! */
}
.btn-success.active.toggle:after, .active.toggle.btn-info:after {
  display: inline-block;
  content: "";
  position: absolute;
  border-bottom: 1px solid #87B87F;
  left: -4px;
  right: -4px;
  bottom: -4px;
}
.btn-success.active:after, .active.btn-info:after {
  display: inline-block;
  content: "";
  position: absolute;
  border-bottom: 1px solid #87B87F;
  left: -4px;
  right: -4px;
  bottom: -4px;
}

.btn-info {
  background-color: #80b9fe !important;
  border-color: #80b9fe;
}
.btn-info.active.toggle {
  background-color: #5487A8 !important;
}
.btn-info.active.toggle:after {
  border-bottom: 1px solid #4396CB;
}
.btn-info.active:after {
  border-bottom: 1px solid #4396CB;
}
.btn-info:active, .btn-info:hover {
  background-color: #4f99c6 !important;
  border-color: #80b9fe;
}
.btn-info:focus {
  background-color: #80b9fe !important;
  border-color: #80b9fe;
}

button.modal-choisir[disabled],
button.disabled,
.control-group .radio input[disabled],
.control-group .radio span[disabled],
input.ace[type=radio][disabled] + .lbl {
  cursor: not-allowed;
}

.link-disabled {
  opacity: 0.7;
  pointer-events: none;
}

/* nouveaux éléments style SIG */
.btn-sig-primary {
  border-color: #040073;
  background-color: #040073 !important;
}
.btn-sig-primary:hover, .btn-sig-primary:focus, .btn-sig-primary:active, .btn-sig-primary:active:focus {
  border-color: #040073 !important;
  background-color: #030052 !important;
}

.open .btn.dropdown-toggle.btn-sig-primary {
  border-color: #040073 !important;
  background-color: #030052 !important;
}

.btn-sig-secondary {
  border-color: #6ab12f;
  background-color: #6ab12f !important;
}
.btn-sig-secondary:hover, .btn-sig-secondary:focus, .btn-sig-secondary:active, .btn-sig-secondary:active:focus {
  border-color: #6ab12f !important;
  background-color: #5b9729 !important;
}

.open .btn.dropdown-toggle.btn-sig-secondary {
  border-color: #6ab12f !important;
  background-color: #5b9729 !important;
}

/* Bouton par défaut applicable à toute action sur un élément d'une table */
.btn-evimbec {
  border-width: 1px !important;
  border-color: #80b9fe !important;
  background-color: #fff !important;
  color: #80b9fe !important;
  text-shadow: none !important;
}
.btn-evimbec:hover, .btn-evimbec:focus {
  background-color: #ebebeb !important;
  border-color: #4f99c6 !important;
}
.btn-evimbec[disabled], .btn-evimbec.disabled {
  color: #b7b7b7 !important;
  content: url("");
  background-color: #fff !important;
  border-color: #ABBAC3 !important;
  cursor: not-allowed;
}
.btn-evimbec[disabled]:hover, .btn-evimbec[disabled]:focus, .btn-evimbec.disabled:hover, .btn-evimbec.disabled:focus {
  color: #b7b7b7 !important;
  content: url("");
  background-color: #fff !important;
  border-color: #ABBAC3 !important;
}

.btn-evimbec-inactive {
  border-width: 1px;
  border-color: #999999 !important;
  background-color: #fff !important;
  color: #999999 !important;
  text-shadow: none;
}

.btn-evimbec-action-grille-couleur {
  border-width: 1px;
  border-color: #80b9fe !important;
  background-color: #fff !important;
  text-shadow: none;
}
.btn-evimbec-action-grille-couleur:hover, .btn-evimbec-action-grille-couleur:focus {
  background-color: #ebebeb !important;
  border-color: #4f99c6 !important;
}
.btn-evimbec-action-grille-couleur > span {
  color: #393939 !important;
  text-shadow: none !important;
}
.btn-evimbec-action-grille-couleur > i {
  color: #80b9fe !important;
  text-shadow: none !important;
}

.btn-evimbec.evb-xls {
  padding-top: 0;
  padding-bottom: 0;
}

.action-buttons-utiliser.actif {
  color: #5CB85C;
  font-weight: bold;
}

.lien-ouvert {
  cursor: pointer;
}

a.cboxElement:hover {
  opacity: 0.7;
}

.ace-file-name > .ace-icon.fa:not(.fa-upload) {
  background-color: transparent;
}

.evb-xls:before {
  content: url(/static/images/xls_mini.png);
  vertical-align: -4px;
  line-height: 0.3em;
  padding-top: 1px;
  padding-bottom: 1px;
  margin: 0;
}

.btn-tj-exporter-excel:disabled .evb-xls:before {
  filter: brightness(1.5) grayscale(1);
}

.evb-gpx:before {
  content: url("/static/images/gpx.png");
  vertical-align: -4px;
  line-height: 0.3em;
  padding-top: 1px;
  padding-bottom: 1px;
  margin: 0;
}

.btn-tj-exporter-gpx:disabled .evb-gpx:before {
  filter: brightness(1.5) grayscale(1);
}

.evb-google-map:before {
  content: url("/static/images/google_map.png");
  vertical-align: -4px;
  line-height: 0.3em;
  padding-top: 1px;
  padding-bottom: 1px;
  margin: 0;
}

.btn-tj-exporter-google-map:disabled .evb-google-map:before {
  filter: brightness(1.5) grayscale(1);
}

.btn-responsive {
  max-width: 100%;
  white-space: normal;
}

.btn-primary, .btn-primary:focus {
  background-color: #80b9fe !important;
  border-color: #80b9fe;
}

.btn-with-options {
  display: flex;
  flex-direction: column;
  transition: height 400ms ease;
  overflow: hidden;
}
.btn-with-options .btn-options-icon {
  margin-left: 5px;
  transition: transform 400ms ease;
}
.btn-with-options .btn-options-list {
  display: flex;
  flex-direction: column;
}
.btn-with-options .btn-options-text, .btn-with-options .btn-options-option {
  margin-bottom: 0 !important;
}
.btn-with-options.open .btn-options-icon {
  transform: rotate(-180deg);
}

.btn-options-option {
  position: relative;
}
.btn-options-option:before {
  content: "";
  position: absolute;
  top: -5px;
  left: 15px;
  right: 15px;
  height: 1px;
  background-color: #fff;
  opacity: 0.7;
}

.btn-group:first-child {
  margin-left: 5px; /* ace overwrite le code de bootstrap, ce qui cause un décallage, correction */
}

.reset-button-style {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  padding: 0;
  border: none;
  background: none;
}

.reset-select-style-container select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
.reset-select-style-container select::-ms-expand {
  display: none;
}

.reset-select-style {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
.reset-select-style::-ms-expand {
  display: none;
}

.reset-input-file-style {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
.reset-input-file-style::file-selector-button {
  opacity: 0;
}

.not-active {
  pointer-events: none;
  cursor: default;
  text-decoration: none;
}

li.methode-calcul-lien-disabled {
  pointer-events: none;
  cursor: default;
  text-decoration: none;
  color: #989898;
}
li.methode-calcul-lien-disabled > a {
  color: #989898;
}

.pointeur {
  cursor: pointer !important;
}

.big-button-list {
  width: 600px;
  max-width: 100%;
  margin: -5px -15px;
}
.big-button-list .big-button-list-item {
  margin: 5px 15px;
}

.action-buttons-lg a {
  margin: 0 3px;
  display: inline-block;
  opacity: 0.85;
  -webkit-transition: all 0.1s;
  -o-transition: all 0.1s;
  transition: all 0.1s;
}
.action-buttons-lg a:hover {
  text-decoration: none;
  opacity: 1;
  -moz-transform: scale(1.2);
  -webkit-transform: scale(1.2);
  -o-transform: scale(1.2);
  -ms-transform: scale(1.2);
  transform: scale(1.2);
}

.action-buttons-signature {
  text-align: left;
  width: 150px;
  display: block;
}

.evenement.action-buttons {
  text-align: right;
  width: auto;
}

.a {
  color: #80b9fe !important;
  text-decoration: none;
}

.btn-link {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  padding: 0;
  border: none;
  background: none;
  color: #337ab7;
  text-decoration: none;
}
@media print {
  .btn-link {
    color: #337ab7 !important;
  }
}
.btn-link:hover, .btn-link:focus {
  color: #23527c;
  text-decoration: underline;
}

.btn-link-svg {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  padding: 0;
  border: none;
  background: none;
  text-decoration: none;
}
.btn-link-svg .btn-link-svg-filling {
  fill: #337ab7;
}
.btn-link-svg:hover, .btn-link-svg:focus {
  fill: #0050b2;
}

a:hover, a:focus, .a:hover, .a:focus, .btn-link:hover, .btn-link:focus {
  color: #0050b2;
  text-decoration: underline;
}

a:active {
  color: #0050b2;
  text-decoration: None;
}

.row-hyperlien {
  cursor: pointer;
}

.btn-input-container {
  border: 1px solid #aaa;
}
.btn-input-container .btn-input-btn {
  position: relative;
  color: #393939 !important;
  background-color: #fafafa !important;
  border: none !important;
  transition: background-color 400ms ease;
}
.btn-input-container .btn-input-btn:after {
  content: "";
  position: absolute;
  right: 0;
  top: 4px;
  bottom: 4px;
  width: 1px;
  background-color: #aaa;
}
.btn-input-container .btn-input-btn:hover, .btn-input-container .btn-input-btn:focus {
  background-color: #ededed !important;
}
.btn-input-container .btn-input-btn:active, .btn-input-container .btn-input-btn:active:focus {
  background-color: #d9d9d9 !important;
}
.btn-input-container .btn-input-input.select2-container .select2-choice {
  border: none !important;
}

/* boutons présents dans datatable */
.dt-buttons {
  padding-top: 6px;
}

div.dt-button-background {
  background: rgba(0, 0, 0, 0.5);
}
div.dt-button-collection {
  border-radius: 0;
  box-shadow: none;
}

/* liste des boutons colvis pour montrer / cacher les colonnes */
.dt-button-collection .dt-button,
.dt-button.buttons-collection {
  color: #fff !important;
  font-size: 13px;
  text-align: center;
  line-height: 1.39;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25) !important;
  box-shadow: none !important;
  outline: none;
  background: #abbac3 !important;
  padding: 4px 9px;
  border-color: #abbac3;
  border-width: 4px !important;
  border-radius: 0 !important;
  margin: 0 0 1px 0 !important;
  transition: all ease 0.15s;
}

/* si c'est le seul enfant et qu'il est vide c'est la colonne des cases à cocher, ajout d'un texte */
.dt-button span:only-child:empty:before {
  content: "Cases à cocher";
  display: inline-block;
}

.dt-button-collection .dt-button:last-child {
  margin-bottom: 0 !important;
}
.dt-button-collection .dt-button:hover, .dt-button-collection .dt-button:focus {
  background: #8b9aa3 !important;
  border-color: #abbac3;
}
.dt-button-collection .dt-button.active, .dt-button-collection .dt-button:active, .dt-button-collection .dt-button:active:focus {
  background: #040073 !important;
  border-color: #040073;
}
.dt-button-collection .dt-button.active:hover, .dt-button-collection .dt-button.active:focus {
  background: #030052 !important;
  border-color: #040073;
}
.dt-button-collection .dt-button.active:active, .dt-button-collection .dt-button.active:active:focus {
  background: #abbac3 !important;
  border-color: #abbac3;
}

/* bouton colvis et autres filtres */
.dt-button.buttons-collection {
  background: #e7e7e7 !important;
  border-color: #e7e7e7;
  color: #888 !important;
  text-shadow: 0 -1px 0 rgba(250, 250, 250, 0.25) !important;
}
.dt-button.buttons-collection:hover, .dt-button.buttons-collection:focus, .dt-button.buttons-collection:active, .dt-button.buttons-collection:active:focus {
  background: #d9d9d9 !important;
  border-color: #e7e7e7 !important;
}

.export-button {
  float: right;
}

.button-group {
  margin: -2px;
}
.button-group .btn {
  margin: 2px;
}

.panel-btn-group {
  margin: -2.5px;
}
.panel-btn-group .panel-btn-group-btn {
  margin: 2.5px;
}

.btn-signature {
  display: flex;
  align-items: center;
  color: #fff;
  width: 100%;
  min-height: 34px;
  margin-bottom: 2px;
}
.btn-signature .badge-container {
  flex-grow: 1;
  flex-shrink: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}
@media (max-width: 1900px) {
  .btn-signature {
    flex-direction: column;
  }
  .btn-signature .badge {
    margin-top: 3px;
  }
}

.btn-xs-datatable {
  line-height: 1;
}

input.ace.ace-switch.ace-switch-5[type=checkbox] + .lbl::before {
  content: "Oui          Non";
}

input[type=checkbox] .ace.ace-w-check-margin + .lbl::before, input[type=radio] .ace.ace-w-check-margin + .lbl::before {
  margin-right: 6px;
}

.readonly {
  color: #939192;
  background: #f5f5f5 !important;
  pointer-events: none;
}

.sig-custom-form {
  display: flex;
  align-items: center;
  margin: 0;
  padding: 5px;
  border-radius: 4px;
  background-color: #fff;
  border: 2px solid rgba(0, 0, 0, 0.2);
}
.sig-custom-form.input {
  margin-top: 0;
  margin-right: 5px;
}

/* OVERRIDE DES STYLES DE ACE */
label.radio {
  margin-right: 25px;
}
@media (max-width: 991px) {
  label {
    text-align: left !important;
  }
}

.bigger-110-custom-checkbox {
  font-size: 14px !important;
  margin-left: 6px !important;
  vertical-align: middle;
  padding-left: 10px !important;
}

/* style spécifique pour ce champ */
.form-date_condition_marche-wrap {
  width: 100px;
  text-align: center;
}

#remarque.champs-multiple.liste-over {
  display: table;
  width: 100%;
}
#remarque.champs-multiple.liste-over [class*=’cell-remarque’] {
  float: none;
  display: table-cell;
}

#form-remarque {
  color: #393939;
  font-size: 16px;
}

div.sous-bloc-form.remarque {
  display: flex;
}

.entete-remarque div {
  border: 1px solid #ddd !important;
  background-image: linear-gradient(to bottom, #f8f8f8 0, #ececec 100%) !important;
}

.cell-remarque.odd {
  background-color: #f9f9f9 !important;
}
.cell-remarque.odd textarea {
  background-color: #f9f9f9 !important;
}
.cell-remarque.odd textarea.cell-remarque.odd textarea:disabled {
  background-color: #f9f9f9 !important;
}

.cell-nom-remarque {
  background-color: #FEF5D7 !important;
}

.cell-remarque {
  border: none !important;
}
.cell-remarque .form-control {
  border: none !important;
}

.ace-file-input .ace-file-container.selected {
  right: 0;
}
.ace-file-input .ace-file-container:before {
  line-height: 30px;
}
.ace-file-input .ace-file-container .ace-icon {
  line-height: 28px;
}
.ace-file-input .ace-file-container .ace-file-name {
  line-height: 32px;
}

.ace-icon-evb {
  vertical-align: middle;
}

#evenement .checkbox label, .radio label {
  padding-left: 0;
  margin-bottom: 0;
  font-weight: 400;
  cursor: pointer;
}

.form-group input[type=color],
.form-group input[type=date],
.form-group input[type=datetime-local],
.form-group input[type=datetime],
.form-group input[type=email],
.form-group input[type=month],
.form-group input[type=number],
.form-group input[type=password],
.form-group input[type=search],
.form-group input[type=tel],
.form-group input[type=text],
.form-group input[type=time],
.form-group input[type=url],
.form-group input[type=week],
.form-group select,
.form-group textarea {
  background-image: none;
  background-repeat: no-repeat;
  background-attachment: scroll;
  background-position: 0 0;
  background-clip: border-box;
  background-origin: padding-box;
  background-size: auto auto;
}
.form-group > label[class*=col-] {
  padding-top: 4px;
  margin-bottom: 4px;
  padding-right: 10px !important;
  text-align: right;
}

form {
  margin-top: 1em;
}
@media print {
  form {
    margin-top: 5px;
  }
}

.form.alert {
  padding: 10px;
  margin-top: 5px;
  margin-bottom: 0;
}

.form-border-alert-danger {
  border-color: #eb0d0f !important;
}

.form-control::-webkit-input-placeholder, .form-control:-moz-placeholder, .form-control::-moz-placeholder, .form-control:-ms-input-placeholder {
  color: #999999;
}
.form-control textarea[readonly] {
  /* style du SELECT tag readonly */
  background-color: blue;
}

/* Option utilisée pour cacher les options qui ont été taggées comme étant disabled dans select2 */
.select2-container--default .select2-results__option[aria-disabled=true] {
  display: none !important;
}

/* Option utilisée pour cacher les options qui ont été taggées comme étant disabled dans select2 */
.select2-drop .select2-results .select2-disabled {
  display: none !important;
}

.select2-container.form-control {
  padding: 0 !important;
  height: auto;
  border: inherit;
}
.select2-container .select2-choice {
  height: 34px;
  line-height: 32px;
}

.select2-container-disabled a {
  cursor: not-allowed;
}

.select2-results {
  max-height: 400px;
}

.select2-search-choice-close:before {
  display: inline;
  position: relative;
}

select.form-control.select-fake {
  -webkit-appearance: none;
  -moz-appearance: none;
  text-align: center !important;
  background-color: #c0c0c0 !important;
}
@media print {
  select.form-control.select-fake {
    background-color: #c0c0c0 !important;
  }
}
select.form-control option {
  /* pour overrider le setting du ACE template. ATTENTION: n'est pas pris en charge par IE */
  /* overridé car en Chrome, les listes avec plusieurs optgroup étaient coupés en hauteur */
  padding: 0 4px;
}
select[disabled].form-control.select-fake {
  background-color: #c0c0c0 !important;
}

h6.form {
  color: #549dca;
  font-size: 14px;
  line-height: 1.5;
  font-weight: bold;
}

input[type=radio].ace + .lbl::before {
  line-height: 15px;
}

.input {
  font-family: Verdana, Geneva, Arial, Helvetica, sans-serif;
  font-size: 0.8em;
}

textarea {
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  resize: none;
  overflow-x: hidden;
}
textarea.textarea-resizable {
  resize: vertical;
}
textarea:not(.money):not(.clearable) {
  overflow-x: hidden;
}
textarea.form-control:not([rows]) {
  height: 34px;
}

::-webkit-input-placeholder {
  opacity: 1;
  -webkit-transition: opacity 0.4s ease-in-out;
  transition: opacity 0.4s ease-in-out;
}

::-moz-placeholder {
  opacity: 1;
  -moz-transition: opacity 0.4s ease-in-out;
  transition: opacity 0.4s ease-in-out;
}

:-ms-input-placeholder {
  opacity: 1;
  -ms-transition: opacity 0.4s ease-in-out;
  transition: opacity 0.4s ease-in-out;
}

:focus::-webkit-input-placeholder {
  opacity: 0.5;
  -webkit-transition: opacity 0.4s ease-in-out;
  transition: opacity 0.4s ease-in-out;
}

:focus:-moz-placeholder {
  opacity: 0.5;
  -moz-transition: opacity 0.4s ease-in-out;
  transition: opacity 0.4s ease-in-out;
}

:focus::-moz-placeholder {
  opacity: 0.5;
  -moz-transition: opacity 0.4s ease-in-out;
  transition: opacity 0.4s ease-in-out;
}

:focus:-ms-input-placeholder {
  opacity: 0.5;
  -ms-transition: opacity 0.4s ease-in-out;
  transition: opacity 0.4s ease-in-out;
}

input[name=placeholder] {
  color: #999 !important;
}
input[type=file] {
  border-radius: 0 !important;
  color: #858585;
  background-color: #fff;
  border: 1px solid #d5d5d5;
  padding: 5px 4px 6px;
  font-size: 14px;
  font-family: inherit;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  -webkit-transition-duration: 0.1s;
  transition-duration: 0.1s;
}
input.money, input.superficie {
  text-align: right !important;
}
input.search-query {
  color: #999 !important;
}
input[type=radio] {
  display: inline-block;
}
input.aspect-label {
  border-style: none;
  background-color: transparent !important;
  cursor: auto !important;
  height: 21px;
  padding-top: 0;
  padding-bottom: 0;
}

.flex-caption {
  position: absolute;
  left: 0;
  right: 0;
  bottom: -10px;
  background: rgba(0, 0, 0, 0.3);
  padding: 5px;
  color: #fff;
  text-align: center;
}

.li-caption {
  width: 100%;
  position: relative;
  opacity: 1;
  display: block;
}

div[type=formulaire] label {
  font-size: 13.5px;
  line-height: 26px;
}

/* lc-swich - custom checkbox plugin */
div.lc_wrap {
  height: 20px !important;
}
div.lcs_switch {
  width: 65px !important;
  height: 24px !important;
}
div.lcs_switch.lcs_off:hover {
  background: #929292;
}
div.lcs_switch.lcs_on:hover {
  background: #4a872f;
}
div.lcs_switch.lcs_on .lcs_cursor {
  left: 45px !important;
}
div.lcs_switch.lcs_on .lcs_label_on {
  left: 8px !important;
}
div.lcs_cursor {
  top: 5px !important;
  width: 15px !important;
  height: 15px !important;
}

.fake-number-input::-webkit-outer-spin-button, .fake-number-input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.fake-number-input[type=number] {
  /* Firefox */
  -moz-appearance: textfield;
}

.fake-text-input {
  border-color: #d5d5d5 !important;
  background-color: #fff !important;
  color: #393939;
  padding: 5px 10px;
  min-height: 34px;
  font-size: 18px;
  margin-bottom: 15px;
}

.unselectable {
  -moz-user-select: -moz-none;
  -moz-user-select: none;
  -o-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.validation .checkbox_custom input:checked ~ .checkmark {
  background-color: #60ca42;
}

.validation-checkbox-invalide,
.validation-checkbox-invalide-alt {
  background: red !important;
}

.validation-checkbox-invalide-alt {
  display: flex;
  align-items: center;
  justify-content: center;
}

/*Gestion des checkboxes pour section documents comme dans l'éditeur de fiche*/
.div-ctrl-suppression input[type=checkbox] {
  visibility: hidden;
}
.div-ctrl-suppression .for-ctrl-suppression-w-hidden-input input[type=checkbox] {
  display: none;
}

.champs-multiple input[type=checkbox]:not(.checkboxmulti) {
  margin: 0;
}
.champs-multiple input.form-control.ctrl-suppression[type=checkbox] {
  visibility: hidden;
}
.champs-multiple label.for-ctrl-suppression {
  display: block;
  margin-bottom: 0;
  line-height: 34px;
}
.champs-multiple label.for-ctrl-suppression > i {
  left: 0;
  position: absolute;
  top: 5px;
  z-index: 99;
}
.champs-multiple label.for-ctrl-suppression > i.fa-square-o {
  color: #393939;
}
.champs-multiple label.for-ctrl-suppression > i.fa-trash-o {
  color: red;
}
.champs-multiple label.for-ctrl-suppression > i.fa-trash-o.tout-active {
  color: red;
}
.champs-multiple label.for-ctrl-suppression > i.fa-trash-o.tout-desactive {
  color: #393939;
}
.champs-multiple label.for-ctrl-suppression[disabled] {
  cursor: not-allowed;
}
.champs-multiple label.for-ctrl-suppression-alt {
  display: initial;
  line-height: initial;
  margin-left: 0;
}
.champs-multiple label.for-ctrl-suppression-alt > i {
  margin-top: 4px;
  position: static;
}
.champs-multiple div.suppression-ligne:not(.ctrl2-sup-alt), .champs-multiple div.suppression-ligne-sauvegardee:not(.ctrl2-sup-alt) {
  position: relative;
}
.champs-multiple div.suppression-ligne:not(.ctrl2-sup-alt):before, .champs-multiple div.suppression-ligne-sauvegardee:not(.ctrl2-sup-alt):before {
  content: "";
  position: absolute;
  top: 17px;
  left: -2px;
  height: 3px;
  width: calc(100% + 4px);
  background-color: red;
  z-index: 50;
}
.champs-multiple .header-suppression {
  color: #393939;
}
.ligne.nouvelle-ligne .for-ctrl-suppression, .ligne.nouvelle-ligne .control-label-suppression {
  display: none !important;
}

.form-w-responsive-labels .control-label {
  word-break: break-word;
}
.form-w-responsive-labels .control-label:not(.label-desktop) {
  display: none;
}
.form-w-responsive-labels .ctrl-suppression {
  display: none;
}
.form-w-responsive-labels label.for-ctrl-suppression-alt > i {
  margin-top: 4px;
}
@media (max-width: 1199px) {
  .form-w-responsive-labels:not(.form-w-responsive-labels-print) .control-label {
    font-size: 12px;
    display: none;
  }
  .form-w-responsive-labels:not(.form-w-responsive-labels-print) .control-label:not(.label-desktop) {
    display: initial;
  }
  .form-w-responsive-labels:not(.form-w-responsive-labels-print) .control-label:not(.label-desktop).flex-full-center {
    display: flex;
  }
  .form-w-responsive-labels:not(.form-w-responsive-labels-print) .label-desktop-wrapper {
    display: none;
  }
  .form-w-responsive-labels div.suppression-ligne:not(.form-group-suppression):before {
    top: 35px;
  }
}

.form-fdt select.form-control[readonly] {
  pointer-events: none;
}
.form-fdt textarea.form-control[readonly]:focus {
  background-color: #e5e5e5;
}
.form-fdt .form-control[readonly] {
  pointer-events: auto;
}
.form-fdt .control-label {
  font-weight: 700;
}
.form-fdt .totaux-form-item-label {
  font-weight: 700;
}
.form-fdt .select2-container .select2-choice abbr:before {
  top: calc(50% - 8px);
}
.form-fdt .nouvelle-ligne .for-ctrl-suppression, .form-fdt .nouvelle-ligne .control-label-suppression {
  display: none !important;
}
.form-fdt .form-group {
  margin-bottom: 0;
}
.form-fdt .form-group.has-error .select2-choice, .form-fdt .form-group.has-error textarea, .form-fdt .form-group.has-error input {
  border-color: #eb0d0f !important;
}
.form-fdt .form-group:not(.has-error) .help-block {
  display: none;
}
.form-fdt .help-block {
  margin-top: 5px;
  margin-bottom: 0;
  padding: 5px;
  background-color: #f2dede;
  border: 1px solid #ebccd1;
}
.form-fdt .help-block strong {
  color: #a94442 !important;
  font-weight: 400;
  word-break: break-word;
}
.form-fdt.form-fdt-print .form-control[disabled],
.form-fdt.form-fdt-print .form-control[readonly],
.form-fdt.form-fdt-print fieldset[disabled] .form-control,
.form-fdt.form-fdt-print input[readonly] {
  color: #393939 !important;
  background-color: #fff !important;
}
.form-fdt.form-fdt-print .row-totaux {
  margin-left: -20px;
}
.form-fdt.form-fdt-print .row-totaux-offset-print {
  margin-left: -25px;
}
.form-fdt.form-fdt-print .row-totaux .form-control[disabled],
.form-fdt.form-fdt-print .row-totaux input[readonly], .form-fdt.form-fdt-print .col-totaux .form-control[disabled],
.form-fdt.form-fdt-print .col-totaux input[readonly] {
  color: #393939 !important;
  background-color: #eee !important;
}
.form-fdt.form-fdt-print .nouvelle-ligne {
  display: none;
}
.form-fdt.form-fdt-print .widget-header {
  color: #393939;
}

div.suppression-ligne:not(.form-group-suppression):not(.ctrl2-sup-alt):before {
  content: "";
  position: absolute;
  top: 17px;
  left: -2px;
  height: 3px;
  width: calc(100% + 4px);
  background-color: red;
  z-index: 50;
}
div.suppression-ligne.form-group-suppression .fa-trash-o:before {
  color: red;
}

.form-vertical div.suppression-ligne:before {
  display: none;
}
.form-vertical div.suppression-ligne .controls {
  position: relative;
}
.form-vertical div.suppression-ligne .controls:before {
  content: "";
  position: absolute;
  top: 15px;
  left: 0;
  right: 0;
  height: 3px;
  background-color: red;
  z-index: 50;
}
.form-vertical .row .controls:before {
  left: -15px;
  right: -15px;
}
.form-vertical .row.row-padding-5 .controls:before {
  left: -2.5px;
  right: -2.5px;
}

input.form-control.ctrl-checkbox[type=checkbox] {
  visibility: hidden;
}

label.for-ctrl-checkbox {
  display: block;
  margin-bottom: 0;
  line-height: 34px;
}
label.for-ctrl-checkbox > i {
  left: 0;
  position: absolute;
  top: 5px;
  z-index: 99;
}

label.for-ctrl-checkbox[disabled],
div.suppression-ligne:hover,
div.suppression-ligne-sauvegardee:hover {
  cursor: not-allowed;
}

.for-ctrl-suppression:not([disabled]) i.fa-square-o:hover:before, .for-ctrl-suppression:not([disabled]) i.fa-trash-o:hover:before, form.form-fdt i.fa-square-o:hover:before, form.form-fdt i.fa-trash-o:hover:before {
  content: "\f014" !important;
  color: #cdcdcd !important;
  -webkit-transition: color 0.1s linear, background-color 0.1s ease-in-out, border-color 0.1s ease-in-out;
  -moz-transition: color 10s linear, background-color 0.1s ease-in-out, border-color 0.1s ease-in-out;
  -o-transition: color 10s linear, background-color 0.1s ease-in-out, border-color 0.1s ease-in-out;
  transition: color 0.1s linear, background-color 0.1s ease-in-out, border-color 0.1s ease-in-out;
}

.div-ctrl-suppression-hidden {
  display: none;
}

/* checkbox du wizard - Custom - Début */
.checkbox_custom {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.checkbox_customlabel.multiple {
  font-size: 14px;
  font-family: inherit;
  margin-left: 10px;
}
.checkbox_custom input {
  /* Cacher le checkbox du formulaire */
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
.checkbox_custom input:checked ~ .checkmark {
  background-color: #428bca;
}
.checkbox_custom input:checked ~ .checkmark:after {
  display: block;
}
.checkbox_custom:hover input ~ .checkmark {
  background-color: #929292;
}
.checkbox_custom .checkmark:not(.validation-checkbox-invalide-alt):after {
  left: 9px;
  top: 5px;
  width: 7px;
  height: 12px;
  border: solid #fff;
  border-width: 0 3.5px 3.5px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.checkmark {
  position: absolute;
  top: 0;
  left: 12px;
  height: 25px;
  width: 25px;
  background-color: #ccc;
}
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

span.checkbox_custom-multiple {
  font-size: 14px;
  font-family: inherit;
  margin-left: 10px;
}

.validation-checkbox-invalide-alt:after {
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\f00d" !important;
  color: #fff;
  font-size: 15px;
}
.validation-checkbox-invalide-alt.checkmark:after {
  display: block;
}

.supprimer-fichier:hover {
  color: #ff0000 !important;
  transform: scale(1.2);
}

#accordion-fichier-a-importer td {
  vertical-align: middle;
}
#accordion-fichier-a-importer .checkmark {
  top: -7px;
}

/* checkbox du wizard - Custom - Fin */
#erreurs-generiques {
  text-align: left;
}

.erreur-validation {
  position: absolute;
  z-index: 999;
  top: -20px;
  right: 0;
  line-height: 0.4;
  border-radius: 15px;
  border: 2px solid;
  font-size: 12px;
}
.erreur-validation.alert-danger {
  border-color: #eb0d0f !important;
}
.erreur-validation.alert-warning, .erreur-validation.alert-caution {
  border-color: #c0a16b !important;
}

.erreur-validation-border-danger {
  border-color: #eb0d0f !important;
}

.erreur-validation-border-warning {
  border-color: #c0a16b !important;
}

.alert-warning, .alert-caution {
  margin-top: 5px;
  padding-left: 10px;
  padding-right: 10px;
  border-color: #D8D045 !important;
  background-color: #fffac4 !important;
  color: #8a6d3b !important;
}
@media print {
  .alert-warning, .alert-caution {
    background-color: #fffac4 !important;
    color: #8a6d3b !important;
  }
}
.alert-warning strong, .alert-caution strong {
  color: #8a6d3b !important;
}
@media print {
  .alert-warning strong, .alert-caution strong {
    color: #8a6d3b !important;
  }
}

.alert-caution {
  border-color: #ffb752 !important;
  background-color: rgb(255, 225.450867052, 184) !important;
  color: #8a6d3b !important;
}
@media print {
  .alert-caution {
    background-color: rgb(255, 225.450867052, 184) !important;
    color: #8a6d3b !important;
  }
}
.alert-caution strong {
  color: #8a6d3b !important;
}
@media print {
  .alert-caution strong {
    color: #8a6d3b !important;
  }
}

#contenu .ctrl2 input:not(.money) {
  /* aligné à gauche les textes des inputs dans le wizard */
  text-align: left;
}
#contenu .date-picker.controle-modifie[name=date_evenement][disabled] {
  background-color: #eee !important;
}
#contenu .wiz-validation-warning .alert.alert-warning, #contenu .wiz-validation-warning .alert.alert-caution {
  padding: 5px;
  margin-bottom: 10px;
}

/* DM: HACK pour cacher les scrollbars pas rapport des SELECT liste */
.container-select-multiple select::-webkit-scrollbar,
.container-select-multiple-input-multiple select::-webkit-scrollbar {
  background-color: transparent;
}

#valider-importer-donnees-evenement-en-lot .select2-container .select2-choice .select2-arrow {
  right: 5px !important;
}

#b98-repartition-fiscale .sous-bloc-form input[disabled] {
  background-color: #fff !important;
}
#b98-repartition-fiscale .section-header {
  font-weight: bold;
}
#b98-repartition-fiscale .section-header hr {
  border-color: #393939;
  margin-top: 5px;
}
#b98-repartition-fiscale .divTable-fake .header, #b98-repartition-fiscale .divTable-fake .body {
  margin-left: 0;
}

#b94-valeur-retenue .row.section-header, #accordion-valeur-retenue-section .row.section-header {
  font-weight: bold;
}
#b94-valeur-retenue .row .section-header, #accordion-valeur-retenue-section .row .section-header {
  text-align: left;
  font-weight: bold;
}
#b94-valeur-retenue .row .section-header-padding-40, #accordion-valeur-retenue-section .row .section-header-padding-40 {
  text-align: left;
  font-weight: bold;
  padding-left: 40px;
}
#b94-valeur-retenue .row .section-header-padding-60, #accordion-valeur-retenue-section .row .section-header-padding-60 {
  text-align: left;
  font-weight: bold;
  padding-left: 60px;
}

#b94-valeur-retenue .row.hidden-row {
  visibility: hidden;
}
#b94-valeur-retenue label.for-ctrl-checkbox > i {
  left: inherit;
  position: absolute;
  top: 5px;
  z-index: 99;
}
#b94-valeur-retenue.blocForm {
  margin-top: 0;
}

#accordion-valeur-retenue-section .row .section-header.lbl,
#accordion-valeur-retenue-section .row .section-header-padding-40.lbl,
#accordion-valeur-retenue-section .row .section-header-padding-60.lbl {
  height: 34px;
}

#adm-table-pilotage .form-control {
  background-color: unset;
  display: table-cell;
  width: 75%;
}

.checkbox_square_down {
  display: block;
  position: relative;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.checkbox_square_down input {
  /* Cacher le checkbox du formulaire */
  position: absolute;
  opacity: 0;
  cursor: pointer;
}
.checkbox_square_down input:disabled {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  cursor: not-allowed;
}
.checkbox_square_down input:checked ~ .checkmark_square_down {
  color: #80b9fe;
}
.checkbox_square_down:hover input ~ .checkmark_square_down {
  color: #7F7F7F;
}
.checkbox_square_down:hover input:checked ~ .checkmark_square_down {
  color: #4f99c6;
}

.checkmark_square_down {
  position: absolute;
  top: 0;
  left: 12px;
  height: 25px;
  width: 25px;
  color: #929292;
}
.checkmark_square_down:after {
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\f150" !important;
  font-size: 2em;
  position: absolute;
}

span.checkmark_square_down[disabled] {
  cursor: not-allowed;
}

.select-disabled {
  background-color: #fff !important;
}

.bloc-form-info {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.bloc-form-item:empty {
  display: none;
}
.bloc-form-item:not(:first-child):before {
  display: inline;
  content: " / ";
}

.col-xs-12.col-md-7.suppression-ligne-test {
  text-decoration: line-through;
  text-decoration-color: red;
  text-decoration-thickness: 3px;
}

.filterOptionsterrain {
  display: none;
}

#controleUserPreferenceMapLayerSave {
  margin-right: 2px;
}

#col-xs-12.col-md-7.suppression-ligne-test:hover .filterOptionsterrain {
  display: block;
}

/* inline responsive form specific style - timesheet */
.responsive-form .mobile-label {
  display: none;
}
@media (max-width: 1199px) {
  .responsive-form .generic-form-label {
    display: none !important;
  }
  .responsive-form .mobile-label {
    display: block;
    font-size: 12px;
  }
}
@media (max-width: 767px) {
  .responsive-form .mobile-label {
    font-size: 14px;
  }
}

/* custom-checkbox */
.custom-checkbox-container {
  display: flex;
  align-items: center;
}

.custom-checkbox-label {
  margin-right: 10px;
}

.custom-checkbox {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  cursor: pointer;
  width: 20px;
  height: 20px;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.custom-checkbox .custom-checkbox-checkbox {
  /* Hide the browser's default checkbox */
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
.custom-checkbox:hover ~ .custom-checkbox-checkmark {
  opacity: 0.8;
}

.custom-checkbox-checkmark {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}
.custom-checkbox-checkmark:before {
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\f096" !important;
  position: absolute;
  display: inline-block;
  color: #393939;
}
.custom-checkbox-checkmark:after {
  /* Create the checkmark/indicator (hidden when not checked) */
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\f00c" !important;
  position: absolute;
  display: none;
  color: #3a87ad;
  font-size: 18px;
}

.custom-checkbox-checkbox:checked ~ .custom-checkbox-checkmark:after {
  display: block;
}

.offset-checkbox {
  position: relative;
}
.offset-checkbox .offset-checkbox-input {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  z-index: 2;
}
.offset-checkbox .offset-checkbox-checkbox:after {
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\f096" !important;
  font-size: 28px;
}
.offset-checkbox .offset-checkbox-input:checked ~ .offset-checkbox-checkbox:after {
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\f046" !important;
  font-size: 28px;
}
.offset-checkbox.form-control {
  display: flex;
  align-items: center;
  padding: 0;
  color: #393939;
  border: none;
}

span.asteriskField {
  color: indianred;
}

/* custom filters radio */
.filter-radio {
  margin-left: 0;
  list-style-type: none;
}
.filter-radio .filter-radio-input {
  display: none;
}
.filter-radio .filter-radio-label {
  cursor: pointer;
  color: #393939;
  position: relative;
  background-color: #fff;
  padding: 5px 5px 5px 30px;
  width: 100%;
  transition: color 400ms ease, background-color 400ms ease;
}
.filter-radio .filter-radio-label .filter-radio-check {
  position: absolute;
  left: 5px;
  top: 50%;
  transform: translateY(-50%);
  opacity: 0;
  transition: opacity 400ms ease;
}
.filter-radio .filter-radio-label:hover, .filter-radio .filter-radio-label:focus {
  background-color: #ededed;
}
.filter-radio .filter-radio-label:active, .filter-radio .filter-radio-label:active:focus {
  background-color: #d9d9d9;
}

.filter-radio-input:checked + .filter-radio-label {
  color: #fff;
  background-color: #6ab12f !important;
}
.filter-radio-input:checked + .filter-radio-label .filter-radio-check {
  opacity: 1;
}

.selected-filter-remove {
  border: none;
  background: none;
  padding: 0;
}

.custom-file-input-container {
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}
.custom-file-input-container:after {
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\f0c6" !important;
  color: #337ab7;
  text-decoration: none;
  font-size: 1.333em;
  line-height: 0.75em;
  vertical-align: -15%;
  text-decoration: none;
  pointer-events: none;
  transition: all 0.1s;
}
@media print {
  .custom-file-input-container:after {
    color: #337ab7 !important;
  }
}
.custom-file-input-container:after:hover, .custom-file-input-container:after:focus {
  color: #23527c;
  text-decoration: underline;
}
.custom-file-input-container:after:hover, .custom-file-input-container:after:focus {
  transform: scale(1.2);
}
.custom-file-input-container .custom-file-input {
  opacity: 0;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}
.custom-file-input-container.edit-mode:after {
  content: url(/static/images/file-checked.svg) !important;
  width: 20px;
}

.input-fake {
  border-radius: 0 !important;
  background-color: #fff;
  border: 1px solid #d5d5d5;
  padding: 5px 4px 6px 4px;
  min-height: 34px;
  font-size: 14px;
  font-family: inherit;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  -webkit-transition-duration: 0.1s;
  transition-duration: 0.1s;
}
@media print {
  .input-fake {
    background-color: #fff !important;
  }
}

.input-calendar .controls {
  position: relative;
}
.input-calendar .controls input {
  padding-right: 44px;
}
.input-calendar .controls:before {
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\f073" !important;
  position: absolute;
  right: 0;
  top: 0;
  pointer-events: none;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 34px;
  padding: 6px 12px;
  font-size: 14px;
  font-weight: 400;
  line-height: 1;
  color: #555;
  text-align: center;
  background-color: #eee;
  border: 1px solid #ccc;
}

.champs-multiple-w-big-labels .generic-form-label {
  min-height: 39px;
}
.champs-multiple-w-big-labels.champs-multiple div.suppression-ligne-sauvegardee:before, .champs-multiple-w-big-labels.champs-multiple div.suppression-ligne:before {
  top: 55px;
}

.form-without-margin-bottom .form-group {
  margin-bottom: 0;
}

.input-width-100 input, .input-width-100 select, .input-width-100 textarea {
  width: 100%;
}

.text-vcenter-input {
  display: flex;
  align-items: center;
  height: 34px;
}

.fake-select {
  display: grid;
  grid-template-columns: max-content;
  background-color: transparent !important;
  list-style: none;
  text-align: left;
  font-size: 12px;
  margin-left: 0;
  margin-bottom: 0;
  padding: 0;
  height: auto;
  border: none;
  color: #393939;
}
.fake-select .fake-select-option {
  padding-left: 2px;
  cursor: pointer;
}
.fake-select .fake-select-option:hover, .fake-select .fake-select-option:focus {
  background-color: #e5e5e5;
}
.fake-select .fake-select-option[disabled] {
  pointer-events: none;
  cursor: not-allowed;
}
.fake-select .fake-select-option.selected {
  background-color: #ccc;
}

.input-icon input {
  padding-left: 24px;
  padding-right: 6px;
}

.radio-no-margin .radio label {
  padding-left: 20px;
}
.radio-no-margin .radio input[type=radio] {
  margin-left: 0;
  left: 0;
}

.reperes-modal {
  display: none;
}
.reperes-modal.donnees {
  background-color: #8AE65C !important;
}
.reperes-modal.active {
  display: block;
}

#modal-reperes {
  font-size: 12px;
}
#modal-reperes .modal-header {
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 5px;
  padding-bottom: 5px;
}

#modal-zoom .btn-select-fichiers-fiche {
  float: left;
  vertical-align: middle;
}
#modal-zoom .modal-content {
  height: 98%;
}
#modal-zoom .modal-zoom-ouvrir {
  border-style: none !important;
}

.modal-zoom {
  width: 98%;
  height: 98%;
  padding: 0;
  top: 0;
}
.modal-zoom .modal-content {
  padding: 10px;
}

.modal-zoom-ouvrir.fiche {
  border-color: #80b9fe !important;
  border: solid;
  border-width: thin;
  padding: 15px 5px 0 5px;
}

@media (min-width: 992px) {
  .modal-xl {
    width: 1300px;
    max-width: calc(100vw - 100px);
  }
}

@media (min-width: 992px) {
  .modal-xxl {
    width: calc(100vw - 300px);
    max-width: 2100px;
  }
}

@media (min-width: 992px) {
  .modal-reperes {
    width: 99%;
    height: 99%;
  }
}

@media (min-width: 992px) {
  .modal-xxx {
    width: calc(100vw - 100px);
    height: calc(100vh - 100px);
  }
}

.modal-full {
  width: calc(100vw - 30px);
  margin: 15px auto;
}

@media (min-width: 992px) {
  .modal-camera {
    width: 85%;
    height: 85%;
  }
}

@media (min-width: 992px) {
  .modal-alerte-immense {
    width: 90%;
  }
}

@media (min-width: 992px) {
  .modal-tg {
    width: 75%;
    height: 75%;
  }
}

.modal-overlay {
  background-color: rgba(103, 103, 103, 0.7490196078);
}

.modal {
  z-index: 9010;
}
.modal.fade .modal-dialog {
  -webkit-transform: translate(0, 0); /* -25% réinitialisé à 0 */
  -ms-transform: translate(0, 0);
  transform: translate(0, 0);
  -webkit-transition: -webkit-transform 0.3s ease-out;
  -moz-transition: -moz-transform 0.3s ease-out;
  -o-transition: -o-transform 0.3s ease-out;
  transition: transform 0.3s ease-out;
}

.modal-header-small {
  padding: 10px;
}
.modal-header-small .modal-header-title {
  margin: 0;
}
.modal-header-small .close {
  margin-top: -11px;
}

.modal-message-ombre {
  -webkit-box-shadow: 1px 1px 5px 0 #555555;
  -moz-box-shadow: 1px 1px 5px 0 #555555;
  filter: progid:DXImageTransform.Microsoft.dropshadow(OffX=1, OffY=1, Color="#555555", Positive="true");
  zoom: 1;
  box-shadow: 1px 1px 5px 0 #555555;
}

/* pour IE9 et + ce hack ci-dessous peut être fait avec les commentaires conditionnels d'IE :
[if gte IE 9]*/
@media only screen {
  .modal-message-ombre {
    filter: none;
  }
}
/*Ie9+*/
.modal-nm {
  width: 600px;
}

.modal-backdrop {
  background-color: #676767;
}

.modal-choisir {
  width: 225px;
  height: 55px;
}

#modal-message-alerte {
  position: absolute;
  z-index: 100000000000;
  background-color: #fff;
}

#modal-message-alerte-footer {
  position: absolute;
  width: 100%;
  margin-left: -15px;
  height: 100%;
  z-index: 2000;
  background-color: #fff;
}

#modal-message-alerte-contenu {
  padding: 25px;
  margin-left: 5px;
  margin-right: 5px;
}

#modal-message-background {
  position: absolute;
  z-index: 2000;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.51);
}

#modal-step-contents {
  display: block;
}

#modalChoisirFiche .in.tools.tools-top {
  -webkit-border-radius: 12px 12px 0 0;
  -moz-border-radius: 12px 12px 0 0;
  border-radius: 12px 12px 0 0;
  background-color: rgba(0, 0, 0, 0.75);
}

/* modal sig specific styles */
.modal-responsive .modal-dialog {
  max-width: calc(100% - 15px);
  width: 800px;
}

.modal-sig-legend .modal-dialog {
  width: 700px;
}

.modal-sig-legend img {
  max-width: 100%;
}

.imageComparable {
  display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
}

.close-comparable-image {
  position: absolute;
  top: 10px;
  right: 10px;
  color: #f1f1f1;
  font-size: 28px;
  font-weight: bold;
}
.close-comparable-image:hover, .close-comparable-image:focus {
  color: #bbb;
  text-decoration: none;
  cursor: pointer;
}

.est_inspecteur {
  background-color: #80b9fe;
}

.modal-body-scrollable {
  overflow: auto;
  height: calc(100vh - 180px);
  min-height: 250px;
}

.modal-over-modal {
  z-index: 9020;
  background-color: rgba(103, 103, 103, 0.5);
}

.modal-over-modal-2 {
  z-index: 9120;
  background-color: rgba(103, 103, 103, 0.5);
}

/* widgets -> styles des widgets */
.daterangepicker .drp-calendar td.today {
  background-color: #040073;
  color: #fff;
}
@media (max-height: 680px) and (max-width: 1024px) {
  .daterangepicker {
    transform: translateY(-50%) translateX(50%);
  }
  .daterangepicker:after, .daterangepicker:before {
    display: none;
  }
  .daterangepicker .calendar-table td, .daterangepicker .calendar-table th {
    padding: 1px 5px;
    height: 20px;
    line-height: 20px;
  }
}

.datepicker-container {
  position: relative;
}

.datatable th div.dataTables_sizing {
  font-size: 12px;
}

div.dt-buttons {
  float: none;
}

#batiment-x-unite-evaluation-sous-bloc-wizard .dataTables_wrapper .sous-bloc-form:first-child, #batiment-x-unite-evaluation-sous-bloc-wizard .dataTables_wrapper .sous-bloc-form:last-child {
  background-color: transparent;
  border-bottom: none;
}
#batiment-x-unite-evaluation-sous-bloc-wizard .dataTables_wrapper .sous-bloc-form .row:first-child, #batiment-x-unite-evaluation-sous-bloc-wizard .dataTables_wrapper .sous-bloc-form .row:last-child {
  background-color: transparent;
  border-bottom: none;
}

.dataTables_wrapper .row:first-child {
  background-color: #fff;
  padding-bottom: 5px;
}
.dataTables_wrapper .row.margin-top-1 { /* écrase ace */ }
.dataTables_wrapper .col-sm-12 {
  padding-left: 0;
  padding-right: 0;
}
.dataTables_wrapper label {
  font-size: 14px;
}

.dataTables_info {
  font-size: 10pt;
}

.DTFC_LeftBodyWrapper {
  top: 0 !important;
}

/*
 * Sort arrow icon positioning
 */
table.display thead th div.DataTables_sort_wrapper {
  position: relative;
  padding-right: 20px;
}
table.display thead th div.DataTables_sort_wrapper span {
  position: absolute;
  top: 50%;
  margin-top: -8px;
  right: 0;
}

.divTable-fake .header {
  line-height: 2.5em;
  margin-right: 0;
  margin-left: 0;
  margin-top: 0;
  margin-bottom: 0;
  padding-bottom: 0;
  color: #707070;
  border-left: 1px solid #ddd;
  border-right: 1px solid #ddd;
  border-top: 1px solid #ddd;
  font-weight: 700;
  background: #F2F2F2 repeat-x;
  background-image: -webkit-linear-gradient(top, #f8f8f8 0, #ececec 100%);
  background-image: -o-linear-gradient(top, #f8f8f8 0, #ececec 100%);
  background-image: linear-gradient(to bottom, #f8f8f8 0, #ececec 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#fff8f8f8", endColorstr="#ffececec", GradientType=0);
}
.divTable-fake .header th {
  border-right: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
  margin: 0;
  padding: 8px;
  line-height: 1.42857143;
  vertical-align: top;
}
.divTable-fake .body {
  line-height: 2.5em;
  margin-right: 0;
  margin-left: 0;
  margin-top: 0;
  padding-bottom: 0;
  color: #707070;
  border-left: 1px solid #ddd;
  border-top: 1px solid #ddd;
  border-right: 1px solid #ddd;
}
.divTable-fake > .body:last-child {
  border-bottom: 0 solid #ddd;
}
.divTable-fake > .body .tr .td {
  border-right: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
  margin: 0;
  padding: 8px;
  line-height: 1.42857143;
  vertical-align: top;
}
.divTable-fake > .body .tr .td input {
  border: 0;
}
.divTable-fake > .body .tr .td.padding-0 {
  padding: 0;
}
.divTable-fake > .body .tr .td:last-child {
  border-right: 0 solid #ddd;
}
.divTable-fake > .body .tr.row {
  margin: 0;
}
.divTable-fake > .body > div > .tr .td input {
  border: 0;
}
.divTable-fake .div-header-suppression {
  padding-left: 12px;
}
.divTable-fake .header-suppression {
  margin-left: 15px;
  margin-right: 0;
}
.divTable-fake.row.champs-multiple {
  margin-left: -5px;
  margin-right: 0;
}
.divTable-fake tr:hover {
  background-color: #f5f5f5;
}
.divTable-fake tr.odd {
  background-color: #f9f9f9;
}
.divTable-fake tr.even {
  background-color: #fff;
}

.div-ctrl-suppression {
  padding-left: 12px;
}

.for-ctrl-suppression {
  margin-left: 15px;
  margin-right: 0;
}

.champs-multiple .divTable-fake label.for-ctrl-suppression > i {
  left: 20px;
}

/* tableflex - simple responsive tables - used in form méthode revenu */
/* tableflex general */
.tableflex-detached {
  border-top: 2px solid #ddd;
}

.tableflex .tableflex-row {
  border: 1px solid #ddd;
  display: flex;
  flex-wrap: wrap;
  margin: 0;
}
.tableflex .tableflex-row .tableflex-col {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-grow: 0;
  flex-shrink: 0;
  padding: 2px;
  word-break: break-word;
  hyphens: auto;
  position: relative;
}
.tableflex .tableflex-row .tableflex-col:not(:last-child) {
  border-right: 1px solid #ddd;
}
.tableflex .tableflex-row .tableflex-col.suppression-ligne:before {
  top: initial;
}
.tableflex .tableflex-head {
  font-weight: 700;
}
.tableflex .tableflex-head .tableflex-row {
  background-color: #e7e7e7;
}
.tableflex .tableflex-head-mobile {
  display: none;
  font-weight: 700;
  margin-bottom: 5px;
}
.tableflex .tableflex-body .tableflex-row {
  border-top: none;
  background-color: #fff;
}
.tableflex .tableflex-body .tableflex-row:nth-child(even) {
  background-color: #f9f9f9;
}
.tableflex .tableflex-body .tableflex-row:hover, .tableflex .tableflex-body .tableflex-row:focus {
  background-color: #f5f5f5 !important;
}
.tableflex .tableflex-footer .tableflex-row {
  border: none;
  background-color: #fff;
}
.tableflex .tableflex-footer .tableflex-row .tableflex-col {
  border-right: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
}
.tableflex .tableflex-footer .tableflex-row .tableflex-col:first-child {
  border-left: 1px solid #ddd;
}
.tableflex .tableflex-footer .tableflex-row:first-child .tableflex-col {
  border-top: 1px solid #ddd;
}
.tableflex .tableflex-input-erreur {
  font-size: 12px;
  line-height: 1.2;
  margin-top: 3px;
  margin-bottom: 0;
}
.tableflex .tableflex-input-erreur:empty {
  display: none;
}
@media (max-width: 991px) {
  .tableflex .tableflex-row {
    flex-wrap: wrap;
  }
  .tableflex .tableflex-row .tableflex-col {
    justify-content: space-between;
    border-top: 1px solid #ddd;
    border-right: 1px solid #ddd;
  }
  .tableflex .tableflex-row .tableflex-col:first-child {
    border-top: none;
    border-right: none;
  }
  .tableflex .tableflex-row .tableflex-col.suppression-ligne:before {
    bottom: 15px;
  }
  .tableflex .tableflex-row .tableflex-col-1:nth-child(4n+4) {
    border-right: none;
  }
  .tableflex .tableflex-head {
    display: none;
  }
  .tableflex .tableflex-head-mobile {
    display: block;
    text-align: left;
  }
}
@media (max-width: 767px) {
  .tableflex .tableflex-row .tableflex-col {
    border: none !important;
  }
  .tableflex .tableflex-row .tableflex-col:not(:last-child) {
    border-bottom: 1px solid #ddd !important;
  }
}

.hyperlien-tableau-operation-ue {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  padding: 0;
  border: none;
  background: none;
  color: #040073;
  text-decoration: underline;
}

/* Utilisé uniquement pour garder un espace entre l'accordéon et le prochain contenu */
.dataTables_length,
.action-buttons-text > i {
  padding-top: 12px;
}

.table-filtres {
  overflow-x: auto;
}
.table-filtres > table {
  margin-bottom: 0 !important;
}

.filtres-actions {
  padding-top: 5px;
}

.ruban-action-datatables {
  position: relative;
  padding-left: 25px;
}

.datatable_alt_container .dataTables_wrapper .row {
  padding: 0;
}
.datatable_alt_container .dataTables_length,
.datatable_alt_container .dataTables_filter,
.datatable_alt_container .dataTables_paginate {
  padding: 12px 0;
}

table.datatable tbody td.no-padding {
  padding: 0;
}
table.dataTable.dtr-inline.collapsed tbody tr.parent td:first-child:before, table.dataTable.dtr-inline.collapsed tbody tr.parent th:first-child:before {
  /* Datatables : Table réduite : Élément courant (donc, fermé) */
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\f13a" !important;
  background-color: #478fca;
}
table.dataTable.dtr-inline.collapsed tbody td:first-child:before, table.dataTable.dtr-inline.collapsed tbody th:first-child:before {
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\f138" !important;
  background-color: #686868;
  line-height: 16px;
  top: 10px;
  width: 17px;
}
table.dataTable > tbody > tr.selected a {
  color: #337ab7;
  text-decoration: none;
}
@media print {
  table.dataTable > tbody > tr.selected a {
    color: #337ab7 !important;
  }
}
table.dataTable > tbody > tr.selected a:hover, table.dataTable > tbody > tr.selected a:focus {
  color: #23527c;
  text-decoration: underline;
}
table.dataTable tbody tr.selected {
  background-color: #B0BED9 !important;
}
table.dataTable tbody tr.selected > th {
  background-color: #B0BED9 !important;
}
table.dataTable tbody tr.selected > td {
  background-color: #B0BED9 !important;
}
table.dataTable tbody tr.odd > .dtfc-fixed-left, table.dataTable tbody tr.odd > .dtfc-fixed-right {
  background-color: #f9f9f9;
}
table.dataTable tbody tr > .dtfc-fixed-right {
  border-left: 1px solid #ddd;
}
table.dataTable tr.odd {
  /* Pour utiliser select de DataTables, on doit utiliser la mécanique de DataSelect et non les options de la version Bootstrap : table-stripe, table-hover. */
  background-color: #f9f9f9;
}
table.dataTable tr.even {
  background-color: #fff;
}
table.dataTable tr:hover {
  background-color: #f5f5f5;
}

@media print {
  .table-striped > tbody > tr:nth-of-type(odd) td {
    background-color: #f9f9f9 !important;
  }
}

[data-type=table-liee].table-striped > tbody > tr:nth-child(even) > td,
.table-striped > tbody > tr:nth-child(even) > th {
  background-color: #dfdfdf;
}
@media print {
  [data-type=table-liee].table-striped > tbody > tr:nth-child(even) > td,
  .table-striped > tbody > tr:nth-child(even) > th {
    background-color: #dfdfdf !important;
  }
}

[data-type=table-liee].table-striped > tbody > tr:nth-child(odd) > td,
.table-striped > tbody > tr:nth-child(odd) > th {
  background-color: #e7e7e7;
}
@media print {
  [data-type=table-liee].table-striped > tbody > tr:nth-child(odd) > td,
  .table-striped > tbody > tr:nth-child(odd) > th {
    background-color: #e7e7e7 !important;
  }
}

tr.entete-table-liee.shown {
  background-color: #D5D5D5 !important;
}

#table-evenements-filtre {
  margin-bottom: 15px;
  white-space: nowrap;
}

#table-evenements {
  margin-bottom: 15px;
  margin-top: 15px;
}

#table-template {
  padding-left: 0;
  padding-right: 0;
}

.div-table {
  /* Utilisé pour simuler une table avec des divs */
  margin: 10px;
}
.div-table .div-table-entete {
  padding: 5px;
  border: 1px solid #ddd;
  color: #707070;
  font-weight: 700;
  background: #F2F2F2 linear-gradient(to bottom, #F8F8F8 0, #ECECEC 100%) repeat-x scroll 0 0;
}
.div-table .div-table-entete.separateur {
  /* pour voir les séparateurs de colonnes pleine hauteur selon la hauteur du div au lieu du texte */
  padding: 0;
  display: -webkit-flex; /* Safari */
  display: flex;
}
.div-table .div-table-entete.separateur > .div-table-colonne {
  padding: 5px;
}
.div-table .div-table-entete.separateur > .div-table-colonne:not(:last-child) {
  border-right: 1px solid #ddd;
  /* pour voir les séparateurs de colonnes pleine hauteur selon la hauteur du div au lieu du texte */
  -webkit-flex: auto; /* Safari 6.1+ */
  -ms-flex: auto; /* IE 10 */
  flex: auto;
}
.div-table .div-table-contenu {
  padding: 5px;
  border: 1px solid #ddd;
  border-top: none;
}
.div-table .div-table-contenu.separateur {
  padding: 0;
}
.div-table .div-table-contenu.separateur > .div-table-ligne {
  /* pour voir les séparateurs de colonnes pleine hauteur selon la hauteur du div au lieu du texte */
  display: -webkit-flex; /* Safari */
  display: flex;
}
.div-table .div-table-contenu.separateur > .div-table-ligne > .div-table-colonne {
  padding: 5px;
}
.div-table .div-table-contenu.separateur > .div-table-ligne > .div-table-colonne:not(:last-child) {
  border-right: 1px solid #ddd;
  /* pour voir les séparateurs de colonnes pleine hauteur selon la hauteur du div au lieu du texte */
  -webkit-flex: auto; /* Safari 6.1+ */
  -ms-flex: auto; /* IE 10 */
  flex: auto;
}
.div-table .div-table-contenu.separateur > .div-table-ligne:not(:last-child) {
  border-bottom: 1px solid #ddd;
}
.div-table div.div-table-contenu:hover {
  background-color: #f5f5f5;
}
.div-table > .div-table-contenu:nth-child(even) {
  background-color: #f9f9f9;
}

/* Fin gestion des checkboxes pour section documents comme dans l'éditeur de fiche */
.table-methode-cout thead > tr th {
  text-align: center;
}
.table-methode-cout thead > tr:first-child {
  color: #fff;
}
@media print {
  .table-methode-cout thead > tr:first-child {
    color: #fff !important;
  }
}
.table-methode-cout thead > tr:first-child > th {
  background-color: #5293c4;
  color: #fff;
}
@media print {
  .table-methode-cout thead > tr:first-child > th {
    background-color: #5293c4 !important;
    color: #fff !important;
  }
}
.table-methode-cout tfoot {
  color: #fff;
}
@media print {
  .table-methode-cout tfoot {
    color: #fff !important;
  }
}
.table-methode-cout tfoot > tr > td {
  background-color: #5293c4;
}
@media print {
  .table-methode-cout tfoot > tr > td {
    background-color: #5293c4 !important;
  }
}

#sommaire-filtres-header {
  padding: 10px;
}

.ligne-entete {
  background-color: #5293c4 !important;
  background-image: none !important;
  color: #fff !important;
}
@media print {
  .ligne-entete {
    background-color: #5293c4 !important;
    background-image: none !important;
    color: #fff !important;
  }
}
.ligne-entete th {
  background-color: #5293c4;
  color: #fff !important;
}
@media print {
  .ligne-entete th {
    background-color: #5293c4 !important;
    color: #fff !important;
  }
}

#table-resume-valeurs-evenement {
  font-weight: bold;
}

/* tables custom styling - based on datatable and ace styling */
.dataTables_custom_container {
  overflow: auto;
}

.dataTables_custom {
  min-width: 560px;
}
.dataTables_custom .dataTables_custom_head {
  color: #707070;
  font-weight: bold;
  padding: 8px;
  border: 1px solid #ddd;
  background: #F2F2F2 repeat-x;
  background-image: -webkit-linear-gradient(top, #f8f8f8 0, #ececec 100%);
  background-image: -o-linear-gradient(top, #f8f8f8 0, #ececec 100%);
  background-image: linear-gradient(to bottom, #f8f8f8 0, #ececec 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#fff8f8f8", endColorstr="#ffececec", GradientType=0);
}
.dataTables_custom .dataTables_custom_subhead tr {
  color: #707070;
  background: #dfdfdf;
  background-image: none;
}

.dataTables_double .dataTables_double_container {
  display: flex;
}
.dataTables_double .dataTables_double_item {
  flex: 1;
  display: flex;
  flex-direction: column;
}
.dataTables_double .dataTables_double_item .dataTables_scrollBody {
  flex-grow: 1;
}
.dataTables_double .dataTables_double_item .dataTables_scrollBody table {
  min-height: 100%;
}
.dataTables_double .dataTables_double_item .dataTables_scrollBody tr:last-child td {
  border-bottom: none;
}
@media (max-width: 1199px) {
  .dataTables_double .dataTables_double_container {
    flex-direction: column;
  }
}

.datatable-columns-fixed {
  white-space: nowrap;
}

.table-scroller-custom .dataTables_scroll {
  overflow: hidden;
}
.table-scroller-custom .dataTables_scrollHead {
  overflow: visible !important;
}

table.dataTable thead > tr > th.sorting:before, table.dataTable thead > tr > th.sorting:after, table.dataTable thead > tr > th.sorting_asc:before, table.dataTable thead > tr > th.sorting_asc:after, table.dataTable thead > tr > th.sorting_desc:before, table.dataTable thead > tr > th.sorting_desc:after, table.dataTable thead > tr > th.sorting_asc_disabled:before, table.dataTable thead > tr > th.sorting_asc_disabled:after, table.dataTable thead > tr > th.sorting_desc_disabled:before, table.dataTable thead > tr > th.sorting_desc_disabled:after, table.dataTable thead > tr > td.sorting:before, table.dataTable thead > tr > td.sorting:after, table.dataTable thead > tr > td.sorting_asc:before, table.dataTable thead > tr > td.sorting_asc:after, table.dataTable thead > tr > td.sorting_desc:before, table.dataTable thead > tr > td.sorting_desc:after, table.dataTable thead > tr > td.sorting_asc_disabled:before, table.dataTable thead > tr > td.sorting_asc_disabled:after, table.dataTable thead > tr > td.sorting_desc_disabled:before, table.dataTable thead > tr > td.sorting_desc_disabled:after {
  opacity: 0.75;
}

.DTFC_NoData {
  display: none;
}

table.dataTable > tbody > tr.selected > * {
  background-color: #b0bed9;
  color: #393939;
  box-shadow: none;
}

/* .datatable existe plus haut, mais pas le même selecteur, css est sensible à la casse */
.dataTable .sorting {
  padding-right: 15px !important;
}
.dataTable .sorting:before {
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\f0d8" !important;
  font-size: 10px !important;
}
.dataTable .sorting:after {
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\f0d7" !important;
  font-size: 10px !important;
}
.dataTable .sorting:before, .dataTable .sorting:after {
  right: 4px !important;
}
.dataTable .tooltip-inner {
  background-color: #393939;
  white-space: initial;
}
.dataTable .ace-thumbnail-picture {
  width: 50px;
  object-fit: cover;
}

.datatables-top-pagination {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 0 12px;
}
.datatables-top-pagination .datatables-topp-left, .datatables-top-pagination .datatables-topp-right {
  display: flex;
  align-items: center;
}
.datatables-top-pagination .datatables-topp-left > div, .datatables-top-pagination .datatables-topp-right > div {
  padding: 12px;
}
.datatables-top-pagination .dataTables_info, .datatables-top-pagination .dataTables_length {
  font-size: 14px;
  padding-top: 12px !important;
}
.datatables-top-pagination .dataTables_length label {
  margin-bottom: 0 !important;
}
@media (max-width: 767px) {
  .datatables-top-pagination {
    flex-direction: column;
    align-items: flex-start;
  }
}

.table-overflow-200 thead, .table-overflow-200 tbody {
  display: block;
  overflow-y: scroll;
}
.table-overflow-200 thead tr, .table-overflow-200 tbody tr {
  display: table;
  width: 100%;
  table-layout: fixed;
}
.table-overflow-200 thead::-webkit-scrollbar {
  opacity: 0;
}
.table-overflow-200 tbody {
  max-height: 200px;
}

.dt-striped-w-child tr.odd + tr:not(.odd):not(.even) {
  background-color: #f9f9f9;
}

.dt-striped-w-child tr.even + tr:not(.odd):not(.even) {
  background-color: #fff;
}

/* principalement datatables */
.revenu-manuel-select2 .select2-chosen {
  text-align: right;
}

.select2-results .select2-result.select2-highlighted {
  background: #040073 !important;
}

.select2-results .select2-result-unselectable {
  cursor: default;
}

/* styles spécifiques au select2 recherche rapide SIG */
.select2-recherche-propriete .select2-results .select2-result:not(:last-child):not(:nth-last-child(2)) {
  border-bottom: 1px solid #ddd;
}

.select2-recherche-propriete .select2-results .select2-result:nth-child(even) {
  background-color: #f9f9f9;
}

.select2-recherche-propriete .select2-result-unselectable {
  position: sticky;
  bottom: 0;
  border-top: 1px solid #ddd;
  background-color: #fff;
}

.checkbox-card-container {
  display: grid;
  grid-gap: 5px;
  grid-template-columns: repeat(4, 1fr);
}
@media (max-width: 1199px) {
  .checkbox-card-container {
    grid-template-columns: repeat(3, 1fr);
  }
}
@media (max-width: 991px) {
  .checkbox-card-container {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media (max-width: 767px) {
  .checkbox-card-container {
    grid-template-columns: 1fr;
  }
}

.checkbox-card {
  cursor: pointer;
  display: flex;
  align-items: center;
  border: 1px solid #ddd;
  padding: 5px 10px;
  margin: 0;
  transition: background-color ease 0.15s; /* transition ace */
}
.checkbox-card .checkbox-card-image {
  flex-grow: 0;
  flex-shrink: 0;
  width: 40px;
}
.checkbox-card .checkbox-card-info {
  flex-grow: 1;
  flex-shrink: 1;
  padding: 0 15px;
  overflow: hidden;
}
.checkbox-card .checkbox-card-checkbox {
  flex-grow: 0;
  flex-shrink: 0;
  padding-top: 4px;
  width: 26px;
}
.checkbox-card:hover, .checkbox-card:focus, .checkbox-card:active, .checkbox-card:active:focus {
  background-color: #f9f9f9;
}

.accordion-style1.panel-group {
  margin-bottom: 0.5em;
}
.accordion-style1.panel-group.display-none {
  display: none;
}
.accordion-style1.panel-group .panel-heading {
  position: relative;
}
.accordion-style1.panel-group.information-ecoforestiere .panel-heading .accordion-toggle {
  background-color: #2a4137;
  color: #fff;
  font-size: initial;
}
.accordion-style1.panel-group.information-ecoforestiere .panel-heading a#collapse-information-ecoforestiere-accordeon {
  background-color: #2a4137;
  color: #fff;
  font-size: initial;
}
.accordion-style1.panel-group.information-ecoforestiere .panel {
  border-color: #2a4137;
}

/* style de l'accordéon pour mobile uniquement */
@media (min-width: 768px) {
  .accordion-style1-mobile-only .panel-heading {
    display: none;
  }
  .accordion-style1-mobile-only .panel, .accordion-style1-mobile-only .panel-collapse, .accordion-style1-mobile-only .panel-body {
    display: block !important;
    height: auto !important;
    padding: 0;
    border: none !important;
    background: none;
  }
}

#headerTitre.information-ecoforestiere {
  color: #2a4137;
}

.accordion-fiches-sticky .panel {
  position: relative;
}
.accordion-fiches-sticky .panel-heading {
  z-index: 1000;
}
.accordion-fiches-sticky .panel-heading.stick-top {
  position: fixed !important;
}
.accordion-fiches-sticky .panel-heading.normal-position {
  position: absolute !important;
  top: 0 !important;
  left: -1px !important;
}
.accordion-fiches-sticky .panel-heading.stick-bottom {
  position: absolute !important;
  top: initial !important;
  left: 0 !important;
  bottom: 0;
}

/* Accordion : Remplacement d'icône pour toute l'application */
.accordion-toggle .fa-angle-down:before {
  content: "\f13a";
}

/* Accordion : Remplacement d'icône pour toute l'application */
.accordion-toggle .fa-angle-right:before {
  content: "\f138";
}

.accordion-toggle-flex {
  display: flex !important;
  align-items: center;
  justify-content: space-between;
}

.label-accordion {
  flex-shrink: 0;
  flex-grow: 0;
}

.accordion-fake {
  border: 1px solid #040073;
  margin-bottom: 15px;
}
.accordion-fake .accordion-fake-heading, .accordion-fake .accordion-fake-heading-lg {
  line-height: 1;
  font-size: initial;
  letter-spacing: initial;
  text-transform: uppercase;
  font-weight: 700;
  color: #fff;
  padding: 10px;
  background-color: #040073;
}
.accordion-fake .accordion-fake-heading-lg {
  font-size: 18px;
}
.accordion-fake .accordion-fake-content {
  font-family: Calibri, sans-serif;
  padding: 15px;
  background-color: #e5e5e5;
  border-top: 1px solid #cdd8e3;
}

.accordion-calcul-actif {
  border: 1px;
  border-color: rgb(135, 186, 33);
  padding-left: 6px;
  /* background: aquamarine; */
  border-style: solid;
  padding-right: 6px;
  padding-top: 5px;
  position: relative;
  margin-top: 35px;
  margin-bottom: 10px;
}

.accordion-calcul-actif-tag {
  width: 150px;
  padding: 3px;
  border: 1px;
  border-style: solid;
  border-color: rgb(135, 186, 33);
  position: absolute;
  top: -30px;
  background-color: rgb(135, 186, 33);
  color: #fff;
  text-align: center;
  vertical-align: middle;
}

.accordion-actions {
  position: absolute;
  z-index: 999;
  right: 15px;
  top: 20%;
  border-color: #fff;
  border-image: none;
  border-style: solid;
  border-width: 0 0 0 1px;
  min-width: 30px;
}

.accordion-actions-inspection {
  position: absolute;
  z-index: 999;
  right: 15px;
  border-color: #fff;
  border-image: none;
  border-style: solid;
  border-width: 0 0 0 1px;
  min-width: 30px;
  max-width: 400px;
  padding-left: 15px;
}
.accordion-actions-inspection .form-group {
  margin-bottom: 0.5px;
}
.accordion-actions-inspection input {
  background-color: #fff;
  height: 31px;
}
@-moz-document url-prefix() {
  .accordion-actions-inspection {
    margin-top: 1.5px;
  }
}

.panel-default > .panel-heading .badge {
  padding-left: 12px;
  padding-right: 12px;
  padding-top: 2px;
}

.panel-group {
  margin-bottom: 0;
  margin-top: 2px;
}

.panel-collapse:not(.in) + .panel-bottom .tableflex-col .tableflex-head-mobile:not(.tableflex-head-mobile-noshow-foot) {
  display: block;
}

/* Positionnement de la liste à l'intérieur d'un accordéon */
.accordion-inside {
  margin-left: 1em;
}

#accordion-creation-adresse-proprietaire-proprietaires-lies .div-table .div-table-contenu.separateur > .div-table-ligne:not(:last-child) {
  border-bottom: none;
}
#accordion-creation-adresse-proprietaire-proprietaires-lies .div-table .div-table-contenu.separateur > .div-table-ligne.regroupement {
  border-bottom: 1px solid #393939;
}
#accordion-creation-adresse-proprietaire-proprietaires-lies .div-table > .div-table-contenu:nth-child(even) {
  background-color: #fff;
}
#accordion-creation-adresse-proprietaire-proprietaires-lies .div-table > div.div-table-contenu:hover {
  background-color: #fff;
}
#accordion-creation-adresse-proprietaire-proprietaires-lies input[type=checkbox].ace.disabled .lbl::before, #accordion-creation-adresse-proprietaire-proprietaires-lies input[type=checkbox].ace:disabled .lbl::before, #accordion-creation-adresse-proprietaire-proprietaires-lies input[type=checkbox].ace[disabled] .lbl::before {
  /* prendre les couleurs de ace sur le checkbox quand il est disabled */
  color: #32a3ce !important;
}

/* couleur dans titre d'accordéons de propagation selon type */
.propagation-conflit > .panel > .panel-heading .accordion-toggle {
  color: red !important;
}

.propagation-touche-gauche > .panel > .panel-heading .accordion-toggle {
  color: blueviolet !important;
}

.accordion-custom .accordion-custom-link-arrow {
  transition: transform 300ms ease;
}
.accordion-custom .accordion-custom-link-arrow.accordion-custom-link-arrow-open {
  transform: rotate(90deg);
}

/* bloc form - used in timelog page (mobile) */
.accordion-toggle-w-info {
  display: flex !important;
}

.accordion-toggle-w-info-arrow {
  flex-grow: 0;
  flex-shrink: 0;
}

.accordion-toggle-w-info-info {
  flex-shrink: 1;
  flex-grow: 1;
}

#map {
  width: 100%;
  height: 100%;
}

#map-avant {
  border-radius: 0.125em;
  border: 2px solid #80b9fe;
  box-shadow: 0 0 8px #999;
  width: 100%;
  height: 100%;
}

#map-apres {
  border-radius: 0.125em;
  border: 2px solid #80b9fe;
  box-shadow: 0 0 8px #999;
  width: 100%;
  height: 100%;
}

.switch-field {
  display: flex;
  overflow: hidden;
  border: 2px solid #616161;
  border-radius: 4px;
  margin-bottom: 15px;
}
.switch-field input {
  position: absolute !important;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  width: 1px;
  border: 0;
  overflow: hidden;
}
.switch-field label {
  color: #616161;
  flex: 1;
  font-size: 12px;
  line-height: 1;
  text-align: center;
  padding: 10px 14px;
  transition: all 0.1s ease-in-out;
  margin-bottom: 0;
}
.switch-field label:hover {
  cursor: pointer;
}
.switch-field label:not(:last-of-type) {
  border-right: 2px solid #616161;
}

.switch-field input:checked + label {
  color: #fff;
  background-color: #040073;
}

.leaflet-container {
  background-color: #fff;
}

/* style custom de boutons pré-existants dans leaflet */
.leaflet-top.leaflet-left {
  padding-bottom: 30px; /* empêche la règle leaflet-control-scale-line de passer par dessus les outils */
}

.leaflet-control-boxzoom.with-background-image {
  margin-top: 0;
  background-size: 16px 16px;
  background-position: center center;
  box-shadow: none;
  border: 2px solid rgba(0, 0, 0, 0.35);
  width: 34px;
  height: 34px;
}

.leaflet-styleeditor.editor-enabled {
  width: 232px;
}
.leaflet-styleeditor.editor-enabled.editor-large {
  width: 450px;
}

.leaf-custom-disabled, .leaflet-disabled {
  pointer-events: none;
  color: #393939;
  opacity: 0.6;
}

.leaflet-styleeditor-header {
  background-color: #6ab12f;
}
.leaflet-styleeditor-header .leaflet-styleeditor-button {
  background-color: #6ab12f;
}
.leaflet-styleeditor-header .leaflet-styleeditor-button:hover, .leaflet-styleeditor-header .leaflet-styleeditor-button:focus, .leaflet-styleeditor-header .leaflet-styleeditor-button:active, .leaflet-styleeditor-header .leaflet-styleeditor-button:active:focus {
  background-color: #5b9729;
}

/* correction affichage informations légales layer apple - https://www.apple.com/legal/internet-services/maps/terms-fr.html */
.mk-controls-container {
  inset: 0 0 17px 0 !important;
}

.history-control {
  margin-top: 0 !important;
}
.history-control.leaflet-bar a {
  width: 30px;
}

.center-map-control {
  margin-top: 0 !important;
}

div[id^=leaflet-control-accordion-layers] > .leaflet-control-accordion-btn {
  background: #fff !important;
}
div[id^=leaflet-control-accordion-layers] > .leaflet-control-accordion-btn:hover, div[id^=leaflet-control-accordion-layers] > .leaflet-control-accordion-btn:focus {
  background: #eee !important;
  color: #585858;
}
div[id^=leaflet-control-accordion-layers] > label {
  font-family: "Arial", Arial, sans-serif;
  text-shadow: none;
}

.ac-container article label {
  cursor: auto;
}

.ac-container input.menu:checked + .leaflet-control-accordion-btn,
.ac-container input.menu:checked + .leaflet-control-accordion-btn:hover,
.ac-container input.menu:checked + .leaflet-control-accordion-btn:focus {
  background: #eee !important;
  color: #393939;
}

/* #15501 : fixe icone "?" dans la légende sigweb2 */
.leaflet-legend {
  top: -50px;
  left: -55.75px;
  display: block;
}

/* header search custom styling - used in SIG-Web 2 page */
.header-search {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  padding-bottom: 4px;
}
.header-search .header-search-btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin: 0 5px 5px 0 !important; /* important to overwrite .btn important */
  min-height: 40px;
}
.header-search .header-search-btn .header-search-btn-txt {
  margin-left: 6px;
}
.header-search .header-search-btn-group {
  margin: 0 5px 5px 0 !important;
  min-height: 40px;
  position: relative;
}
.header-search .header-search-btn-group .btn-input-container {
  height: 100%;
}
.header-search .header-search-btn-group .select2-container {
  display: flex;
  align-items: center;
  flex-grow: 1;
  flex-shrink: 1;
}
.header-search .header-search-btn-group .select2-container .select2-choice {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
}
.header-search .header-search-btn-group .select2-container .select2-arrow b:before {
  vertical-align: sub;
}
.header-search .header-search-select {
  margin: 0 5px 5px 0;
  min-height: 40px;
}
.header-search .header-search-select .select2-choice {
  display: flex;
  align-items: center;
  min-height: 40px;
}
.header-search .header-search-bottom {
  display: flex;
}
.header-search .selected-filters-container {
  display: flex;
  justify-content: flex-end;
  flex-grow: 1;
  flex-shrink: 1;
}
@media (max-width: 1549px) {
  .header-search .header-search-top {
    flex-grow: 1;
  }
  .header-search .selected-filters-container {
    display: none;
  }
}
@media (max-width: 1199px) {
  .header-search .header-search-btn {
    min-width: 15%;
  }
  .header-search .header-search-btn:empty {
    display: none;
  }
  .header-search .header-search-select, .header-search .header-search-btn-group {
    flex-grow: 1;
  }
  .header-search .header-search-top, .header-search .header-search-bottom {
    display: flex;
    align-items: center;
    float: none;
    flex-wrap: wrap;
    width: 100% !important;
  }
}
@media (max-width: 767px) {
  .header-search .header-search-btn .header-search-btn-txt {
    display: none;
  }
  .header-search .header-search-btn-group {
    width: 100%;
  }
}
@media (max-width: 400px) {
  .header-search .header-search-btn {
    min-width: 50px;
  }
}

@media (min-width: 1550px) {
  .selected-filter-count {
    display: none !important;
  }
}

.dropdown-open-top {
  top: initial;
  bottom: 100%;
}

/* map layers styling */
#exp-ue-ajouter-carte {
  position: relative;
}

.map-controls-container {
  position: relative;
  top: 0;
  right: 0;
  z-index: 1000;
}
.map-controls-container .leaflet-control-layers {
  position: absolute;
  top: 13px;
  right: 15px;
  max-width: calc(100vw - 133px);
  transition: right 500ms ease;
  background: #fff;
}
.map-controls-container .leaflet-control-layers:hover, .map-controls-container .leaflet-control-layers:focus, .map-controls-container .leaflet-control-layers:active, .map-controls-container .leaflet-control-layers:active:focus {
  background: #fff;
}
.map-controls-container .leaflet-control-layers-list {
  max-width: 100%;
}
.map-controls-container .leaflet-control-layers-list .leaflet-control-layers-head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 8px;
}
.map-controls-container .leaflet-control-layers-list .leaflet-control-layers-head .btn {
  margin-bottom: 0 !important;
  font-size: 14px;
}
.map-controls-container .leaflet-control-layers-list .leaflet-control-layers-selector {
  margin-top: 0;
  margin-right: 8px;
}
.map-controls-container .leaflet-control-layers-list .leaflet-control-accordion-btn {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 40px; /* prevent overlay with arrow on hover */
  min-height: 30px;
  height: auto;
}
.map-controls-container .leaflet-control-layers-list .leaflet-control-accordion-btn:hover:after {
  top: 50%;
  transform: translateY(-50%);
}
.map-controls-container .leaflet-control-layers-list .menu-item-checkbox, .map-controls-container .leaflet-control-layers-list .menu-item-radio {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
}
.map-controls-container .leaflet-control-layers-list .menu-item-checkbox label, .map-controls-container .leaflet-control-layers-list .menu-item-radio label {
  flex-grow: 1;
  margin-right: 6px;
  margin-bottom: 0;
}
.map-controls-container .bt_legend_container {
  flex-shrink: 0;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 16px;
  height: 16px;
}
.map-controls-container .bt_legend_container:after {
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\f128" !important;
  position: absolute;
  pointer-events: none;
  font-size: 20px;
}
.map-controls-container .bt_legend_container .bt_legend {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  padding: 0;
  border: none;
  background: none;
  width: 100%;
  height: 100%;
}
.map-controls-container .ac-container article {
  margin-top: 0;
}
.map-controls-container:not(.map-controls-container-alt) .leaflet-control-layers.offset-left {
  right: 473px;
}
@media (min-width: 992px) and (max-width: 1199px) {
  .map-controls-container:not(.map-controls-container-alt) .leaflet-control-layers.offset-left {
    right: 403px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .map-controls-container:not(.map-controls-container-alt) .leaflet-control-layers.offset-left {
    right: 318px;
  }
}
@media (max-width: 1199px) {
  .map-controls-container .leaflet-control-layers {
    right: 5px;
  }
}
@media (max-width: 767px) {
  .map-controls-container:not(.map-controls-container-alt) .leaflet-control-layers.offset-left {
    right: 13px;
  }
}

.carte-exp-ue-empty {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px;
  background-color: #ddd;
}
.carte-exp-ue-empty .carte-exp-ue-empty-icon {
  color: #7c7c7c;
  font-size: 130px;
}

.leaflet-touch .leaflet-top.leaflet-right {
  max-width: calc(100% - 64px);
}
.leaflet-touch .leaflet-lsidebar .lineContainer1-title {
  display: inline-block;
  width: 100%;
  margin-top: 20px;
  margin-bottom: 10px;
}
.leaflet-touch .leaflet-lsidebar .lineContainer1-title h3 {
  margin-top: 0;
  margin-bottom: 0;
}
.leaflet-touch .leaflet-lsidebar .title-btn-container {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-wrap: wrap;
  margin: 10px -7px;
}
.leaflet-touch .leaflet-lsidebar .title-btn-container .title-btn-title {
  margin-top: 0;
  margin-bottom: 0;
}
.leaflet-touch .leaflet-lsidebar .title-btn-container .title-btn-btn {
  margin-left: 7px;
  margin-right: 7px;
}
@media (max-width: 1199px) {
  .leaflet-touch .leaflet-lsidebar .lineContainer1 {
    margin-bottom: 14px;
  }
  .leaflet-touch .leaflet-lsidebar .lineContainer1 .left1, .leaflet-touch .leaflet-lsidebar .lineContainer1 .right1 {
    float: none;
    width: 100%;
  }
}
@media (max-width: 767px) {
  .leaflet-touch .leaflet-lsidebar > .leaflet-control {
    padding-right: 15px;
  }
}
@media (max-width: 510px) {
  .leaflet-touch .leaflet-lsidebar .timeline-container:before {
    left: 0;
    width: 6px;
  }
  .leaflet-touch .leaflet-lsidebar .timeline-label {
    margin: 0 0 18px 6px;
  }
  .leaflet-touch .leaflet-lsidebar .timeline-info {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 3px;
  }
  .leaflet-touch .leaflet-lsidebar .timeline-info .timeline-indicator {
    border-radius: 0;
    width: 6px;
    height: 100%;
    border: none !important;
  }
  .leaflet-touch .leaflet-lsidebar .timeline-item .widget-box {
    border-left: none;
    margin: 0 0 0 6px;
  }
}

.leaflet-lsidebar .closeSb, .lsidebar-actions-button {
  background-color: #fff;
  border: 1px solid #cdd8e3;
  width: 36px;
  height: 36px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 400ms ease;
}
.leaflet-lsidebar .closeSb .lsidebar-actions-icon, .lsidebar-actions-button .lsidebar-actions-icon {
  color: #7c7c7c;
  font-size: 18px;
}
.leaflet-lsidebar .closeSb:hover, .lsidebar-actions-button:hover, .leaflet-lsidebar .closeSb:focus, .lsidebar-actions-button:focus {
  background-color: #f9f9f9;
}
.leaflet-lsidebar .closeSb:active, .lsidebar-actions-button:active, .leaflet-lsidebar .closeSb:focus, .lsidebar-actions-button:focus {
  background-color: #ddd;
}

.leaflet-lsidebar .lsidebar-actions-in {
  position: absolute;
  z-index: 1001;
  top: 20px;
  right: 89px;
  display: flex;
  align-items: center;
}
.leaflet-lsidebar .lsidebar-actions-in .lsidebar-actions-button {
  margin-right: 7px;
}
.leaflet-lsidebar .lsidebar-actions-out {
  position: absolute;
  left: -49px;
  top: 49px;
  pointer-events: none;
  opacity: 0;
  transition: opacity 400ms ease;
}
.leaflet-lsidebar .lsidebar-actions-out .lsidebar-actions-button {
  border: none;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.4);
  margin-top: 10px;
}
.leaflet-lsidebar .lsidebar-actions-out .lsidebar-actions-button .lsidebar-actions-icon {
  font-size: 22px;
}
.leaflet-lsidebar.minimized:not(.visible) .lsidebar-actions-out {
  left: -91px;
  opacity: 1;
  pointer-events: initial;
}
.leaflet-lsidebar .closeSb {
  right: 53px;
  color: #fff;
}
.leaflet-lsidebar .closeSb:after {
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\f00d" !important;
  color: #7c7c7c;
  font-size: 18px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateY(-50%) translateX(-50%);
}
@media (max-width: 1199px) {
  .leaflet-lsidebar .lsidebar-actions-in {
    right: 82px;
  }
  .leaflet-lsidebar.minimized:not(.visible) .lsidebar-actions-out {
    left: -150px;
  }
  .leaflet-lsidebar .closeSb {
    right: 46px;
  }
}
@media (max-width: 991px) {
  .leaflet-lsidebar.minimized:not(.visible) .lsidebar-actions-out {
    left: -236px;
  }
}
@media (max-width: 767px) {
  .leaflet-lsidebar .lsidebar-actions-in {
    right: 62px;
  }
  .leaflet-lsidebar.minimized:not(.visible) .lsidebar-actions-out {
    left: -41px;
  }
  .leaflet-lsidebar .closeSb {
    right: 25px;
  }
  .leaflet-lsidebar.right.visible {
    background-color: #fff;
    z-index: 1200;
  }
}

.leaflet-popup-header {
  padding-bottom: 10px;
  margin-bottom: 15px;
  border-bottom: 1px solid #c3c3c3;
}
.leaflet-popup-header .leaflet-popup-header-text {
  margin: 0;
  font-size: 14px;
  font-weight: bold;
}

.leaflet-popup-item:not(:last-child) {
  margin-bottom: 15px;
}
.leaflet-popup-item .leaflet-popup-item-text {
  margin: 0;
}

.leaflet-popup-item-w-button {
  display: flex;
  align-items: center;
}
.leaflet-popup-item-w-button .leaflet-popup-item-text {
  flex-grow: 1;
  flex-shrink: 1;
}
.leaflet-popup-item-w-button .leaflet-popup-item-button {
  flex-grow: 0;
  flex-shrink: 0;
  margin-left: 6px;
}

.leaflet-popup-subitem {
  display: flex;
  margin: -6px;
}
.leaflet-popup-subitem .leaflet-popup-subitem-text {
  flex-grow: 1;
  padding: 6px;
}

.leaflet-draw-collapsable {
  margin-top: 0 !important;
  overflow: hidden;
  height: 0;
  transition: height 400ms ease;
}
@media (min-width: 885px) {
  .leaflet-draw-collapsable {
    margin-top: 10px !important;
    height: auto !important;
    overflow: initial !important;
  }
}

.leaflet-draw .leaflet-draw-section .leaflet-draw-toolbar {
  margin: 0;
  float: none;
}
.leaflet-draw a .sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}

.leaflet-retina .leaflet-draw-toolbar a {
  background-image: linear-gradient(transparent, transparent), url("https://cdnjs.cloudflare.com/ajax/libs/leaflet.draw/1.0.4/images/spritesheet.svg");
}

.leaflet-draw-toolbar {
  margin: 0;
  float: none;
}
.leaflet-draw-toolbar a {
  background-image: linear-gradient(transparent, transparent), url("https://cdnjs.cloudflare.com/ajax/libs/leaflet.draw/1.0.4/images/spritesheet.svg");
  background-size: 300px 30px;
  background-clip: padding-box;
}

.leaflet-touch .leaflet-draw-toolbar .leaflet-draw-draw-markertext,
.leaflet-draw-toolbar .leaflet-draw-draw-markertext {
  background-image: url("../../images/text.png");
  background-size: 15px 15px;
}

.leaflet-draw-toolbar .leaflet-draw-draw-circlemarker {
  background-position: -273px -2px;
}

.leaflet-touch .leaflet-draw-toolbar .leaflet-draw-draw-circlemarker {
  background-position: -271px -1px;
}

@media (min-width: 885px) {
  .leaflet-control-collapse {
    display: none;
  }
}

.leaflet-marker-pane .leaflet-measure-path-measurement {
  font-size: 16px;
}

.leaflet-edit-marker-selected {
  background-color: rgba(254, 87, 161, 0.1);
  border: 4px dashed rgba(254, 87, 161, 0.6);
  -webkit-border-radius: 4px;
  border-radius: 4px;
  box-sizing: content-box;
}

/* styles pour les maps custom / leaflet */
.fa-pdf-evb {
  content: url(/static/images/pdf.png);
  height: 30px;
}

.fa-pdf-evb:before { /* Uniquement pour Firefox */
  content: url(/static/images/pdf_firefox.png);
  height: 25px;
}

.fa-gif-evb {
  content: url(/static/images/gif.png);
  height: 32px;
}

/* Faire version pour Firefox */
.fa-jpg-evb {
  content: url(/static/images/jpg.png);
  height: 32px;
}

.fa-regular-eraser-evb {
  content: url(/static/images/fa-regular-erase.svg);
  width: 14px;
}

.fa-file-checked {
  content: url(/static/images/file-checked.svg);
}

.tape_a_mesurer {
  content: url(/static/images/tape_a_mesurer.svg);
  margin-top: 40%;
  width: 21px;
}

.fa-jpeg-evb {
  content: url(/static/images/jpg.png);
  height: 18px;
}

.fa-doc-evb {
  content: url(/static/images/doc.png);
  height: 32px;
}

.fa-jnt-evb {
  content: url(/static/images/jnt.png);
  height: 32px;
}

.fa-zip-evb {
  content: url(/static/images/zip.png);
  height: 32px;
}

.fa-exclamation-bubble-evb {
  content: url(/static/images/exclamation.svg);
  width: 14px;
}

/* Hack pour font awesome */
.menu-min .fa-support-evb {
  margin-left: 12px;
}

.fa-support-evb-icone-black {
  content: url(/static/images/support_icone.png);
  vertical-align: middle;
  width: 13px !important;
  margin-right: 6px;
}

.fa-support-evb-white {
  content: url(/static/images/support-white.png);
  vertical-align: middle;
  width: 37px !important;
  margin: 0 auto !important;
}

.fa-support-evb-white:before { /* Uniquement pour Firefox */
  content: url(/static/images/support-white-ff.png);
  transform: scale(0.5);
  vertical-align: middle;
  margin: 0 auto !important;
}

.fa-teamviewer-evb-white {
  content: url(/static/images/teamviewer-white.png);
  vertical-align: middle;
  width: 66px !important;
  margin: 0 auto !important;
}

.fa-teamviewer-evb-white:before { /* Uniquement pour Firefox */
  content: url(/static/images/teamviewer-white-ff.png);
  transform: scale(0.5);
  vertical-align: middle;
  margin: 0 auto !important;
}

.teamviewer-svg {
  content: url(/static/images/team-viewer.svg);
  width: 18px;
  margin-right: 6px;
}

.fa-csv-evb {
  content: url(/static/images/csv.png);
  height: 32px;
}

.fa-docx-evb {
  content: url(/static/images/doc.png);
  height: 32px;
}

.fa-docx-evb:before {
  /* Mettre le contenu en base64 parce que URL pas pris en compte ni par Firefox et IE. */
  content: ""; /* Mettre url(/static/images/doc.png); */
}

.fa-xls-evb {
  content: url(/static/images/xls.png);
  height: 32px;
}

.fa-xlsx-evb {
  content: url(/static/images/xls.png);
  height: 32px;
}

.fa-flat-evb {
  content: url(/static/images/flat.png);
  height: 32px;
}

.fa-small {
  font-size: 0.7em;
}

.fa-square-o:before {
  content: "\f096";
}

i.exp-ue-historique-recherche-liste {
  color: #80b9fe;
}

i.exp-ue-historique-recherche-liste:hover {
  color: #457aa3 !important;
}

.fa-disabled {
  opacity: 0.4;
  cursor: not-allowed;
}

/* version alternative de font awesome geocentralis */
.hero {
  background-color: #040073;
  background-size: cover;
  background-position: center center;
}

.caroussel-camera, .camera-video-container, .camera-buttons-container .camera-button, .caroussel-camera .caroussel-camera-slide .caroussel-camera-slide-remove, .camera-replace-container .camera-replace-remove {
  background-color: rgba(57, 57, 57, 0.9);
  border: 4px solid rgba(57, 57, 57, 0.9);
}

.camera-buttons-container .camera-button, .caroussel-camera .caroussel-camera-slide .caroussel-camera-slide-remove, .camera-replace-container .camera-replace-remove {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  cursor: pointer;
  position: relative;
  width: 80px;
  height: 80px;
  border-radius: 50% !important;
  color: #fff !important;
  transition: all 400ms ease !important;
}
.camera-buttons-container .camera-button::file-selector-button, .caroussel-camera .caroussel-camera-slide .caroussel-camera-slide-remove::file-selector-button, .camera-replace-container .camera-replace-remove::file-selector-button {
  opacity: 0;
}
.camera-buttons-container .camera-button:after, .caroussel-camera .caroussel-camera-slide .caroussel-camera-slide-remove:after, .camera-replace-container .camera-replace-remove:after {
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\f00d" !important;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  font-size: 45px;
}
.camera-buttons-container .camera-button:hover, .caroussel-camera .caroussel-camera-slide .caroussel-camera-slide-remove:hover, .camera-replace-container .camera-replace-remove:hover, .camera-buttons-container .camera-button:focus, .caroussel-camera .caroussel-camera-slide .caroussel-camera-slide-remove:focus, .camera-replace-container .camera-replace-remove:focus {
  background-color: rgba(57, 57, 57, 0.6);
}
.camera-buttons-container .camera-button:active, .caroussel-camera .caroussel-camera-slide .caroussel-camera-slide-remove:active, .camera-replace-container .camera-replace-remove:active {
  background-color: #393939;
}

.camera-video-container {
  position: relative;
}
.camera-video-container .camera-video {
  max-width: 100%;
  min-height: 150px;
  max-height: calc(100vh - 280px);
  transition: all 400ms ease !important;
}

.camera-replace-container {
  position: relative;
  background-color: rgb(74.85, 74.85, 74.85);
}
.camera-replace-container .camera-replace-remove {
  position: absolute;
  right: 50px;
  top: 50px;
}
.camera-replace-container .camera-photo {
  max-width: 100%;
}

.caroussel-camera .caroussel-camera-slide {
  position: relative;
}
.caroussel-camera .caroussel-camera-slide .caroussel-camera-slide-remove {
  position: absolute;
  right: 10px;
  top: 8px;
  width: 25px;
  height: 25px;
}
.caroussel-camera .caroussel-camera-slide .caroussel-camera-slide-remove:after {
  font-size: 14px;
}

.camera-buttons-container {
  position: absolute;
  bottom: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}
.camera-buttons-container .camera-button {
  position: relative;
  width: 80px;
  height: 80px;
  padding: 0;
  margin: 0 15px;
}
.camera-buttons-container .camera-button:after {
  font-size: 45px;
}
.camera-buttons-container .camera-button.camera-button-file:after {
  content: "\f093" !important;
}
.camera-buttons-container .camera-button.camera-button-picture {
  display: none;
}
.camera-buttons-container .camera-button.camera-button-picture:after {
  content: "\f030" !important;
}

.avec-camera .camera-buttons-container .camera-button.camera-button-picture {
  display: block;
}

.cursor-pointer {
  cursor: pointer;
}

.cursor-grab {
  cursor: grab !important;
}

.cursor-grabbing {
  cursor: grabbing !important;
}

.cursor-not-allowed {
  cursor: not-allowed !important;
}

.pointer-events-none {
  pointer-events: none;
}

.reset-list {
  margin: 0;
  padding: 0;
  list-style: none;
}

.breadcrumbs {
  background-color: #fff;
  line-height: 36px;
  margin-top: 4px;
  min-height: 36px;
}

.popover-content {
  line-height: 20px;
  font-size: 11px;
}

/*PERMET DE REMETTRE LE CALENDRIER DEVANT LA FENÊTRE MODAL DANS CHROME*/
.datepicker-dropdown {
  z-index: 10000 !important;
}

div[data-type=travaux-batiment-nature-contenu] .panel-body {
  background-color: #F6F6F6;
}
@media print {
  div[data-type=travaux-batiment-nature-contenu] .panel-body {
    background-color: #F6F6F6 !important;
  }
}
div[data-type=travaux-batiment-nature-contenu] > [data-type=travaux-batiment-nature-type]:nth-child(odd) {
  background-color: #e5e5e5;
  padding-top: 15px;
}
@media print {
  div[data-type=travaux-batiment-nature-contenu] > [data-type=travaux-batiment-nature-type]:nth-child(odd) {
    background-color: #e5e5e5 !important;
  }
}
div[data-type=travaux-batiment-nature-contenu] > [data-type=travaux-batiment-nature-type]:nth-child(even) {
  background-color: #eee;
  padding-top: 15px;
}
@media print {
  div[data-type=travaux-batiment-nature-contenu] > [data-type=travaux-batiment-nature-type]:nth-child(even) {
    background-color: #eee !important;
  }
}

/* icones pieds ou mètres dans SIG */
.custom-pieds, .custom-metrique {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
}
.custom-pieds:after, .custom-metrique:after {
  font-size: 16px;
  font-family: serif;
  font-weight: 600;
}

.custom-pieds:after {
  content: "pi";
}

.custom-metrique:after {
  content: "m";
}

/*ON ENLÈVE LA ROTATION APPLIQUÉE AUX ICÔNES DE DOWNLOAD DANS LE FUELUX TREE*/
.tree .ace-icon[class*=-down] {
  transform: rotate(0deg);
}

@media (max-width: 767px) {
  .action-grille-icone {
    padding: 10px;
  }
}
@media (min-width: 768px) {
  .action-grille-icone {
    padding: 0;
  }
}

.breadcrumbs {
  margin-bottom: 0;
}

.ruban-action-datatables .btn-group + .btn-sm, .btn-group > .btn-sm {
  border-width: 1px;
}

#ruban-action-wizard {
  margin-left: 12px;
}

.ruban-boutons-explorateur .btn {
  margin-bottom: 5px;
}
@media (max-width: 767px) {
  .ruban-boutons-explorateur .btn {
    width: calc(33.3333333333% - 3px);
  }
}
@media (max-width: 700px) {
  .ruban-boutons-explorateur .btn {
    width: calc(50% - 3px);
  }
}
@media (max-width: 510px) {
  .ruban-boutons-explorateur .btn {
    width: 100%;
  }
}

.badge-sig-primary {
  background-color: #040073 !important;
}
@media print {
  .badge-sig-primary {
    background-color: #040073 !important;
  }
}

.badge-sig-secondary {
  background-color: #6ab12f !important;
}
@media print {
  .badge-sig-secondary {
    background-color: #6ab12f !important;
  }
}

.badge .badge-text {
  display: none;
  position: absolute;
  z-index: 100;
  background-color: #393939;
  padding: 2px 5px;
  pointer-events: none;
  bottom: calc(100% + 5px);
  left: 50%;
  transform: translateX(-50%);
}
@media print {
  .badge .badge-text {
    background-color: #393939 !important;
  }
}
.badge .badge-text:after {
  content: "";
  position: absolute;
  left: calc(50% - 5px);
  bottom: -5px;
  width: 10px;
  height: 10px;
  background-color: #393939;
  transform: rotate(45deg);
  z-index: -1;
}
@media print {
  .badge .badge-text:after {
    background-color: #393939 !important;
  }
}
@media (max-width: 1199px) {
  .badge .badge-text {
    left: -5px;
    transform: none;
  }
}
@media (max-width: 1199px) {
  .badge .badge-text:after {
    left: 10px;
  }
}
.badge.badge-w-text:hover .badge-text, .badge.badge-w-text:focus .badge-text {
  display: block;
}

.dropdown-menu.pull-right {
  left: initial;
}
.dropdown-menu.pull-right.dropdown-caret:after {
  left: initial;
  right: 10px;
}
.dropdown-menu.pull-right.dropdown-caret:before {
  left: initial;
  right: 9px;
}

.dropdown-sig-primary > li > a, .dropdown-sig-primary .dropdown-submenu > a {
  border: 4px solid rgba(255, 255, 255, 0);
}
.dropdown-sig-primary > li > a:hover, .dropdown-sig-primary > li > a:focus, .dropdown-sig-primary .dropdown-submenu > a:hover, .dropdown-sig-primary .dropdown-submenu > a:focus {
  background: #040073 !important;
  border-color: #040073;
  color: #fff;
}
.dropdown-sig-primary > li > a:active, .dropdown-sig-primary > li > a:active:focus, .dropdown-sig-primary .dropdown-submenu > a:active, .dropdown-sig-primary .dropdown-submenu > a:active:focus {
  background: #030052 !important;
  border-color: #040073;
  color: #fff;
}
.dropdown-sig-primary > li.active > a, .dropdown-sig-primary > li.active > a:hover, .dropdown-sig-primary .dropdown-submenu.active > a, .dropdown-sig-primary .dropdown-submenu.active > a:hover {
  background: #040073 !important;
  border-color: #040073;
  color: #fff;
}
.dropdown-sig-primary .dropdown-submenu:hover > a {
  background: #040073 !important;
  border-color: #040073;
  color: #fff;
}
@media (max-width: 1199px) {
  .dropdown-sig-primary.pull-right {
    left: 5px;
    right: initial;
  }
  .dropdown-sig-primary.pull-right.dropdown-caret:after {
    left: 10px;
    right: initial;
  }
  .dropdown-sig-primary.pull-right.dropdown-caret:before {
    left: 9px;
    right: initial;
  }
}
@media (max-width: 510px) {
  .dropdown-sig-primary {
    width: calc(100vw - 100px);
  }
  .dropdown-sig-primary.dropdown-menu > li > a {
    white-space: initial;
  }
}

.clearable {
  background: #fff url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABEAAAARCAYAAAA7bUf6AAAABmJLR0QA/wD/AP+gvaeTAAAACXBIWXMAABcSAAAXEgFnn9JSAAAAB3RJTUUH3wMTDQ0nMszejgAAAOtJREFUOMvdki1uwkEQxX/QBIGnpqpIkGCQaE7SnuC54nZuwHX+Hg0OGoJrj0ACNbPNZtj1hGcm8/L27XzB88LMcnwBSClRxsxnXUavYvQOHIGppH3BT4AdMJb0Xb7pB4M34ODpzszmzs/dAODgurqJ52V1WzP7Arah+n7VxMyQdAaWwXgd8qWkczmXfxNJpJSQ1AEr4BIeX4CVpM51zXYyfoBr4K7O36EX2im30MJU0j7r71ZsZqPKbxvgI3Cvkn5b7QyBWxjiZxj2zXXN7ZyAmVMLSZ3zHbBwfibpFK+2dvaDxtkPamf/OPgDVfRoowG2LpYAAAAASUVORK5CYII=") no-repeat right -25px center;
  border: 1px solid #999;
  padding: 3px 18px 3px 4px;
  border-radius: 3px;
  transition: background 0.4s;
}
@media print {
  .clearable {
    background: #fff url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABEAAAARCAYAAAA7bUf6AAAABmJLR0QA/wD/AP+gvaeTAAAACXBIWXMAABcSAAAXEgFnn9JSAAAAB3RJTUUH3wMTDQ0nMszejgAAAOtJREFUOMvdki1uwkEQxX/QBIGnpqpIkGCQaE7SnuC54nZuwHX+Hg0OGoJrj0ACNbPNZtj1hGcm8/L27XzB88LMcnwBSClRxsxnXUavYvQOHIGppH3BT4AdMJb0Xb7pB4M34ODpzszmzs/dAODgurqJ52V1WzP7Arah+n7VxMyQdAaWwXgd8qWkczmXfxNJpJSQ1AEr4BIeX4CVpM51zXYyfoBr4K7O36EX2im30MJU0j7r71ZsZqPKbxvgI3Cvkn5b7QyBWxjiZxj2zXXN7ZyAmVMLSZ3zHbBwfibpFK+2dvaDxtkPamf/OPgDVfRoowG2LpYAAAAASUVORK5CYII=") no-repeat right -25px center !important;
  }
}
.clearable.x {
  background-position: right 5px center;
}
.clearable.onX {
  cursor: pointer;
}

.close-message {
  float: right;
  font-size: 16px;
  font-weight: 700;
  color: #393939;
  text-shadow: 0 1px 0 #fff;
  opacity: 0.2;
  border: 0 none;
  padding: 0;
  cursor: pointer;
  background: none repeat scroll 0 0 transparent;
}
.close-message:hover, .close-message:focus {
  color: #393939;
  text-decoration: none;
  cursor: pointer;
  opacity: 0.5;
}

@media (max-width: 1199px) {
  .widget-toolbar.no-border {
    float: left;
    padding: 0;
  }
}

.batiment-entete-bootstrap {
  display: inline-block;
  width: 150px;
}

div .childSlider {
  display: none;
  margin-left: 26px;
}

.repere,
.row-repere {
  display: -webkit-flex; /* Safari */
  display: flex;
}

.cell-repere {
  border-color: #a11506;
  border-style: solid;
  border-left-style: hidden;
  border-bottom-style: hidden;
  border-width: thin;
  min-height: 60px;
  -webkit-flex: auto; /* Safari 6.1+ */
  -ms-flex: auto; /* IE 10 */
  flex: auto;
}

.cell-resultat {
  min-height: 60px;
  flex: auto;
}

.liens-reperes .lien.active {
  font-weight: bold;
  background-color: #eef4f9;
}
@media print {
  .liens-reperes .lien.active {
    background-color: #eef4f9 !important;
  }
}
.liens-reperes a:focus {
  color: #0050b2;
  text-decoration: None;
}
@media print {
  .liens-reperes a:focus {
    color: #0050b2 !important;
  }
}

.row-resultat {
  border-color: #a11506;
  border-style: solid;
  border-left-style: hidden;
  border-bottom-style: hidden;
  border-right-style: hidden;
  border-width: thin;
}

.cell-nom-repere {
  background-color: #fae3ba;
  font-weight: bold;
}
@media print {
  .cell-nom-repere {
    background-color: #fae3ba !important;
  }
}
.cell-nom-repere.first {
  border-left-style: solid;
}

.cell-resultat.resultat {
  flex: auto;
  width: 6.5%;
}

.repere-col-first {
  padding-left: 0;
}

.repere-header-col-first {
  flex: auto;
}

.cell-repere.choix-classe {
  border: thick solid #fff;
}
.cell-repere.selected {
  background-color: #c2fac1;
}
@media print {
  .cell-repere.selected {
    background-color: #c2fac1 !important;
  }
}
.cell-repere.click {
  cursor: pointer;
}

div[disabled].cell-repere {
  cursor: not-allowed;
}
div[disabled].cell-repere.cell-repere-disabled {
  background-color: #c0c0c0 !important;
}
@media print {
  div[disabled].cell-repere.cell-repere-disabled {
    background-color: #c0c0c0 !important;
  }
}

.repere-header {
  background-color: #a11506;
  color: #fff;
  text-align: center;
  font-weight: bold;
  border: thick solid #fff;
  flex: auto;
}
@media print {
  .repere-header {
    background-color: #a11506 !important;
    color: #fff !important;
  }
}

.titre-resultat {
  text-align: right;
  font-weight: bold;
}

.zoom-contenu-bouton.droite {
  margin-left: 7px;
  float: right;
}

.contenu-zoom {
  background: #e5e5e5;
}
@media print {
  .contenu-zoom {
    background: #e5e5e5 !important;
  }
}

.zoom {
  z-index: 10000;
  width: 100%;
  height: 100%;
}

.zoom-hover:hover {
  transform: scale(1.01);
}

.drop-shadow-arrondi {
  -webkit-border-radius: 12px !important;
  -moz-border-radius: 12px !important;
  border-radius: 12px !important;
}
.drop-shadow-arrondi:hover {
  -moz-box-shadow: 3px 3px 4px #b1b1b1 !important;
  -webkit-box-shadow: 3px 3px 4px #b1b1b1 !important;
  box-shadow: 3px 3px 4px #b1b1b1 !important;
}

#entete-fixe-reperes, #entete-fixe-propagation, #entete-fixe {
  margin: auto;
  z-index: 1000;
  background-color: #fff;
}
@media print {
  #entete-fixe-reperes, #entete-fixe-propagation, #entete-fixe {
    background-color: #fff !important;
  }
}

#entete-fixe {
  position: fixed;
  top: 0;
  width: 100%;
}

#entete-fixe-propagation {
  position: fixed;
  width: 100%;
}

.flash-message {
  padding-top: 6px;
  padding-bottom: 6px;
  padding-left: 6px;
  padding-right: 6px;
}
.flash-message .panel-group {
  margin-bottom: 0;
}

.steps li.complete .step {
  cursor: pointer;
}
.steps li:before {
  right: 50%;
  box-shadow: 0 0 0 1px #C2C1C1 !important;
}
@media print {
  .steps li:before {
    box-shadow: 0 0 0 1px #C2C1C1 !important;
  }
}
.steps li .title {
  font-size: 0.85em;
}
.steps li.active .step {
  -moz-transform: scale(1.1);
  -webkit-transform: scale(1.1);
  -o-transform: scale(1.1);
  -ms-transform: scale(1.1);
  transform: scale(1.1);
  border-color: #87ba21;
  box-shadow: 0 0 0 2px #5293c4 !important;
  position: relative;
}
@media print {
  .steps li.active .step {
    box-shadow: 0 0 0 2px #5293c4 !important;
  }
}
.steps li.active .title {
  font-weight: bold;
}
.steps li.active:before {
  border-color: #5293c4;
  right: 50%;
}
.steps li:only-child:before {
  display: none;
}
.steps li:last-child:not(:first-child):before {
  max-width: 100%;
  width: 100%;
  right: 50%;
}

.certificat-diff-fleche {
  font-size: 13px;
  margin-top: 5px;
  margin-left: -4px;
  color: #eb0d0f;
}
@media print {
  .certificat-diff-fleche {
    color: #eb0d0f !important;
  }
}

.list-group-item:last-child {
  margin-bottom: -1px;
}

.div-list-group-item {
  display: flex;
  align-items: center;
  padding: 0;
}
.div-list-group-item .div-list-group-item-content {
  flex-grow: 1;
  padding: 10px 15px;
}
.div-list-group-item .div-list-group-item-actions {
  padding: 10px 15px 10px 0;
}
.div-list-group-item .div-list-group-item-btn {
  margin: 0 !important;
}
.div-list-group-item:hover, .div-list-group-item:focus {
  text-decoration: none;
  color: #555;
  background-color: #f5f5f5;
}

.liste-nature-over:hover {
  background-color: #f5f5f5;
}

.liste-over:hover {
  background-color: #f5f5f5;
}

.drop-down-item {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  padding: 0;
  border: none;
  background: none;
  width: 100%;
  background-color: #fff;
  text-align: left;
}
@media print {
  .drop-down-item {
    background-color: #fff !important;
  }
}
.drop-down-item:hover, .drop-down-item:focus, .drop-down-item:active, .drop-down-item:active:focus {
  background-color: #5293c4;
}

/* toasts */
#messages {
  border-radius: 6px;
  z-index: 9999;
  border-width: 1px;
  box-shadow: 0 2px 2px #888;
  line-height: 25px;
  font-size: 14px;
  top: -5px;
  padding-bottom: 7px;
  padding-top: 7px;
  visibility: hidden;
  position: fixed;
  background: #fefff4; /* Old browsers */
  background: -moz-linear-gradient(top, #fefff4 0%, #e9eddc 100%); /* FF3.6+ */
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #fefff4), color-stop(100%, #e9eddc)); /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(top, #fefff4 0%, #e9eddc 100%); /* Chrome10+,Safari5.1+ */
  background: -o-linear-gradient(top, #fefff4 0%, #e9eddc 100%); /* Opera 11.10+ */
  background: -ms-linear-gradient(top, #fefff4 0%, #e9eddc 100%); /* IE10+ */
  background: linear-gradient(to bottom, #fefff4 0%, #e9eddc 100%); /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#fefff4", endColorstr="#e9eddc", GradientType=0); /* IE6-9 */
}
#messages p {
  font-size: 15px;
}
#messages > span {
  display: inline-block;
  vertical-align: middle;
  line-height: normal;
}

#message-toast {
  -webkit-box-shadow: 0 0 12px rgba(255, 255, 255, 0.51);
  -moz-box-shadow: 0 0 12px rgba(255, 255, 255, 0.51);
  box-shadow: 0 0 12px rgba(255, 255, 255, 0.51);
  border-radius: 15px;
  bottom: 100px;
  color: #fff;
  font-size: 18px;
  left: 0;
  line-height: 22px;
  margin: auto;
  max-width: 500px;
  min-width: 100px;
  padding: 20px;
  position: fixed;
  right: 0;
  text-align: center;
  width: 90%;
  z-index: 1000000;
}

ul.nav-tabs li.active i.ace-icon {
  color: #478fca;
}
@media print {
  ul.nav-tabs li.active i.ace-icon {
    color: #478fca !important;
  }
}

ul.nav-tabs li:not(active) i.ace-icon {
  color: #bbb;
}
@media print {
  ul.nav-tabs li:not(active) i.ace-icon {
    color: #bbb !important;
  }
}

.paginate_button {
  padding: 0 !important;
}

.pagination > li > a {
  margin: 0;
}

.sous-total {
  text-decoration: underline !important;
  font-weight: bold;
}

.total {
  text-decoration: underline !important;
  border-bottom: 1.5px solid #393939 !important;
  font-weight: bold;
}

#spinorama {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 90000000;
}
#spinorama:empty {
  display: none;
}

.evenements-multiples-fleche {
  background-color: #D5D5D5;
}
@media print {
  .evenements-multiples-fleche {
    background-color: #D5D5D5 !important;
  }
}

.tooltip {
  position: fixed;
}

/*Optimisation de la couleur des tooltips de Bootstrap pour GeoCentralis*/
.tooltip-inner {
  background-color: #838383;
}
@media print {
  .tooltip-inner {
    background-color: #838383 !important;
  }
}

.ui-autocomplete {
  position: absolute;
  cursor: default;
  z-index: 1000000 !important;
}

.information-animated-explo, .information-animated-sig, .information-animated-alt {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 15px;
  min-height: 300px;
  width: 100%;
  transition: transform 450ms ease, opacity 450ms ease;
}
.hiding.information-animated-explo, .hiding.information-animated-sig, .hiding.information-animated-alt {
  opacity: 0;
  transform: translateX(10px);
}
.information-animated-explo .information-animated-text, .information-animated-sig .information-animated-text, .information-animated-alt .information-animated-text {
  color: #7c7c7c;
  font-size: 20px;
  font-weight: 700;
  text-align: center;
  width: 360px;
  transform: translateY(-40px);
  transition: transform 500ms ease;
}
@media print {
  .information-animated-explo .information-animated-text, .information-animated-sig .information-animated-text, .information-animated-alt .information-animated-text {
    color: #7c7c7c !important;
  }
}
.information-animated-explo .information-animated-btn, .information-animated-sig .information-animated-btn, .information-animated-alt .information-animated-btn {
  transform: translateY(-40px);
  transition: transform 500ms ease;
}
.information-animated-explo .information-animated-icon, .information-animated-sig .information-animated-icon, .information-animated-alt .information-animated-icon {
  width: 220px;
  opacity: 0;
  margin-bottom: 20px;
  transform: scale(0.5);
  transition: transform 500ms ease, opacity 500ms ease;
}
.loaded.information-animated-explo .information-animated-text, .loaded.information-animated-sig .information-animated-text, .loaded.information-animated-alt .information-animated-text, .loaded.information-animated-explo .information-animated-btn, .loaded.information-animated-sig .information-animated-btn, .loaded.information-animated-alt .information-animated-btn {
  transform: translateY(0);
}
.loaded.information-animated-explo .information-animated-icon, .loaded.information-animated-sig .information-animated-icon, .loaded.information-animated-alt .information-animated-icon {
  opacity: 1;
  transform: scale(1);
}

.information-animated-alt {
  position: static;
  min-height: 0;
}
.information-animated-alt .information-animated-icon {
  font-size: 120px;
  width: auto;
  color: #7c7c7c;
  margin-bottom: 0;
}

.information-animated-sig {
  min-height: 400px;
}

.information-animated-explo {
  position: sticky;
}
@media (min-width: 992px) {
  .information-animated-explo .contenu-explo {
    position: absolute;
  }
}

.widget-main-alt {
  padding-right: 24px;
}

.widget-main .exp-recherche {
  padding-bottom: 12px;
  padding-left: 12px;
  padding-right: 12px;
  padding-top: 0;
  padding-left-ltr-source: physical;
  padding-left-rtl-source: physical;
  padding-right-ltr-source: physical;
  padding-right-rtl-source: physical;
}

.wizard-accordion-sub-title {
  color: #4c8fbd;
  background-color: #eef4f9;
  position: relative;
  font-weight: 700;
  font-size: 13px;
  padding: 8px;
  display: block;
  margin-top: 10px;
}
@media print {
  .wizard-accordion-sub-title {
    color: #4c8fbd !important;
    background-color: #eef4f9 !important;
  }
}

.wizard-odd {
  background-color: #edf2f6;
}

.wizard-even {
  background-color: #dcedf9;
}

.wizard-autre-evenement {
  margin-top: 5px;
  padding-left: 10px;
  padding-right: 10px;
  background-color: #fffac4;
  color: #8a6d3b;
  border-color: #D8D045;
}
@media print {
  .wizard-autre-evenement {
    background-color: #fffac4 !important;
    color: #8a6d3b !important;
  }
}
.wizard-autre-evenement .lien-evenement {
  padding: 5px;
  margin-left: 10px;
  display: inline-block;
}

.wizard-executer-propagation {
  margin-top: 5px;
  padding-left: 10px;
  padding-right: 10px;
  border-color: #D8D045;
  background-color: #fffac4;
  color: #8a6d3b;
}
@media print {
  .wizard-executer-propagation {
    background-color: #fffac4 !important;
    color: #8a6d3b !important;
  }
}

.banniere-propagation-succes {
  margin-top: 5px;
  padding-left: 10px;
  padding-right: 10px;
  background-color: #87b87f !important;
}
@media print {
  .banniere-propagation-succes {
    background-color: #87b87f !important;
  }
}

.affix-bottom {
  top: 0 !important;
}

.align-bottom-evb-parent {
  position: relative;
}

.align-bottom-evb {
  position: absolute;
  bottom: 3px;
}

.stuck { /* Utilisé pour waypoint */
  position: fixed;
  z-index: 8000;
  top: 45px; /* Hauteur de navbar navbar-default navbar-fixed-top de index.html */
}

.ui-slider-handle-custom {
  width: 42px !important;
  height: 22px !important;
  top: 50% !important;
  transform: translateY(-50%);
  text-align: center;
  line-height: 1.6em;
}
.ui-slider-handle-custom:before, .ui-slider-handle-custom:after {
  display: none !important;
}

.slimScrollBar {
  display: block !important;
}

.ace-thumbnails > li {
  border: 0;
}
.ace-thumbnails .ace-thumbnail-single {
  float: none;
  overflow: visible;
}

/* changer la hauteur initiale de ace: sert à voir le texte sur plusieurs lignes dans un tree view*/
.tree .tree-item {
  height: auto;
}

.gauge {
  margin: 10px 10px;
}

.fiche-residentiel i.display-id {
  color: #393939;
}

.display-id {
  position: absolute;
  z-index: 999;
  left: -10px;
  margin-top: 10px;
}

.white-popup-block {
  background: #fff;
  padding: 20px 30px;
  text-align: left;
  max-width: 650px;
  margin: 40px auto;
  position: relative;
}

#custom-content-popup img {
  max-width: 100%;
  margin-bottom: 10px;
}

#pageSpinner {
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0);
  display: flex !important;
  align-items: center;
  justify-content: center;
  position: absolute;
  z-index: 1000;
  top: 0;
}

#page-spinner-copy {
  color: #393939;
  margin-top: 180px;
  font-size: 25px;
}

.ace-spinner-width-100 .ace-spinner {
  width: 100% !important;
}

.ace-spinner {
  max-width: 100%;
}

.nb_regroupement_popover > .popover {
  z-index: 123456;
}

/* pour le scroll du content */
#tab-sommaire {
  height: auto;
  position: relative;
}

.tabs-responsive {
  display: flex;
}
@media (max-width: 767px) {
  .tabs-responsive {
    flex-direction: column;
  }
  .tabs-responsive.nav-tabs > li.active > a, .tabs-responsive.nav-tabs > li.active > a:focus, .tabs-responsive.nav-tabs > li.active > a:hover {
    border-top: 1px solid #c5d0dc;
    border-left: 4px solid #4c8fbd;
    border-bottom: 1px solid #c5d0dc;
  }
}

.search-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 35px;
  padding-bottom: 10px;
}
.search-header .search-header-left {
  margin-right: 15px;
}
.search-header .search-header-right .btn {
  margin-bottom: 0 !important;
}
@media (max-width: 991px) {
  .search-header {
    flex-direction: column-reverse;
    align-items: flex-start;
  }
  .search-header .search-header-left {
    margin-right: 0;
  }
  .search-header .search-header-right {
    align-self: flex-end;
    margin-bottom: 5px;
  }
}
@media (max-width: 767px) {
  .search-header {
    flex-direction: row;
    align-items: center;
  }
  .search-header .search-header-left {
    margin-right: 15px;
  }
  .search-header .search-header-right {
    margin-bottom: 0;
  }
}
@media (max-width: 510px) {
  .search-header {
    flex-direction: column-reverse;
    align-items: flex-start;
  }
  .search-header .search-header-left {
    margin-right: 0;
  }
  .search-header .search-header-right {
    margin-bottom: 5px;
  }
}

@media print {
  .widget-header {
    background: #f7f7f7 repeat-x !important;
    background-image: -webkit-linear-gradient(top, #fff 0, #eee 100%) !important;
    background-image: -o-linear-gradient(top, #fff 0, #eee 100%) !important;
    background-image: linear-gradient(to bottom, #fff 0, #eee 100%) !important;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffffffff", endColorstr="#ffeeeeee", GradientType=0) !important;
  }
}

.widget-header-resp {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 6px;
}
.widget-header-resp:before, .widget-header-resp:after {
  display: none;
}
.widget-header-resp .widget-header-left, .widget-header-resp .widget-header-right {
  float: none;
}
.widget-header-resp .widget-header-left .btn, .widget-header-resp .widget-header-right .btn {
  margin-bottom: 0 !important;
}
.widget-header-resp .widget-header-left {
  margin-right: 15px;
}
@media (max-width: 510px) {
  .widget-header-resp {
    flex-direction: column-reverse;
    align-items: flex-start;
  }
  .widget-header-resp .widget-header-left {
    margin-right: 0;
  }
  .widget-header-resp .widget-header-right {
    align-self: flex-end;
    margin-bottom: 8px;
  }
}

/* responsive widget alt - for table/collapse header w arrows */
.widget-header-alt {
  display: flex;
  padding: 0;
}
.widget-header-alt .widget-header-alt-title {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-grow: 1;
  flex-shrink: 1;
  line-height: 1.2;
  padding: 8px 15px;
}
.widget-header-alt .widget-header-alt-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 0;
  flex-shrink: 0;
}

/* fake ol (number list) responsive styling */
.nbr-list-resp {
  position: relative;
  padding-left: 48px;
}
.nbr-list-resp .nbr-list-nbr {
  position: absolute;
  left: 0;
  margin-right: 0;
}
@media (max-width: 510px) {
  .nbr-list-resp {
    padding-left: 35px;
  }
}

.sectionContainer-resp .sectionContainer-col-container {
  display: flex;
  justify-content: space-between;
}
.sectionContainer-resp .sectionContainer-col-container .sectionContainer-col-left, .sectionContainer-resp .sectionContainer-col-container .sectionContainer-col-right {
  float: none;
  width: calc(50% - 7px);
}
@media (max-width: 991px) {
  .sectionContainer-resp .sectionContainer-col-container {
    flex-direction: column;
  }
  .sectionContainer-resp .sectionContainer-col-container .sectionContainer-col-left, .sectionContainer-resp .sectionContainer-col-container .sectionContainer-col-right {
    width: 100%;
  }
  .sectionContainer-resp .sectionContainer-col-container .sectionContainer-col-left {
    margin-bottom: 15px;
  }
}

.lineContainer-resp {
  display: flex;
  align-items: center;
  margin-bottom: 4px;
}
.lineContainer-resp .left_fp, .lineContainer-resp .right_fp {
  float: none;
  margin-bottom: 0;
}
.lineContainer-resp .right_fp {
  padding-left: 15px;
}
@media (max-width: 767px) {
  .lineContainer-resp {
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 15px;
  }
  .lineContainer-resp .left_fp, .lineContainer-resp .right_fp, .lineContainer-resp .plusLarge, .lineContainer-resp .plusMince {
    width: 100%;
  }
  .lineContainer-resp .left_fp {
    margin-bottom: 4px;
  }
  .lineContainer-resp .right_fp {
    padding-left: 0;
  }
}

.row-info-number .col-number {
  width: 125px;
}

.bloc-historique {
  overflow-x: hidden;
}

#ef-date-evenement-historik {
  list-style-type: none;
  width: 185px;
  margin: 0;
}

.bloc-historique-fond-barre {
  background-image: repeating-linear-gradient(-45deg, transparent 0 20px, rgba(255, 255, 255, 0.3333333333) 20px 40px) !important;
}
@media print {
  .bloc-historique-fond-barre {
    background-image: repeating-linear-gradient(-45deg, transparent 0 20px, rgba(255, 255, 255, 0.3333333333) 20px 40px) !important;
  }
}

.barre {
  text-decoration: line-through !important;
}

.modal-animated-fade .modal-animated-fade-item, .print-animated .modal-animated-fade-item, .print-animated .print-animated-content {
  transition: transform 450ms ease, opacity 450ms ease;
  opacity: 0;
  transform: translateX(-10px);
}
.modal-animated-fade .modal-animated-fade-item:nth-child(2), .print-animated .modal-animated-fade-item:nth-child(2), .print-animated .print-animated-content:nth-child(2) {
  -webkit-transition-delay: 100ms, 100ms;
  transition-delay: 100ms, 100ms;
  transition-property: opacity, transform;
}
.modal-animated-fade .modal-animated-fade-item:nth-child(3), .print-animated .modal-animated-fade-item:nth-child(3), .print-animated .print-animated-content:nth-child(3) {
  -webkit-transition-delay: 200ms, 200ms;
  transition-delay: 200ms, 200ms;
  transition-property: opacity, transform;
}
.modal-animated-fade .modal-animated-fade-item:nth-child(4), .print-animated .modal-animated-fade-item:nth-child(4), .print-animated .print-animated-content:nth-child(4) {
  -webkit-transition-delay: 300ms, 300ms;
  transition-delay: 300ms, 300ms;
  transition-property: opacity, transform;
}
.modal-animated-fade .modal-animated-fade-item:nth-child(5), .print-animated .modal-animated-fade-item:nth-child(5), .print-animated .print-animated-content:nth-child(5) {
  -webkit-transition-delay: 400ms, 400ms;
  transition-delay: 400ms, 400ms;
  transition-property: opacity, transform;
}
.modal-animated-fade .modal-animated-fade-item:nth-child(6), .print-animated .modal-animated-fade-item:nth-child(6), .print-animated .print-animated-content:nth-child(6) {
  -webkit-transition-delay: 500ms, 500ms;
  transition-delay: 500ms, 500ms;
  transition-property: opacity, transform;
}
.modal-animated-fade .modal-animated-fade-item:nth-child(7), .print-animated .modal-animated-fade-item:nth-child(7), .print-animated .print-animated-content:nth-child(7) {
  -webkit-transition-delay: 600ms, 600ms;
  transition-delay: 600ms, 600ms;
  transition-property: opacity, transform;
}
.modal-animated-fade .modal-animated-fade-item.visible, .print-animated .modal-animated-fade-item.visible, .print-animated .visible.print-animated-content {
  opacity: 1;
  transform: translateX(0);
}
.modal-animated-fade .modal-animated-fade-item.btn, .print-animated .modal-animated-fade-item.btn, .print-animated .btn.print-animated-content {
  transition: transform 450ms ease, opacity 450ms ease, border-color ease 0.15s, background-color ease 0.15s;
}
@media (max-width: 767px) {
  .modal-animated-fade .modal-animated-fade-item.btn, .print-animated .modal-animated-fade-item.btn, .print-animated .btn.print-animated-content {
    width: 100%;
    margin: 0 0 5px 0 !important;
  }
  .modal-animated-fade .modal-animated-fade-item.btn:last-child, .print-animated .modal-animated-fade-item.btn:last-child, .print-animated .btn.print-animated-content:last-child {
    margin-bottom: 0 !important;
  }
}

/* #18773 - modal-print-animated - animations customs - non réutilisables (pour l'instant) */
.print-animated .print-animated-body {
  /* animation d'ouverture et fermeture du body du modal */
  transition: height 500ms ease;
  position: relative;
}
.print-animated .print-animated-body .print-animated-title {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  padding: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.print-animated .print-animated-content.removing {
  opacity: 0;
  transform: translateX(10px);
}
.print-animated .print-animated-startbtn .print-animated-startbtn-icon:before {
  content: "\f02f";
}
.print-animated .print-animated-startbtn .print-animated-startbtn-icon-container {
  display: inline-block;
  transition: transform 300ms ease;
}
.print-animated .print-animated-startbtn:active .print-animated-startbtn-icon-container, .print-animated .print-animated-startbtn:active:focus .print-animated-startbtn-icon-container {
  transform: scale(0.2);
}
.print-animated .print-animated-startbtn.loading .print-animated-startbtn-icon {
  animation: rotation 2s linear infinite;
}
.print-animated .print-animated-startbtn.loading .print-animated-startbtn-icon:before {
  content: "\f110";
}
@media (max-width: 767px) {
  .print-animated .print-animated-content.btn {
    width: 100%;
    margin: 0 0 5px 0 !important;
  }
  .print-animated .print-animated-content.btn:last-child {
    margin-bottom: 0 !important;
  }
}

.legend-box {
  border: 1px solid #ccc;
  padding: 15px;
}

.legend-item {
  display: flex;
  align-items: center;
}

li.legend-item:not(:last-child) {
  margin-bottom: 10px;
}

.legend-square {
  height: 20px;
  width: 40px;
  border: 1px solid #393939;
  margin-right: 15px;
}

.alert-w-icon {
  display: flex;
  align-items: center;
}
.alert-w-icon .alert-icon {
  flex-grow: 0;
  flex-shrink: 0;
}
.alert-w-icon .alert-icon:first-child {
  margin-right: 15px;
}
.alert-w-icon .alert-icon:last-child {
  margin-left: 15px;
}

.signature-bloc {
  display: flex;
  align-items: flex-end;
  width: 100%;
}
.signature-bloc .signature-text {
  flex-grow: 0;
  flex-shrink: 0;
  margin-right: 15px;
  margin-bottom: 0;
}
.signature-bloc .signature-line {
  flex-grow: 1;
  flex-shrink: 1;
  border-bottom: 1px solid #393939;
}
@media only screen and (max-width: 991px) {
  .signature-bloc {
    flex-direction: column;
    align-items: flex-start;
  }
  .signature-bloc .signature-text {
    margin-right: 0;
    margin-bottom: 15px;
    width: 100%;
  }
  .signature-bloc .signature-line {
    width: 100%;
  }
}
.signature-bloc:not(:last-child) {
  margin-bottom: 15px;
}

@media print {
  .signature-print {
    page-break-inside: avoid;
    padding: 40px 15px 25px 15px;
    background-color: #fff !important;
    border: 1px solid #393939;
  }
}

.private-media {
  pointer-events: none;
}
@media print {
  .private-media {
    display: none;
  }
}

.row-striped {
  padding-top: 5px;
  padding-bottom: 5px;
}
.row-striped:nth-child(even) {
  background-color: #eee;
}
@media print {
  .row-striped:nth-child(even) {
    background-color: #eee !important;
  }
}

.sous-bloc-form-sortable {
  position: relative;
  left: 0 !important;
  background-color: #e5e5e5;
  padding-left: 40px;
}
.sous-bloc-form-sortable .sortable-handle-logo {
  position: absolute;
  left: 5px;
  top: 50%;
  transform: translateY(-50%);
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 2px;
  row-gap: 2px;
  width: 35px;
  padding: 12px;
  border: 1px solid #ccc;
}
.sous-bloc-form-sortable .sortable-handle-logo .sortable-handle-logo-box {
  width: 4px;
  height: 4px;
  background-color: #616161;
}
.sous-bloc-form-sortable.nouvelle-ligne .sortable-handle-logo {
  display: none;
}

.text-separator {
  display: flex;
  align-items: center;
  text-align: center;
}
.text-separator .text-separator-line {
  flex-grow: 1;
  border-bottom: 1px solid #ccc;
}

/* link-underline */
.link-underline {
  color: #fff;
  position: relative;
  padding-bottom: 2px;
  text-decoration: none !important;
  transition: opacity 300ms ease;
}
.link-underline:after {
  display: block;
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  height: 2px;
  width: 0;
  background-color: #fff;
  transition: width 300ms ease;
}
.link-underline.link-underline-featured:after {
  background-color: #6ab12f;
}
.link-underline:hover, .link-underline:focus {
  color: #fff;
}
.link-underline:hover:after, .link-underline:focus:after, .link-underline:active:after, .link-underline:active:focus:after, .link-underline.active:after {
  width: 100%;
}
.link-underline:active:after, .link-underline:active:focus:after {
  opacity: 0.8;
}

.border-radius-sm {
  border-radius: 4px;
}

.splitinput-container {
  display: flex;
  margin: -4px;
}
.splitinput-container .splitinput {
  margin: 4px;
  max-width: 35px;
  text-transform: uppercase;
}

/* titre custom avec ligne à gauche */
.title-line {
  position: relative;
  padding-left: 20px;
}
.title-line:before {
  display: block;
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 5px;
  height: 100%;
  background-color: #6ab12f;
}

.icon-text {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
}
.icon-text .icon-text-icon-container {
  width: 70px;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  border: 5px solid #5293c4;
  margin-bottom: 20px;
  transform: scale(0);
  opacity: 0;
  transition: transform 500ms ease, opacity 500ms ease;
}
.icon-text .icon-text-icon-container.loaded {
  transform: scale(1);
  opacity: 1;
}
.icon-text .icon-text-icon-container .icon-text-icon {
  font-size: 40px;
}
@media (min-width: 511px) {
  .icon-text {
    flex-direction: row;
    text-align: left;
  }
  .icon-text .icon-text-icon-container {
    flex-grow: 0;
    flex-shrink: 0;
    margin-bottom: 0;
    margin-right: 20px;
  }
  .icon-text .icon-text-text-container {
    flex-grow: 1;
    flex-shrink: 1;
  }
}

/* sections -> styles de sections se répétant sur le site ex: header, newsletter etc */
.navbar-fixed-top + .main-container {
  padding-top: 65px;
}

/* navbar overlay fix */
.navbar-fixed-top + #main-container {
  padding-top: 0;
}

.navbar.geocentralis {
  background-color: #040073 !important;
}
.navbar.geocentralis-debug-prod {
  background-color: red !important;
}
.navbar .navbar-alt .navbar-toggle {
  position: relative;
  display: none;
}
.navbar .navbar-alt .navbar-toggle .icon-bar {
  position: absolute;
  left: 0;
  transition: opacity 400ms ease, transform 400ms ease, top 400ms ease;
}
.navbar .navbar-alt .navbar-toggle .icon-bar:nth-child(2) {
  top: 10px;
}
.navbar .navbar-alt .navbar-toggle .icon-bar:nth-child(3) {
  top: 12px;
}
.navbar .navbar-alt .navbar-toggle .icon-bar:nth-child(4) {
  top: 18px;
}
.navbar .navbar-alt .navbar-toggle.display .icon-bar:nth-child(2) {
  top: 16px;
  transform: rotate(45deg);
}
.navbar .navbar-alt .navbar-toggle.display .icon-bar:nth-child(3) {
  opacity: 0;
}
.navbar .navbar-alt .navbar-toggle.display .icon-bar:nth-child(4) {
  top: 12px;
  transform: rotate(-45deg);
}
@media (max-width: 991px) {
  .navbar .navbar-alt .navbar-toggle {
    display: inline-block;
  }
}
@media (max-width: 767px) {
  .navbar:not(.navbar-collapse) .navbar-alt .ace-nav > li :last-child, .navbar:not(.navbar-collapse) .navbar-alt .ace-nav > li :first-child {
    border: none;
  }
}

.navbar-alt {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.navbar-alt .navbar-alt-left, .navbar-alt .navbar-alt-right {
  display: flex;
  align-items: center;
}
.navbar-alt .ace-nav > li > a.dropdown-toggle {
  background: none;
}
@media (max-width: 991px) {
  .navbar-alt .user-info {
    display: none;
  }
}
@media (max-width: 767px) {
  .navbar-alt .dropdown-menu > li > a {
    font-size: 16px;
    padding: 8px 16px;
  }
  .navbar-alt .bottin-outter {
    margin-right: 0;
  }
  .navbar-alt .bottin-select .bottin-select-txt {
    display: none;
  }
}
@media (max-width: 510px) {
  .navbar-alt .navbar-brand .navbar-brand-txt:not(.navbar-brand-txt-info) {
    display: none;
  }
  .navbar-alt .navbar-brand .navbar-brand-txt-info {
    font-size: 14px;
  }
  .navbar-alt .navbar-brand .geocentralis-logo {
    width: 110px;
  }
}

.navbar-fixed-container {
  position: relative;
  z-index: 1999;
}

.navbar-buttons.navbar-header.pull-right.geocentralis {
  vertical-align: middle;
  padding-top: 12px;
}
.navbar-buttons.navbar-header.pull-right.geocentralis li.geocentralis-blue > a {
  background-color: #040073 !important;
}

@media (max-width: 510px) {
  .select2-drop.bigdrop {
    left: 15px !important;
    width: calc(100vw - 30px) !important;
  }
}

.dropdown-user {
  display: flex;
  align-items: center;
  color: #fff !important;
  text-decoration: none !important;
  padding: 5px 10px;
  border-radius: 14px;
  background-color: rgba(0, 0, 0, 0);
  transition: background-color 400ms ease;
}
.dropdown-user .dropdown-user-icon {
  font-size: 30px;
  margin-right: 8px;
}
.dropdown-user:hover, .dropdown-user:focus, .dropdown-user:active {
  background-color: #030052;
}

.bottin .select2-choice {
  display: flex;
  align-items: center;
  background-color: #fff;
  background-image: none !important;
  padding: 5px 10px;
  border: none;
  border-radius: 14px;
  min-height: 40px;
}
.bottin .select2-choice .select2-arrow {
  right: 5px;
  top: 4px;
}
.bottin .bottin-select {
  display: flex;
  align-items: center;
}
.bottin .bottin-select .fa-phone {
  font-size: 20px;
}

.bottin-ligne {
  font-size: 9pt;
}
.bottin-ligne:first-child {
  font-size: 14pt;
  font-weight: bold;
}
.bottin-ligne:empty {
  display: none;
}

.bottin-select {
  color: #888;
}

.user-menu .user-menu-info {
  /* styles repris d'ace, à l'exception de la gestion de l'overflow */
  font-size: 13px;
  padding-left: 11px;
  padding-right: 11px;
  margin-bottom: 1px;
  margin-top: 1px;
  max-width: 250px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

/*STYLES COPIÉS DU TEMPLATE POUR REPRODUIRE UN MENU DE GAUCHE RESPONSIVE (QUI DISPARAIT SUR TABLET)*/
/* responsive sidebar */
.evb-sidebar {
  -moz-border-bottom-colors: none;
  -moz-border-left-colors: none;
  -moz-border-right-colors: none;
  -moz-border-top-colors: none;
  background-color: #fff;
  border-color: #ccc;
  border-image: none;
  border-style: solid;
  border-width: 0 1px 0 0;
  float: left;
  position: relative;
}
@media (max-width: 991px) {
  .evb-sidebar {
    display: none;
    float: none;
    position: absolute;
    z-index: 999;
    left: 0;
    bottom: auto;
    top: auto !important;
    /*so that it's applied even if it's .sidebar-fixed */
    margin-top: 40px;
    border: 1px solid #ccc;
    border-left-width: 0;
    box-shadow: 2px 1px 2px 0 rgba(0, 0, 0, 0.2);
  }
  .evb-sidebar:before {
    display: none;
  }
  .evb-sidebar.display {
    display: block;
  }
}

.sidebar {
  /* responsive sidebar fixed styling */
}
.sidebar .sidebar-shortcuts {
  min-height: 39.5px;
}
@media (max-width: 510px) {
  .sidebar.responsive.sidefixed-responsive {
    top: 52px;
    bottom: 0;
    width: 100%;
    z-index: 2000;
  }
  .sidebar.responsive.sidefixed-responsive .nav-list > li > a {
    height: auto;
    font-size: 14px;
    padding-top: 12px;
    padding-bottom: 12px;
  }
  .sidebar.responsive.sidefixed-responsive .nav-list > li.active:after {
    right: 0;
  }
  .sidebar.responsive.sidefixed-responsive .nav-list li.active > a:after {
    right: 2px;
  }
}

.version_info {
  font-size: 9pt;
}

.no-skin .nav-list > li.active > a {
  font-weight: 700;
  color: #040073;
}
.no-skin .nav-list > li.active:after {
  border-color: #040073;
}
.no-skin .nav-list > li:hover > a {
  color: #040073;
}
.no-skin .nav-list > li .submenu > li.active > a {
  color: #040073;
  text-decoration: none;
}
.no-skin .nav-list li.active > a:after {
  border-right-color: #040073;
  -moz-border-right-colors: #040073;
}

@media (max-width: 510px) {
  body.sidebar-open {
    height: 100vh;
    overflow: hidden;
  }
}

.sidebarTitle {
  font-size: 1em;
}

/* pages -> style uniques présents dans des pages spécifiques ex:404 */
.login-layout .widget-box {
  background-color: rgb(91.1560695652, 89.964, 124.236) !important;
}

.geocentralis-logo-login {
  padding-top: 40px;
  padding-bottom: 20px;
  width: 375px;
  max-width: 100%;
}

.geocentralis.login-purple-lamborghini {
  color: #3d0073;
}

.managment-topbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.managment-topbar .managment-topbar-left, .managment-topbar .managment-topbar-right {
  display: flex;
  align-items: center;
  padding: 6px 15px;
}
.managment-topbar .managment-topbar-left {
  flex-grow: 0;
  flex-shrink: 0;
}
.managment-topbar .managment-topbar-right {
  flex-grow: 1;
  flex-shrink: 1;
  justify-content: flex-end;
}
.managment-topbar #gestion-semaine {
  width: 580px;
  margin: 0;
}
.managment-topbar #gestion-semaine > div > div label {
  text-align: left;
  padding-top: 7px;
  margin-bottom: 0;
}
.managment-topbar .form-group {
  margin: 0;
}
.managment-topbar .btn {
  margin-bottom: 0 !important;
}
.managment-topbar .managment-topbar-btn-container {
  display: flex;
  margin: -5px;
}
.managment-topbar .managment-topbar-btn-container .managment-topbar-btn {
  margin: 5px !important;
}
.managment-topbar .managment-topbar-item h5 {
  margin: 0;
}
.managment-topbar .managment-topbar-item #etat_feuille_temps {
  margin-bottom: 0;
}
@media (max-width: 1415px) {
  .managment-topbar .managment-topbar-btn-container .managment-topbar-btn .btn-text {
    display: none;
  }
}
@media (max-width: 1199px) {
  .managment-topbar {
    flex-direction: column;
  }
  .managment-topbar .managment-topbar-left, .managment-topbar .managment-topbar-right {
    justify-content: flex-start;
    width: 100%;
  }
  .managment-topbar .managment-topbar-btn-container {
    width: 100%;
  }
  .managment-topbar .managment-topbar-btn-container .managment-topbar-btn {
    flex: 1;
  }
  .managment-topbar .managment-topbar-btn-container .managment-topbar-btn .btn-text {
    display: inline;
  }
}
@media (max-width: 991px) {
  .managment-topbar .managment-topbar-left {
    flex-direction: column;
    align-items: flex-start;
  }
  .managment-topbar .managment-topbar-left .managment-topbar-item:not(:last-child) {
    margin-bottom: 8px;
  }
  .managment-topbar #gestion-semaine {
    width: 100%;
  }
}
@media (max-width: 767px) {
  .managment-topbar .managment-topbar-right {
    padding-top: 0;
  }
}
@media (max-width: 510px) {
  .managment-topbar .managment-topbar-btn-container .managment-topbar-btn .btn-text {
    display: none;
  }
  .managment-topbar .managment-topbar-item {
    width: 100%;
  }
}

/* styles propres aux pages d'erreur (ex: 403, 404) */
.error-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 40px 15px;
  min-height: 100vh;
}
.error-page .error-geocentralis-logo {
  width: 375px;
  max-width: 100%;
}
.error-page .error-content {
  border: 6px solid rgb(91.1560695652, 89.964, 124.236);
  background-color: #fff;
  padding: 30px 15px;
  width: 700px;
  max-width: 100%;
}
.error-page .error-title .error-title-number {
  font-size: 100px;
  font-weight: 700;
}
.error-page .error-title .error-title-number .number {
  display: inline-block;
  opacity: 0;
  transform: translateY(-10px);
  transition: opacity 500ms ease, transform 500ms ease;
}
.error-page .error-title .error-title-number .number.loaded {
  transform: translateY(0);
  opacity: 1;
}
.error-page .error-title .error-title-number .number:nth-child(1) {
  -webkit-transition-delay: 0.1s;
  transition-delay: 0.1s;
}
.error-page .error-title .error-title-number .number:nth-child(2) {
  -webkit-transition-delay: 0.2s;
  transition-delay: 0.2s;
}
.error-page .error-title .error-title-number .number:nth-child(3) {
  -webkit-transition-delay: 0.3s;
  transition-delay: 0.3s;
}
@media (min-width: 992px) {
  .error-page .error-title .error-title-number {
    font-size: 130px;
  }
}

.choisir-permis:hover,
.choisir-mutation:hover {
  color: #5CB85C;
  cursor: pointer;
}

@media (max-width: 1199px) {
  #s2id_exp-ue-liste-ville {
    padding-bottom: 5px;
  }
}

@media (max-width: 1199px) {
  ::-webkit-scrollbar {
    width: 10px !important;
    height: 12px !important;
  }
}

@media (max-width: 1199px) {
  ::-webkit-scrollbar-thumb {
    background: #C1C1C1;
  }
  ::-webkit-scrollbar-thumb:hover {
    background: #787878;
  }
}

.geocentralis-logo {
  height: 45px;
}

img.responsive {
  max-width: 100%;
}

.page-header {
  margin-top: 10px;
  margin-left: -12px;
  margin-right: -12px;
  margin-bottom: 0.5em;
  padding-bottom: 0.5em;
  padding-top: 0;
}
.page-header h1 {
  margin-left: 0 !important;
  color: #040073;
}

#page_content {
  padding: 5px 15px 0;
}

.profile-info-name-geoc {
  width: 200px !important;
}

.panel-heading-37h {
  height: 37px;
}

.panel-title-float-left {
  float: left;
}